import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import {
  CustomerAddInternalFinancialComponent,
} from './CustomerAddInternalFinancial.component';

const CustomerAddInternalFinancialContainer: FC = () => {
  const customerOrganization = useSelector(R.selectors.userOrganizationData.getCustomersAddInternal);
  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.getCustomersAddInternalErrors);
  const allowedMembershipTypes = useSelector(R.selectors.userOrganizationData.getCustomersAddInternalAllowedMembershipTypes);

  return (
    <CustomerAddInternalFinancialComponent
      organization={customerOrganization}
      organizationFieldsErrors={organizationFieldsErrors}
      onChangeUpdateBusinessRegistrationNumber={UC.organizationProfile.onChangeCustomerAddInternalBusinessRegistrationNumber}
      onChangeUpdateMembershipType={UC.organizationProfile.onChangeCustomerAddInternalMembershipType}
      onChangeUpdateMembershipValue={UC.organizationProfile.onChangeCustomerAddInternalMembershipValue}
      onAddMembership={UC.organizationProfile.onAddCustomerAddInternalMembership}
      onRemoveMembership={UC.organizationProfile.onRemoveCustomerAddInternalMembership}
      allowedMembershipTypes={allowedMembershipTypes}
      onChangeUpdateTaxId={UC.organizationProfile.onChangeCustomerAddInternalTaxId}
      onBlurUpdateBusinessRegistrationNumber={UC.organizationProfile.onBlurCustomerAddInternalBusinessRegistrationNumber}
      onBlurUpdateMembership={UC.organizationProfile.onBlurCustomerAddInternalMembership}
      onBlurUpdateTaxId={UC.organizationProfile.onBlurCustomerAddInternalTaxId}
    />
  );
};

export const CustomerAddInternalFinancial = memo(CustomerAddInternalFinancialContainer);
