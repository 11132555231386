import React, { FC } from 'react';
import { Tooltip } from 'app-wrapper/view/components/Tooltip';
import { IInputProps } from 'app-wrapper/view/components/Input';
import omit from 'lodash/fp/omit';
import { InputWithErrorStyled } from './InputWithError.styled';

export interface InputWithErrorProps extends IInputProps{
  errorMessage?: string | null
  hasError?: boolean,
  largeInput?: boolean,
  'data-class'?: string,
}

export const InputWithError: FC<InputWithErrorProps> = (props) => {
  const { errorMessage } = props;
  return (
    <Tooltip mode="danger" title={errorMessage} visible={Boolean(errorMessage)}>
      <InputWithErrorStyled
        {...omit(['errorMessage'], props)}
      />
    </Tooltip>
  );
};
