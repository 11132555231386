import React, {
  FC,
  ChangeEvent,
  useCallback,
  useMemo,
} from 'react';

import { hasAccess } from 'app-wrapper/utils';
import { Input } from 'app-wrapper/view/components';
import { CargoDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

interface ICargoFormValueInputComponentProps {
  value: CargoDTM['value']
  touchField: (field: string) => void
  setValue: (value: CargoDTM['value']) => void
  cargosAvailability?: PermissionAttributePolicy
  customerCargosAvailability?: PermissionAttributePolicy
}

export const CargoFormValueInputComponent: FC<ICargoFormValueInputComponentProps> = ({
  value,
  touchField,
  setValue,
  cargosAvailability,
  customerCargosAvailability,
}) => {
  const handleChangeValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    touchField('value');
  }, []);

  const isDisabled = useMemo(() => !hasAccess(cargosAvailability, PermissionAttributePolicy.WRITE) || !hasAccess(customerCargosAvailability, PermissionAttributePolicy.WRITE), [cargosAvailability, customerCargosAvailability]);

  return (
    <Input
      value={value}
      onBlur={handleBlur}
      onChange={handleChangeValue}
      disabled={isDisabled}
    />
  );
};
