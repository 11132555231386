import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const PaymentsTradeStatusWrap = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'PaymentsTradeStatusWrap',
}))`
  ${divFlex}
`;

export const PaymentsTradeStatusContent = styled.div.attrs((props: {
  isFull?: boolean
  'data-class'?: string
}) => ({
  isFull: props.isFull,
  'data-class': props?.['data-class'] || 'PaymentsTradeStatusContent',
}))`
  ${divFlex}
  align-items: center;
  padding: ${({ isFull }) => (isFull ? '0' : '0 8px')};
  margin-top: ${({ isFull }) => (isFull ? '8px' : '0')};

  border-radius: 3px;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1Dot5};
`;

export const PaymentsTradeStatusItem = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'PaymentsTradeStatusItem',
}))`
  ${divFlex}
  align-items: center;
`;

export const PaymentsTradeStatusItemIcon = styled.div.attrs((props: {
  isActive: boolean
  svgColor: string
  isFirst?: boolean
  'data-class'?: string
}) => ({
  isActive: props?.isActive,
  svgColor: props?.svgColor,
  isFirst: props.isFirst,
  'data-class': props?.['data-class'] || 'PaymentsTradeStatusItemIcon',
}))`
  ${divFlex}
  align-items: center;

  margin-left: ${({ isFirst }) => (isFirst ? '0' : '4px')};
  svg {
    color: ${({ theme, isActive }) => (!isActive ? theme?.themesColors?.neutralBranding6 : theme?.themesColors?.secondaryDot45)};
    ${({ svgColor }) => svgColor && `color: ${svgColor}`};
  }
`;

export const PaymentsTradeStatusItemText = styled.div.attrs((props: {
  isActive: boolean
  isFirst?: boolean
  'data-class'?: string
}) => ({
  isActive: props?.isActive,
  isFirst: props.isFirst,
  'data-class': props?.['data-class'] || 'PaymentsTradeStatusItemText',
}))`
  ${divFlex}
  align-items: center;

  margin-left: ${({ isFirst }) => (isFirst ? '0' : '4px')};
  color: ${({ theme, isActive }) => (!isActive ? theme?.themesColors?.neutralBranding6 : theme?.themesColors?.secondaryDot45)};
  ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
`;
