import React, {
  FC, useEffect, useCallback, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alert from 'antd/es/alert';
import notification from 'antd/es/notification';

import {
  TrackerHeader,
} from 'shipment-operations/view/components/ShipmentTrackerHeader';
import { TrackerHeaderDrayage } from 'shipment-operations/view/components';
import {
  Wrapper, GapVerticalContainerStyled, SubPageHeaderTitle, SubPageHeader,
} from 'app-wrapper/view/guideline';
import { useIsExportOrImport } from 'app-wrapper/hooks';
import { ContainersTable } from './components';

interface IShipmentTransportationTrackerComponentProps {
  loadData: (shipmentId?: string, isOverview?: boolean) => void
  onClosePage: () => void
  errorUpdate: boolean
  resetError: () => void
  withEdit: boolean
  isLoading: boolean
  showUntrackedWarning: boolean
  shouldUpdateData: boolean
  isCommon: boolean
  isOriginDoor?: boolean
  isOriginPartner?: boolean
  isDestinationPartner?: boolean
}

export const ShipmentTransportationTrackerComponent: FC<IShipmentTransportationTrackerComponentProps> = ({
  loadData,
  isLoading,
  onClosePage,
  errorUpdate,
  resetError,
  withEdit,
  showUntrackedWarning,
  shouldUpdateData,
  isCommon,
  isOriginDoor,
  isOriginPartner,
  isDestinationPartner,
}) => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { t } = useTranslation();

  const {
    isExportOrImport, isExport, isOceanBooking, isNotDrayage,
  } = useIsExportOrImport();

  useEffect(() => {
    loadData(shipmentId);
    return onClosePage;
  }, []);

  useEffect(() => {
    if (shouldUpdateData) {
      loadData(shipmentId, isCommon);
    }
  }, [shouldUpdateData]);

  useEffect(() => {
    if (errorUpdate) {
      notification.error({
        message: t('basicErrors.REQUEST_COMMON.title'),
        description: t('basicErrors.REQUEST_COMMON.subTitle'),
        placement: 'bottomRight',
        bottom: 60,
        onClose: resetError,
      });
    }
  }, [errorUpdate, t]);

  const showWarning = useMemo(() => (
    ((isExport && isOriginDoor && !isCommon) || (!isCommon && isOriginPartner && isNotDrayage)
    || (isOceanBooking && !isOriginDoor && !isCommon)) && !isLoading && showUntrackedWarning && !isDestinationPartner
  ), [isCommon, isExport, isOriginDoor, showUntrackedWarning, isLoading, isOceanBooking]);

  const content = useCallback(() => (
    <GapVerticalContainerStyled>
      {isExportOrImport ? (
        <TrackerHeaderDrayage isPageLoading={isLoading} skeletonHeight="210px" />
      ) : (
        <TrackerHeader isPageLoading={isLoading} skeletonHeight="210px" withEdit={withEdit && !isCommon} />
      )}

      {showWarning && (
        <Alert
          type="warning"
          showIcon
          message={t('Containers have been sent')}
        />
      )}

      <ContainersTable isCommon={isCommon} />
    </GapVerticalContainerStyled>
  ), [isLoading, showUntrackedWarning, isExportOrImport, withEdit, isCommon, showWarning]);

  return isCommon ? (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderTitle>{t('Shipment Tracker')}</SubPageHeaderTitle>
      </SubPageHeader>
      {content()}
    </Wrapper>
  ) : content();
};
