export enum ECommandCenterPageType {
  COMMAND_CENTER_TASKS_REQUESTS = 'COMMAND_CENTER_TASKS_REQUESTS',
}

interface ICommandCenterPageTypeValuesProps {
  isCustomer?: boolean
  isUsersOrganizationIsAssignee?: boolean
  isUsersOrganizationIsCreated?: boolean
}

export const CommandCenterPageTypeValues = (props?:ICommandCenterPageTypeValuesProps) => {
  const {
    isCustomer,
    isUsersOrganizationIsAssignee,
    isUsersOrganizationIsCreated,
  } = props || {};

  if (isCustomer) {
    return {
      [ECommandCenterPageType.COMMAND_CENTER_TASKS_REQUESTS]: {
        viewDueDate: true,
        viewCreatedAt: true,
        viewCreatedBy: isUsersOrganizationIsCreated,
        viewModule: true,
        viewAssignee: isUsersOrganizationIsAssignee,
      },
    };
  }

  return {
    [ECommandCenterPageType.COMMAND_CENTER_TASKS_REQUESTS]: {
      viewDueDate: true,
      viewCreatedAt: true,
      viewCreatedBy: isUsersOrganizationIsCreated,
      viewModule: true,
      viewAssignee: isUsersOrganizationIsAssignee,
    },
  };
};
