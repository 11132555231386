import styled from 'styled-components';

import { FlexRow, BodyTextMedium } from 'app-wrapper/view/guideline';

export const Header = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
`;

export const SectionTitle = styled.span`
  ${BodyTextMedium}
`;
