import React, { FC } from 'react';

import { DialogSvg } from 'app-wrapper/view/icons';
import { GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';
import { HintBoxContent, IconDiv } from './HintBox.styled';

interface IHintBoxContentProps {
  children: React.ReactNode;
}

export const HintBox: FC<IHintBoxContentProps> = ({ children }) => (
  <HintBoxContent>
    <GapHorizontalContainerStyled>
      <IconDiv>
        <DialogSvg />
      </IconDiv>
      {children}
    </GapHorizontalContainerStyled>
  </HintBoxContent>
);
