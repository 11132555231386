import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export interface ITransporationDrayagePermissionsDTM {
  editExportLoadingControls?: PermissionAttributePolicy;
  editImportLoadingControls?: PermissionAttributePolicy;
  editExportSchedule?: PermissionAttributePolicy;
  editImportSchedule?: PermissionAttributePolicy;
  editExportContainer?: PermissionAttributePolicy,
  editImportContainer?: PermissionAttributePolicy,
  addChargeExportCharges?: PermissionAttributePolicy;
  addChargeImportCharges?: PermissionAttributePolicy;
  chargesAndTrackerVisibility?: PermissionAttributePolicy;
  exportRequestChangesVisibility?: PermissionAttributePolicy;
  importRequestChangesVisibility?: PermissionAttributePolicy;
  linkContainer?: PermissionAttributePolicy;
}

export class TransporationDrayagePermissionsDTM extends BaseDTM<ITransporationDrayagePermissionsDTM> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editExportLoadingControls?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editImportLoadingControls?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editExportSchedule?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editImportSchedule?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editExportContainer?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editImportContainer?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  addChargeExportCharges?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  addChargeImportCharges?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  chargesAndTrackerVisibility?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  exportRequestChangesVisibility?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  importRequestChangesVisibility?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  linkContainer?: PermissionAttributePolicy;
}
