import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { EShippingPartyTypes } from 'shipment-operations/constants';
import { ShippingPartySection } from 'app-wrapper/view/guideline';

export const ReceiverSection = observer(() => {
  const { t } = useTranslation();
  const { shippingPartiesStore } = useMobxStore();
  const cargoReceiver = shippingPartiesStore.getShippingPartyByRole(EShippingPartyTypes.CARGO_RECEIVER);

  return cargoReceiver ? (
    <ShippingPartySection
      title={t('Cargo Receiver')}
      addressTitle={t('Receiver’s Address')}
      shippingParty={cargoReceiver}
    />
  ) : null;
});
