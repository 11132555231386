import { DateDtm } from 'app-wrapper/models/dtm';
import {
  action, computed, makeObservable, observable,
} from 'mobx';

interface States {
  isBlur: boolean
  isFocus: boolean
  isVisited: boolean
}

interface SetStates {
  isBlur?: boolean
  isFocus?: boolean
  isVisited?: boolean
}

interface InputState<T> {
  value: T | undefined,
  states: States
  errors: {
    message: string | null,
  }
  utcOffset: number | null,
}

export class InputStore<T> {
  @observable
  state: InputState<T> = {
    value: undefined,
    states: {
      isBlur: false,
      isFocus: false,
      isVisited: false,
    },
    errors: {
      message: null,
    },
    utcOffset: null,
  }

  constructor(value?: T) {
    makeObservable(this);
    if (value) {
      this.setValue(value);
      if (value instanceof DateDtm) {
        this.setUtcOffset(value.offset);
      }
    }
  }

  @action
  init(initialState: T) {
    this.state = { ...this.state, ...initialState };
  }

  @action
  setValue(value: T) {
    this.state.value = value;
  }

  @action
  setStates(states: SetStates) {
    this.state.states = { ...this.state.states, ...states };
  }

  @action
  setUtcOffset(utcOffset: number) {
    this.state.utcOffset = utcOffset;
  }

  @action
  setError(errorMessage: string | null) {
    this.state.errors.message = errorMessage;
  }

  @computed
  get hasError() {
    return Boolean(this.state.errors.message);
  }

  @computed
  get errorMessage() {
    return this.state.errors.message;
  }

  @computed
  get value() {
    return this.state.value;
  }
}
