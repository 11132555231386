import React, {
  FC,
  memo,
  useCallback, useEffect, useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { InfoCircleOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import {
  AlertBlock, Button, Tooltip, Row, Col, Panel, Collapse,
  ExpandIcon,
  TypeSwitch,
} from 'app-wrapper/view/components';
import { Option } from 'app-wrapper/view/controllers/SelectController/SelectController';

import {
  useCollapseOnChangeHandler, useWindowSize,
} from 'app-wrapper/hooks';
import {
  CloseSvg, VerticalFormItemSvg, PlusSvg, MinusSvg,
  HazmatsSvg,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { REQUEST_STATUS } from 'app-wrapper/constants';

import { getNotFoundContent } from 'monetary/hooks';
import { FreightFromCommodityValuesStateDTM, IFreightSelectFieldDTM } from 'monetary/models/dtm';
import { IDefaultFieldErrors } from 'monetary/models/errors';

import { IMOClass as IMOClassData } from '../components/data';

import {
  CommodityHazmats,
  CommodityHazmatsIcon,
  CommodityHazmatsText,
  CommodityInput,
  CommoditySelect,
  CommoditySelectOptionHeader,
  CommodityWrapper,
} from './Commodity.styled';

const collapseIcon = (<VerticalFormItemSvg />);
const debounceTime = 300;

export interface ICommodityComponentProps {
  isAllDisabled?: boolean
  forceCollapse?: boolean
  isCollapsed?: boolean
  // new
  temperatureControl?: boolean
  onChangeCommodityTemperature: (temperatureControl: boolean) => void
  onAddCommodityValuesItem: () => void
  onRemoveCommodityValuesItem: (indexItem: number) => () => void
  commodityValues?: FreightFromCommodityValuesStateDTM[]
  isCommodityError?: boolean
  quotaStatus?: string
  errorsCommodity?: {
    // this simulation array keys
    [key: number]: {
      error?: IDefaultFieldErrors
      hsCode?: IDefaultFieldErrors
      goodsValue?: IDefaultFieldErrors
      IMOClass?: IDefaultFieldErrors
      UNNumber?: IDefaultFieldErrors
    }
  }
  isSubmitVisited?: boolean
  isSubmitClicked?: boolean
  // HsCode
  getRFQCommodityHsCodeDyIndex: (indexItem: number) => (value: string | null) => void
  hsCodeState?: {
    // this simulation array keys
    [key: number]: {
      status?: string
      values?: IFreightSelectFieldDTM[]
    }
  }
  onChangeCommodityHsCode: (indexItem: number) => (value: IFreightSelectFieldDTM) => void
  onClearCommodityHsCode: (indexItem: number) => () => void
  onFocusCommodityHsCode: (indexItem: number) => () => void
  onBlurCommodityHsCode: (indexItem: number) => () => void
  onKeyPressHsCodeByIndex: (indexItem: number) => (key: string) => void
  // GoodsValue
  onChangeCommodityGoodsValue: (indexItem: number) => (value: string) => void
  onFocusCommodityGoodsValue: (indexItem: number) => () => void
  onBlurCommodityGoodsValue: (indexItem: number) => () => void
  // IMO Class
  onChangeCommodityIMOClass: (indexItem: number) => (value: string) => void
  onClearCommodityIMOClass: (indexItem: number) => () => void
  onFocusCommodityIMOClass: (indexItem: number) => () => void
  onBlurCommodityIMOClass: (indexItem: number) => () => void
  // UN Number
  onChangeCommodityUNNumber: (indexItem: number) => (value: string) => void
  onFocusCommodityUNNumber: (indexItem: number) => () => void
  onBlurCommodityUNNumber: (indexItem: number) => () => void
}

const CommodityComponent: FC<ICommodityComponentProps> = ((props) => {
  const {
    isAllDisabled,
    forceCollapse,
    isCollapsed,
    // new
    temperatureControl,
    onChangeCommodityTemperature,
    onAddCommodityValuesItem,
    onRemoveCommodityValuesItem,
    commodityValues,
    isCommodityError,
    errorsCommodity,
    quotaStatus,
    isSubmitVisited,
    isSubmitClicked,
    // HsCode
    getRFQCommodityHsCodeDyIndex,
    onChangeCommodityHsCode,
    onClearCommodityHsCode,
    onFocusCommodityHsCode,
    onBlurCommodityHsCode,
    onKeyPressHsCodeByIndex,
    hsCodeState,
    // GoodsValue
    onChangeCommodityGoodsValue,
    onFocusCommodityGoodsValue,
    onBlurCommodityGoodsValue,
    // IMO Class
    onChangeCommodityIMOClass,
    onClearCommodityIMOClass,
    onFocusCommodityIMOClass,
    onBlurCommodityIMOClass,
    // UN Number
    onChangeCommodityUNNumber,
    onFocusCommodityUNNumber,
    onBlurCommodityUNNumber,
  } = props;
  const { isFullMediaWidth } = useWindowSize();
  const [isResponsive, setIsResponsive] = useState(isFullMediaWidth);

  useEffect(() => {
    if (forceCollapse) {
      setIsResponsive(false);
    } else {
      // old changed this
      setIsResponsive(true);
    }
  }, [forceCollapse]);
  const { t } = useTranslation();

  const handleAddButton = useCallback(() => {
    onAddCommodityValuesItem();
  }, [onAddCommodityValuesItem]);

  const isInfoBlock = temperatureControl && commodityValues?.length && commodityValues?.length > 1;

  const onChangeTypeSwitchController = useCallback(
    (value) => {
      onChangeCommodityTemperature(!!value);
    },
    [onChangeCommodityTemperature],
  );

  const switchTemperature = useMemo(() => (
    <TypeSwitch
      value={!!temperatureControl}
      data-class="temperatureControl"
      leftText={t('Temperature Control')}
      rightText={t('')}
      checkedChildren="On"
      unCheckedChildren="Off"
      disabledbg="true"
      onChange={onChangeTypeSwitchController}
      disabled={isAllDisabled}
    />
  ), [temperatureControl, isAllDisabled, onChangeTypeSwitchController, t]);

  const addBtnIsMedium = useMemo(() => (
    <Button
      onClick={onAddCommodityValuesItem}
      disabled={temperatureControl || isAllDisabled}
      size="small"
      type="dashed"
      data-testid="addMediumBtn"
    >
      <PlusSvg
        style={
          {
            color: temperatureControl ? '#D1D7E2' : undefined,
          }
        }
        height={11}
        width={11}
      /><span className="addCommodity__title">{t('addCommodity')}</span>
    </Button>
  ), [handleAddButton, onAddCommodityValuesItem, isAllDisabled, temperatureControl, t]);

  const headerIsFullComponent = useMemo(() => isResponsive && (
    <>
      <Row justify="space-between" className="FreightQuoteFormRequest__Row__col_both__title">
        <div>
          <Typography.Text>{t('Commodity')}</Typography.Text>
        </div>
        <div className="FreightQuoteFormRequest__Row__Commodity__temperature">
          {isResponsive && switchTemperature}
        </div>
      </Row>
      <Row className="FreightQuoteFormRequest__Row__Commodity__content">
        {/* Currently align missing blocks */}
        <Col span={18} style={{ maxWidth: 'calc(75% + 14px)', flex: 'auto' }}>
          <div className="FreightQuoteFormRequest__Row__Commodity__content_title">
            <Typography.Text>{t('Type')} </Typography.Text>
            <Typography.Text className="text_placeholder">{t('default FAK')}
              <Tooltip title={t('fakInfoText')} placement="top">
                <InfoCircleOutlined className="FreightQuoteFormRequest__Row__Commodity__content_title_icon" />
              </Tooltip>
            </Typography.Text>
          </div>
        </Col>
        <Col span={4}>
          <div className="FreightQuoteFormRequest__Row__Commodity__content_title">
            <Typography.Text>{t('Value')}</Typography.Text>
          </div>
        </Col>
        {/* Currently hidden */}
        {/* <Col span={4}>
          <div className="FreightQuoteFormRequest__Row__Commodity__content_title">
            <Typography.Text>{t('IMO Class')}</Typography.Text>
          </div>
        </Col> */}
        {/* <Col span={4}>
          <div className="FreightQuoteFormRequest__Row__Commodity__content_title">
            <Typography.Text>{t('UN Number')} </Typography.Text>
          </div>
        </Col> */}
        <Col span={1} style={{ minWidth: isFullMediaWidth ? '40px' : '32px' }} />
      </Row>
    </>
  ), [isFullMediaWidth, isResponsive, switchTemperature, t]);

  const IMOClassComponent = useCallback((index) => (
    <CommoditySelect
      value={commodityValues?.[index]?.IMOClass || null}
      data-class={`IMOClassComponent_${index}`}
      allowClear={!!commodityValues?.[index]?.IMOClass}
      disabled={isAllDisabled}
      suffixIcon={collapseIcon}
      isError={!!errorsCommodity?.[index]?.IMOClass?.message
        && (!!errorsCommodity?.[index]?.IMOClass?.isVisited || isSubmitVisited)}
      onClear={onClearCommodityIMOClass(index)}
      onChange={onChangeCommodityIMOClass(index)}
      onFocus={onFocusCommodityIMOClass(index)}
      onBlur={onBlurCommodityIMOClass(index)}
    >
      {IMOClassData.map((itemIMO, indexIMO) => (
        <Option key={`${indexIMO + 1}_IMOClass.map`} value={`${itemIMO.value}`}>
          {itemIMO.name}
        </Option>
      ))}
    </CommoditySelect>
  ), [
    commodityValues,
    errorsCommodity,
    isAllDisabled,
    isSubmitVisited,
    t,
  ]);

  const onChangeCommodityHsCodeHandler = useCallback(
    (index: number) => (value: string, option: unknown) => {
      const { description } = option as { description: string } || {};

      if (value) {
        onChangeCommodityHsCode(index)({
          code: value,
          description: description || '',
        });
      }
    },
    [onChangeCommodityHsCode],
  );

  const debounceHsCodeSelect = useMemo(() => debounce((index: number, newValue: string) => {
    getRFQCommodityHsCodeDyIndex(index)(newValue);
  }, debounceTime), [getRFQCommodityHsCodeDyIndex]);
  const throttledHsCodeSelect = useMemo(() => throttle((index: number, newValue: string) => {
    debounceHsCodeSelect(index, newValue);
  }, 40), [debounceHsCodeSelect]);

  const onKeyPressHsCodeByIndexHandler = useCallback((index: number) => (key: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyPressHsCodeByIndex(index)(key.key);
  }, [onKeyPressHsCodeByIndex]);

  const onSearchHsCodeSelectHandler = useCallback(
    (index: number) => (value: string) => {
      throttledHsCodeSelect(index, value);
    },
    [throttledHsCodeSelect],
  );

  const getHsCodeValue = useCallback(
    (index: number) => {
      let value = null;

      if (commodityValues?.[index]?.hsCode?.code) {
        value = commodityValues?.[index]?.hsCode?.code;
      }

      if (value && commodityValues?.[index]?.hsCode?.description) {
        value = `${value} ${commodityValues?.[index]?.hsCode?.description}`;
      }

      if (!value && commodityValues?.[index]?.hsCode?.description) {
        value = `${commodityValues?.[index]?.hsCode?.description}`;
      }

      return value;
    },
    [commodityValues],
  );

  const sortCommoditiesByHSCode = useCallback((commoditiesToSort: IFreightSelectFieldDTM[]) => [...commoditiesToSort].sort((a, b) => (a.code ? +a.code : 0) - (b.code ? +b.code : 0)), []);
  const getSortedCommodityOptions = useCallback((index) => {
    const options = hsCodeState?.[index]?.values || [];

    return {
      forbiddenCommodities: sortCommoditiesByHSCode(options.filter((option) => option.forbidden)),
      allowedCommodities: sortCommoditiesByHSCode(options.filter((option) => !option.forbidden)),
    };
  }, [hsCodeState]);

  const HsCodeSelectComponent = useCallback((index: number, dropdownMatchSelectWidth?: number) => {
    const { forbiddenCommodities, allowedCommodities } = getSortedCommodityOptions(index);

    return (
      <CommoditySelect
        value={getHsCodeValue(index)}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        data-class={`commodityTypeComponent_${index}`}
        isError={!!errorsCommodity?.[index]?.hsCode?.message
          && (!!errorsCommodity?.[index]?.hsCode?.isVisited || isSubmitVisited)}
        showSearch
        allowClear={!!commodityValues?.[index]?.hsCode}
        autoClearSearchValue={false}
        disabled={isAllDisabled}
        onSearch={onSearchHsCodeSelectHandler(index)}
        onClear={onClearCommodityHsCode(index)}
        placeholder={`${t('Start entering H.S or Name')}`}
        notFoundContent={getNotFoundContent(hsCodeState?.[index]?.status || '', !!hsCodeState?.[index]?.values?.length)}
        showArrow={false}
        filterOption={false}
        onChange={onChangeCommodityHsCodeHandler(index)}
        onFocus={onFocusCommodityHsCode(index)}
        onBlur={onBlurCommodityHsCode(index)}
        onKeyDown={onKeyPressHsCodeByIndexHandler(index)}
      >
        {allowedCommodities.map(({
          id, code, description,
        }) => (
          <>
            <Option
              key={`origin_locationValues_${id} `}
              description={description}
              value={code || ''}
            >
              {code || ''} {description}
            </Option>
          </>
        ))}

        {forbiddenCommodities.length ? (
          <>
            <Option
              description={t('Excluded Commodities')}
              value=""
              disabled
            >
              <CommoditySelectOptionHeader isDisabled>
                {t('Excluded Commodities')}
              </CommoditySelectOptionHeader>
            </Option>

            {forbiddenCommodities.map(({
              id,
              code,
              description,
            }) => (
              <Option
                key={`origin_locationValues_${id} `}
                description={description}
                value={code || ''}
                disabled
              >
                {code || ''} {description}
              </Option>
            ))}
          </>
        ) : null}
      </CommoditySelect>
    );
  }, [getSortedCommodityOptions, throttledHsCodeSelect, commodityValues, errorsCommodity, isAllDisabled, hsCodeState, isSubmitVisited, onClearCommodityHsCode, onSearchHsCodeSelectHandler, onFocusCommodityHsCode, onBlurCommodityHsCode, onKeyPressHsCodeByIndexHandler, onChangeCommodityHsCodeHandler]);

  const onChangeCommodityGoodsValueHandler = useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeCommodityGoodsValue(index)(event.target.value);
    },
    [onChangeCommodityGoodsValue],
  );

  const GoodsValuesComponent = useCallback(
    (index: number) => (
      <CommodityInput
        value={commodityValues?.[index]?.goodsValue || ''}
        data-class={`GoodsValuesComponent_${index}`}
        $isValue={!commodityValues?.[index]?.goodsValue}
        prefix="$"
        $isError={!!errorsCommodity?.[index]?.goodsValue?.message
          && (!!errorsCommodity?.[index]?.goodsValue?.isVisited || isSubmitVisited)}
        disabled={isAllDisabled}
        onChange={onChangeCommodityGoodsValueHandler(index)}
        onFocus={onFocusCommodityGoodsValue(index)}
        onBlur={onBlurCommodityGoodsValue(index)}
      />
    ),
    [commodityValues, isSubmitVisited, errorsCommodity, isAllDisabled, onChangeCommodityGoodsValue, onFocusCommodityGoodsValue, onBlurCommodityGoodsValue, t],
  );

  const onChangeCommodityUNNumberHandler = useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeCommodityUNNumber(index)(event.target.value);
    },
    [onChangeCommodityUNNumber],
  );

  const UNNumberComponent = useCallback(
    (index: number) => (
      <CommodityInput
        value={commodityValues?.[index]?.UNNumber || ''}
        data-class={`UNNumberComponent_${index}`}
        placeholder={t('Example: 1648')}
        $isError={!!errorsCommodity?.[index]?.UNNumber?.message
          && (!!errorsCommodity?.[index]?.UNNumber?.isVisited || isSubmitVisited)}
        disabled={isAllDisabled}
        onChange={onChangeCommodityUNNumberHandler(index)}
        onFocus={onFocusCommodityUNNumber(index)}
        onBlur={onBlurCommodityUNNumber(index)}
      />
    ),
    [commodityValues, isSubmitVisited, errorsCommodity, isAllDisabled, onChangeCommodityUNNumber, onFocusCommodityUNNumber, onBlurCommodityUNNumber],
  );

  const itemCommodityIsFull = useCallback((item: FreightFromCommodityValuesStateDTM, index) => (
    <Row className={`${index === 0 ? 'FreightQuoteFormRequest__Row__Commodity__parent_content_item_first' : ''} FreightQuoteFormRequest__Row__Commodity__content FreightQuoteFormRequest__Row__Commodity__parent_content_item`} key={`${item.id} `}>
      {/* Currently align missing blocks */}
      <Col span={18} style={{ maxWidth: 'calc(75% + 14px)', flex: 'auto' }}>
        {HsCodeSelectComponent(index, 400)}
      </Col>
      <Col span={4}>
        {GoodsValuesComponent(index)}
      </Col>
      {/* Currently hidden */}
      {/* <Col span={4}>
        {IMOClassComponent(index)}
      </Col> */}
      {/* <Col
        span={4}
      >
        <Tooltip
          visible={!!errorsCommodity?.[index]?.UNNumber?.message && (!!errorsCommodity?.[index]?.UNNumber?.isFocus || !!errorsCommodity?.[index]?.UNNumber?.isBlur) && !!errorsCommodity?.[index]?.UNNumber?.isVisited}
          title={`UN Number ${t('Validations.string.shouldEqualLength')} 4`}
          placement="bottom"
          mode="danger"
        >
          {UNNumberComponent(index)}
        </Tooltip>
      </Col> */}
      <Tooltip
        visible={index === 0 && temperatureControl ? undefined : false}
        className="FreightQuoteFormRequest__Row__Commodity__content__col_add__tooltip"
        title={t('btnPlusTooltip')}
        placement="topRight"
      >
        <Col span={1} className="FreightQuoteFormRequest__Row__Commodity__content__col_add">
          {(index === 0
            && (
              <Button
                onClick={onAddCommodityValuesItem}
                disabled={temperatureControl || isAllDisabled}
                type="dashed"
                icon={(
                  <PlusSvg
                    style={
                      {
                        color: temperatureControl ? '#D1D7E2' : undefined,
                      }
                    }
                    height={11}
                    width={11}
                  />
                )}
                className="FreightQuoteFormRequest__Row__Commodity__content__col_add_btn"
              />
            ))
            || (
              <Button
                onClick={onRemoveCommodityValuesItem(index)}
                type="dashed"
                icon={<MinusSvg height={11} width={11} />}
                className="FreightQuoteFormRequest__Row__Commodity__content__col_add_btn"
              />
            )}
        </Col>
      </Tooltip>
    </Row>
  ), [
    errorsCommodity,
    temperatureControl,
    isAllDisabled,
    t,
    GoodsValuesComponent,
    IMOClassComponent,
    UNNumberComponent,
    onRemoveCommodityValuesItem,
    HsCodeSelectComponent, handleAddButton, onAddCommodityValuesItem,
  ]);

  const itemCommodityIsMedium = useCallback((item: FreightFromCommodityValuesStateDTM, index) => (
    <Row
      className={`${index === 0 ? 'FreightQuoteFormRequest__Row__Commodity__parent_content_item_first' : ''} FreightQuoteFormRequest__Row__Commodity__content FreightQuoteFormRequest__Row__Commodity__parent_content_item
FreightQuoteFormRequest__Row__Commodity__parent_content_item__medium`}
      key={`${item.id} `}
    >
      <Col span={24}>
        <Row>
          <Col span={18}>
            <div className="FreightQuoteFormRequest__Row__Commodity__content_title">
              <Typography.Text>{t('Type')} </Typography.Text>
              <Typography.Text className="text_placeholder">{t('default FAK')}
                <Tooltip title={t('fakInfoText')} placement="top">
                  <InfoCircleOutlined className="FreightQuoteFormRequest__Row__Commodity__content_title_icon" />
                </Tooltip>
              </Typography.Text>
            </div>
          </Col>
          <Col span={6} className="FreightQuoteFormRequest__Row__Commodity__content__col_add">
            {index !== 0 && (
              <Button
                onClick={onRemoveCommodityValuesItem(index)}
                disabled={isAllDisabled}
                type="dashed"
                size="small"
                icon={<CloseSvg height={11} width={11} />}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {HsCodeSelectComponent(index)}
          </Col>
        </Row>
      </Col>
      <Col span={24} className="FreightQuoteFormRequest__Row__Commodity__content__row_second">
        <Row>
          <Col span={8}>
            <div className="FreightQuoteFormRequest__Row__Commodity__content_title">
              <Typography.Text>{t('Value')}</Typography.Text>
            </div>
          </Col>
          {/* Currently hidden */}
          {/* <Col span={8}>
            <div className="FreightQuoteFormRequest__Row__Commodity__content_title">
              <Typography.Text>{t('IMO Class')}</Typography.Text>
            </div>
          </Col> */}
          {/* <Col span={8}>
            <div className="FreightQuoteFormRequest__Row__Commodity__content_title">
              <Typography.Text>{t('UN Number')} </Typography.Text>
            </div>
          </Col> */}

        </Row>
        <Row>
          <Col span={24}>
            {GoodsValuesComponent(index)}
          </Col>
          {/* Currently hidden */}
          {/* <Col span={8}>
            <span>
              {IMOClassComponent(index)}
            </span>
          </Col> */}
          {/* <Col span={8}>
            <Tooltip
              visible={!!errorsCommodity?.[index]?.UNNumber?.message && (!!errorsCommodity?.[index]?.UNNumber?.isFocus || !!errorsCommodity?.[index]?.UNNumber?.isBlur) && !!errorsCommodity?.[index]?.UNNumber?.isVisited}
              title={`UN Number ${t('Validations.string.shouldEqualLength')} 4`}
              placement="bottom"
              mode="danger"
            >
              {UNNumberComponent(index)}
            </Tooltip>
          </Col> */}
        </Row>
      </Col>
    </Row>
  ), [t,
    errorsCommodity,
    isAllDisabled,
    GoodsValuesComponent,
    IMOClassComponent,
    UNNumberComponent,
    HsCodeSelectComponent,
  ]);

  const commodityComponent = useMemo(() => (
    <>
      {headerIsFullComponent}
      {commodityValues?.map((item, index) => (
        isResponsive ? itemCommodityIsFull(item, index) : itemCommodityIsMedium(item, index)
      ))}
      {
        isInfoBlock && (
          <Row className="FreightQuoteFormRequest__Row__msg">
            <Col span={24}><AlertBlock type="warning" message={t('onlyOneCommotiy')} /></Col>
          </Row>
        )
      }
      {!isResponsive && (
        <Row justify="start" className="FreightQuoteFormRequest__Row__col_both_col__title_owned">
          <Tooltip
            visible={temperatureControl ? undefined : false}
            className="FreightQuoteFormRequest__Row__Commodity__content__col_add__tooltip"
            title={t('btnPlusTooltip')}
            placement="topLeft"
          >
            <Col span={24} className="isMedium">
              {addBtnIsMedium}
            </Col>
          </Tooltip>
        </Row>
      )}

      {/* Currently hidden */}
      {false && (
        <CommodityHazmats>
          <CommodityHazmatsIcon>
            <HazmatsSvg />
          </CommodityHazmatsIcon>
          <CommodityHazmatsText>
            {t('Hazmats will be available in the coming weeks.')}
          </CommodityHazmatsText>
        </CommodityHazmats>
      )}
    </>
  ), [
    temperatureControl,
    isInfoBlock,
    t,
    headerIsFullComponent,
    addBtnIsMedium,
    isResponsive,
    itemCommodityIsFull,
    itemCommodityIsMedium,
  ]);

  const endVisible = useRef<HTMLElement>(null);

  const genExtra = useCallback(
    () => (
      <div
        onClick={(event) => event.stopPropagation()}
        role="button"
        onKeyDown={() => ''}
        tabIndex={0}
        className="FreightQuoteFormRequest__Row__Commodity__temperature"
      >
        {switchTemperature}
      </div>
    ),
    [switchTemperature],
  );

  const [
    changeErrorIsCollapseState, setChangeErrorIsCollapseState,
  ] = useState<Array<string>>([]);

  useEffect(() => {
    if (isSubmitVisited && isCommodityError) {
      setChangeErrorIsCollapseState(['1']);
    }
  }, [isSubmitVisited, isCommodityError, isSubmitClicked]);

  useEffect(() => {
    if (quotaStatus === REQUEST_STATUS.pending) {
      if (commodityValues?.some((item) => (item.hsCode?.code || item.IMOClass || item.goodsValue || item.UNNumber))) {
        setChangeErrorIsCollapseState(['1']);
        return;
      }
      setChangeErrorIsCollapseState([]);
    }
  }, [quotaStatus, commodityValues]);

  const changeErrorIsCollapseCallback = useCallback(
    () => {
      setChangeErrorIsCollapseState((prev) => (prev?.length ? [] : ['1']));
    },
    [],
  );

  const collapseOnChangeHandler = useCollapseOnChangeHandler(
    endVisible.current, changeErrorIsCollapseCallback,
  );

  const collapseComponent = useMemo(() => (
    <Collapse
      expandIcon={({ isActive }) => ExpandIcon({
        isActive,
        color: `${changeErrorIsCollapseState.length ? themesColors.primaryBranding6 : themesColors.neutralBranding7} `,
      })}
      onChange={collapseOnChangeHandler}
      activeKey={changeErrorIsCollapseState}
    >
      <Panel header={t('Commodity')} key="1" extra={genExtra()}>
        {commodityComponent}
      </Panel>
    </Collapse>
  ), [changeErrorIsCollapseState, commodityComponent, genExtra, t]);

  return (
    <CommodityWrapper isNotCollapsed={!isCollapsed}>
      <Row className="FreightQuoteFormRequest__Row__col_both FreightQuoteFormRequest__Row__Commodity FreightQuoteFormRequest__Row__col__commodityPanel">
        <Col span={24}>
          {!isResponsive ? (
            collapseComponent
          ) : (
            commodityComponent
          )}
        </Col>
      </Row>
    </CommodityWrapper>
  );
});

const CachedCommodityComponent = memo(CommodityComponent);

export { CachedCommodityComponent as CommodityComponent };
