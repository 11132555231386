import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface IStyledProps {
  isSecondStep?: boolean;
}

export const Wrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Number = styled.div<IStyledProps>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #6C79DE;
  font-weight: 400;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NumberDisabled = styled(Number)`
  border: 1px solid ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
  color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
  background: transparent;
`;

export const EmptyNumber = styled(Number)`
  background: transparent;
  border: 1px solid #6C79DE;
`;

export const SecondNumber = styled(Number)<IStyledProps>`
  color: ${({ isSecondStep }) => (isSecondStep ? 'white' : '#D1D7E2')};
  border: ${({ isSecondStep }) => (isSecondStep ? 'none' : '1px solid #D1D7E2')};
  background: ${({ isSecondStep }) => (isSecondStep ? '#6C79DE' : 'transparent')};
`;

export const Title = styled.span<IStyledProps>`
  color: ${({ isSecondStep }) => (isSecondStep ? '#202C3C' : '#D1D7E2')};
  font-weight: 400;
  font-size: 12px;
  margin-left: 8px;
`;

export const CustomSeparator = styled.div<IStyledProps>`
  border: ${({ isSecondStep }) => !isSecondStep && '1px solid #EAEEF8'};
  border-top: ${({ isSecondStep }) => isSecondStep && '1px solid #6C79DE'};
  width: 280px;
  margin: 0 14px;
`;

export const WarningIcon = styled(ExclamationCircleOutlined)`
  font-size: 24px;
  color: ${({ theme }) => theme.themesColors.characterBrandingWarning};
`;
