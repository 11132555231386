import React from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import {
  ChangeRequestDrawerComponent,
} from 'shipment-operations/view/drawers/ChangeRequestDrawer/ChangeRequestDrawer.component';
import { ChangeRequestDrawerController } from 'shipment-operations/controllers/ChangeRequestDrawerController';
import { ChangeRequestStore } from 'shipment-operations/repository/mobxStores';
import { EChangeRequestTypeStepToComplete } from 'app-wrapper/constants';

export const ChangeRequestDrawerContainer = observer(() => {
  const controller = useCurrentController<ChangeRequestDrawerController>();
  const store = useCurrentControllerStore<ChangeRequestStore>();
  const steps = EChangeRequestTypeStepToComplete[store.state.type];

  return (
    <ChangeRequestDrawerComponent
      isLoading={store.state.isLoading}
      changeRequest={store.state.changeRequest}
      onClose={controller.closeDrawer}
      type={store.state.type}
      editMode={store.state.editMode}
      steps={steps}
    />
  );
});
