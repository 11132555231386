import { ENotificationCriticality, ENotificationTaskType, REQUEST_STATUS } from 'app-wrapper/constants';
import {
  CommandCenterGetCommandCenterGetNotificationsDTM,
  CommandCenterGetTasksDTM, ICommandCenterGetTasksDTM,
  NotificationTaskDTM, NotificationViewedFiltersDTM,
} from 'app-wrapper/models/dtm';
import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { R, R as appR } from 'app-wrapper/repository';
import moment from 'moment/moment';
import { ShipmentAllStatsDTM } from 'shipment-operations/models/dtm';
import { OrganizationDTM } from 'user-management/models/dtm';
import { R as UserManagementR } from 'user-management/repository';
import { EOrganizationMemberRole } from 'user-management/constants';

const TasksBlockSize = '100';
const TasksScrollBlockSize = '20';

const defaultParams = CommandCenterGetTasksDTM.fromPlain({
  page: `${0}`,
  size: TasksBlockSize,
  sort: 'dueDate,asc',
  query: 'TODO',
});

const getTasks = async (paramsTasks?: CommandCenterGetTasksDTM) => new Promise<NotificationTaskDTM | null>((resolve, reject) => {
  try {
    const res = R.services.CommandCenter.getTasks({
      ...defaultParams,
      ...paramsTasks,
    });

    resolve(res);
  } catch (e) {
    reject(e);
  }
});

const getTasksStats = async (paramsTasks?: CommandCenterGetTasksDTM) => new Promise<ShipmentAllStatsDTM | undefined>((resolve, reject) => {
  try {
    const res = R.services.CommandCenter.getOrganizationStats({
      ...defaultParams,
      ...paramsTasks,
    });

    resolve(res);
  } catch (e) {
    reject(e);
  }
});

export class CommandCenterUseCase extends BaseUseCase {
  public getNotifications = async (_defaultParams: CommandCenterGetCommandCenterGetNotificationsDTM, paramsTasks?: CommandCenterGetCommandCenterGetNotificationsDTM) => new Promise<NotificationTaskDTM | null>((resolve, reject) => {
    try {
      const res = appR.services.CommandCenter.getNotifications({
        ..._defaultParams,
        ...paramsTasks,
      });

      resolve(res);
    } catch (e) {
      reject(e);
    }
  })

  public getTasksFiltersRequestParams(params?: {
    dateFrom?: string,
    dateTo?: string,
    isOverdue?: boolean,
    isToday?: boolean,
    isWeek?: boolean,
    isLater?: boolean,
    isReset?: boolean,
  }) {
    const {
      dateFrom,
      dateTo,
      isOverdue,
      isToday,
      isWeek,
      isLater,
    } = params || {};
    const { currentState: { filters } } = this.store.getState().commandCenter;
    const { organization } = this.store.getState().userOrganizationData;
    const isCustomerRequestsTasksType = organization?.role === EOrganizationMemberRole.CUSTOMER && filters?.typeTask === ENotificationTaskType.CHANGE_REQUEST;
    const isCustomerRequestsTasks = isCustomerRequestsTasksType && filters?.isOnlyUnread === true;

    const { email } = this.store.getState().auth;
    let dueDateFrom: string | undefined = dateFrom;
    let dueDateTo: string | undefined = dateTo;

    const getDates = () => {
      const earliestDate = filters?.filterReceivedDates?.dates?.earliestDate ? moment(filters?.filterReceivedDates?.dates?.earliestDate) : undefined;
      const latestDate = filters?.filterReceivedDates?.dates?.latestDate ? moment(filters?.filterReceivedDates?.dates?.latestDate).endOf('day') : undefined;

      const diffFrom = moment(dateFrom).startOf('day').diff(filters?.filterReceivedDates?.dates?.earliestDate);
      const diffTo = moment(dateTo).diff(moment(filters?.filterReceivedDates?.dates?.latestDate).endOf('day').format());
      const diffFromTo = moment(dateFrom).startOf('day').diff(moment(dateTo).endOf('day').format());
      const diffLatestToday = moment(filters?.filterReceivedDates?.dates?.latestDate).startOf('day').diff(moment().endOf('day'));

      if (isOverdue && earliestDate && diffFrom >= 0) {
        dueDateFrom = earliestDate?.format();
      } else if (isOverdue) {
        dueDateFrom = 'undefined';
      }

      if (isOverdue && latestDate && diffLatestToday <= 0) {
        dueDateTo = latestDate?.format();
      } else if (isOverdue && diffLatestToday >= 0) {
        dueDateTo = moment().endOf('day').format();
      } else if (isOverdue) {
        dueDateTo = 'undefined';
      }

      if (isToday && diffFrom >= 0) {
        dueDateFrom = dateFrom;
      } else if (isToday) {
        dueDateFrom = 'undefined';
      }
      if (isToday && diffTo <= 0) {
        dueDateTo = dateTo;
      } else if (isToday) {
        dueDateTo = 'undefined';
      }

      if (isWeek && diffFrom <= 0 && diffFrom >= diffFromTo) {
        dueDateFrom = earliestDate?.format();
      } else if (isWeek
        && (earliestDate && moment(dateFrom).valueOf() >= earliestDate?.valueOf())) {
        dueDateFrom = dateFrom;
      } else if (isWeek) {
        dueDateFrom = 'undefined';
      }
      if (isWeek
        && (latestDate && moment(dateTo).valueOf() >= latestDate?.valueOf())) {
        dueDateTo = latestDate?.format();
      } else if (isWeek
        && (latestDate && moment(dateTo).valueOf() <= latestDate?.valueOf())) {
        dueDateTo = dateTo;
      } else if (isWeek) {
        dueDateTo = 'undefined';
      }

      if (isLater
        && (earliestDate && moment(dateFrom).valueOf() <= earliestDate?.valueOf())) {
        dueDateFrom = earliestDate?.format();
      } else if (isLater
        && (earliestDate && moment(dateFrom).valueOf() >= earliestDate?.valueOf())) {
        dueDateFrom = dateFrom;
      } else if (isLater) {
        dueDateFrom = 'undefined';
      }

      if (isLater
        && (latestDate && moment(dateFrom).valueOf() <= latestDate?.valueOf())) {
        dueDateTo = latestDate?.format();
      } else if (isLater) {
        dueDateTo = 'undefined';
      }
    };

    if (filters?.filterReceivedDates?.disableReset === false) {
      getDates();
    }

    const domainGroup = filters?.filterModule?.group
      ?.filter((itemFilter) => itemFilter.checked)
      ?.map((itemFilter) => itemFilter.value);

    const assigneeEmailGroup = filters?.filterAssignee?.group
      ?.filter((itemFilter) => itemFilter.checked)
      ?.map((itemFilter) => itemFilter.value);

    const assigneeOrganizationEmailGroup = filters?.filterAssigneeOrganization?.group
      ?.filter((itemFilter) => itemFilter.checked)
      ?.map((itemFilter) => itemFilter.value);

    const referenceOrganization = filters?.filterTreeDataObject?.group
      ?.filter((itemFilter) => (itemFilter.checked && itemFilter.value2 === 'Organization'))
      ?.map((itemFilter) => itemFilter.id);
    const referenceRate = filters?.filterTreeDataObject?.group
      ?.filter((itemFilter) => (itemFilter.checked && itemFilter.value2 === 'Request'))
      ?.map((itemFilter) => itemFilter.id);
    const referenceShipment = filters?.filterTreeDataObject?.group
      ?.filter((itemFilter) => (itemFilter.checked && itemFilter.value2 === 'Shipment'))
      ?.map((itemFilter) => itemFilter.id);

    const assigneeOrganizationIdsGroup = assigneeOrganizationEmailGroup?.length ? assigneeOrganizationEmailGroup : undefined;
    const assigneeOrganizationIdsGroupCurrent = filters?.isOnlyUnread === true && !isCustomerRequestsTasks ? [organization?.id] : undefined;
    const userOrganizationIds = [organization?.id];

    const assigneeUserEmails = assigneeEmailGroup || undefined;

    const assigneeUserEmailsParams = filters?.isOnlyUnread !== true && !assigneeUserEmails?.length && !isCustomerRequestsTasks ? [email] : assigneeUserEmails;

    const isUserUnassigned = Boolean(assigneeUserEmails?.find((_email) => _email === 'false'));

    const userAssigned = isUserUnassigned ? 'false' : undefined;

    return {
      dueDateFrom,
      dueDateTo,
      type: filters?.typeTask,
      domain: domainGroup?.length ? domainGroup?.join(',') : undefined,
      assigneeUserEmails: isUserUnassigned || (isCustomerRequestsTasksType && filters?.isOnlyUnread !== true) ? undefined : assigneeUserEmailsParams,
      assigneeOrganizationIds: assigneeOrganizationIdsGroup || assigneeOrganizationIdsGroupCurrent,
      userOrganizationIds: isCustomerRequestsTasks ? userOrganizationIds : undefined,
      createdBy: isCustomerRequestsTasksType && filters?.isOnlyUnread !== true ? email : undefined,
      assigneeUserAssigned: userAssigned,
      objectReferenceOrganizationIds: referenceOrganization,
      objectReferenceRateRequestIds: referenceRate,
      objectReferenceShipmentIds: referenceShipment,
      createdAtFrom: filters?.filterReceivedDates?.dates?.earliestDate ? moment(filters?.filterReceivedDates?.dates?.earliestDate).format() : undefined,
      createdAtTo: filters?.filterReceivedDates?.dates?.latestDate ? moment(filters?.filterReceivedDates?.dates?.latestDate).endOf('day').format() : undefined,
      completedAtFrom: filters?.filterCompletionDates?.dates?.earliestDate ? moment(filters?.filterCompletionDates?.dates?.earliestDate).format() : undefined,
      completedAtTo: filters?.filterCompletionDates?.dates?.latestDate ? moment(filters?.filterCompletionDates?.dates?.latestDate).endOf('day').format() : undefined,
    } as ICommandCenterGetTasksDTM;
  }

  public async onRunTasksUpdateFilters(shipmentId?: string, isReset?: boolean) {
    const { currentState } = this.store.getState().commandCenter;
    const {
      filters, status, statusPage, criticality,
    } = currentState;

    let tasksAll: NotificationTaskDTM | null = null;
    let tasksOverdue: NotificationTaskDTM | null = null;
    let tasksToday: NotificationTaskDTM | null = null;
    let tasksThisWeek: NotificationTaskDTM | null = null;
    let tasksLater: NotificationTaskDTM | null = null;

    if (status === REQUEST_STATUS.pending) return;
    this.dispatch(R.actions.commandCenter.setDefaultCurrentState());

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.commandCenter.setCurrentCriticality(criticality));
    this.dispatch(R.actions.commandCenter.setContent([]));

    const requestPage = 0;

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${requestPage}`,
      size: TasksBlockSize,
      sort: 'dueDate,asc',
      query: statusPage || 'TODO',
      criticality,
      shipmentId,
    });

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      isRequest: true,
    })));

    this.onLoadStatisticsTasks(shipmentId);
    const [res1, res2, res3, res4, res5] = await Promise.all<NotificationTaskDTM | null>([
      !statusPage ? getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        sort: 'dueDate,desc',
        dueDateTo: moment().format(),
        ...this.getTasksFiltersRequestParams({
          dateTo: moment().format(),
          isOverdue: true,
          isReset,
        }),
      })) : new Promise((resolve) => resolve(null)),
      !statusPage ? getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        dueDateFrom: moment().format(),
        dueDateTo: moment().endOf('day').format(),
        ...this.getTasksFiltersRequestParams({
          dateFrom: moment().format(),
          dateTo: moment().endOf('day').format(),
          isToday: true,
          isReset,
        }),
      })) : new Promise((resolve) => resolve(null)),
      !statusPage ? getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        dueDateFrom: moment().add('days', 1).startOf('day').format(),
        dueDateTo: moment().add('days', 7).endOf('day').format(),
        ...this.getTasksFiltersRequestParams({
          dateFrom: moment().add('days', 1).startOf('day').format(),
          dateTo: moment().add('days', 7).endOf('day').format(),
          isWeek: true,
          isReset,
        }),
      })) : new Promise((resolve) => resolve(null)),
      !statusPage ? getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        includeEmptyDueDate: 'true',
        dueDateFrom: moment().add('days', 8).startOf('day').format(),
        ...this.getTasksFiltersRequestParams({
          dateFrom: moment().add('days', 8).startOf('day').format(),
          isLater: true,
          isReset,
        }),
      })) : new Promise((resolve) => resolve(null)),
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        size: TasksScrollBlockSize,
        sort: 'completedAt,desc',
        ...this.getTasksFiltersRequestParams({
        }),
      })),
    ]).catch();

    tasksOverdue = res1;
    tasksToday = res2;
    tasksThisWeek = res3;
    tasksLater = res4;
    tasksAll = res5;

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.commandCenter.setCurrentPage(requestPage));
    this.dispatch(R.actions.commandCenter.setTotalPages(tasksAll?.totalPages || 0));
    this.dispatch(R.actions.commandCenter.setIsTaskFirstRequest(true));

    this.dispatch(R.actions.commandCenter.setTotalElements(tasksAll?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setContent(tasksAll?.content || []));
    this.dispatch(R.actions.commandCenter.setCurrentStatusPage(statusPage));

    this.dispatch(R.actions.commandCenter.setContentOverdue(tasksOverdue?.content || []));
    this.dispatch(R.actions.commandCenter.setContentToday(tasksToday?.content || []));
    this.dispatch(R.actions.commandCenter.setContentThisWeek(tasksThisWeek?.content || []));
    this.dispatch(R.actions.commandCenter.setContentLater(tasksLater?.content || []));
    this.dispatch(R.actions.commandCenter.setTotalElementsOverdue(tasksOverdue?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsToday(tasksToday?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsTisWeek(tasksThisWeek?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsLater(tasksLater?.totalElements || 0));

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      isRequest: false,
    })));
  }

  public async onLoadStatisticsTasks(shipmentId?: string) {
    const { currentState: { criticality, filters } } = this.store.getState().commandCenter;

    const { organization } = this.store.getState().userOrganizationData;
    let organizationReq: OrganizationDTM | undefined;

    if (!organization) {
      organizationReq = await UserManagementR.services.organization.getCurrentOrganization();
    }

    const organizationId = organization?.id || organizationReq?.id;
    const assigneeOrganizationIds = organizationId ? [organizationId] : undefined;
    const { email } = this.store.getState().auth;
    const assigneeOrganizationUsers = email ? [email] : undefined;

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${0}`,
      size: '1',
      query: 'TODO',
      criticality,
      shipmentId,
      assigneeUserEmails: !filters?.isOnlyUnread ? assigneeOrganizationUsers : undefined,
      assigneeOrganizationIds: filters?.isOnlyUnread ? assigneeOrganizationIds : undefined,
    });

    const [resTasks] = await Promise.all<ShipmentAllStatsDTM | undefined>([
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...params,
      })),
    ]);

    const tasksOverdue = resTasks?.taskStats.overdue;
    const tasksDue = resTasks?.taskStats.expiring;
    const tasksPlanned = resTasks?.taskStats.todo;
    const tasksAllCounts = (resTasks?.taskStats.overdue || 0) + (resTasks?.taskStats.expiring || 0) + (resTasks?.taskStats.todo || 0);

    this.dispatch(R.actions.commandCenter.setInfoCountAllTasks(tasksAllCounts || 0));
    this.dispatch(R.actions.commandCenter.setInfoCountOverdueTasks(tasksOverdue || 0));
    this.dispatch(R.actions.commandCenter.setInfoCountDueTasks(tasksDue || 0));
    this.dispatch(R.actions.commandCenter.setInfoCountPlannedTasks(tasksPlanned || 0));

    if (criticality?.length === 1 && criticality.includes(ENotificationCriticality.HIGH)) {
      this.dispatch(R.actions.commandCenter.setInfoCountAllTasks(resTasks?.taskStats.alerts || 0));
      this.dispatch(R.actions.commandCenter.setInfoCountOverdueTasks(resTasks?.taskStats.highOverdue || 0));
      this.dispatch(R.actions.commandCenter.setInfoCountDueTasks(resTasks?.taskStats.highExpiring || 0));
      this.dispatch(R.actions.commandCenter.setInfoCountPlannedTasks(resTasks?.taskStats.highTodo || 0));
    }
  }
}
