import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  MissingTextValue,
  GapVerticalContainerStyled,
  SubPageContent,
  GapHorizontalContainerStyled,
  InfoCardComponent,
  DatePriority,
  ContentSectionContainer,
  GreenTag,
  YellowTag, RedTag,
} from 'app-wrapper/view/guideline';
import { NotificationTaskContentDTM } from 'app-wrapper/models/dtm';
import { ENotificationCriticality, ENotificationStatus, RouteNames } from 'app-wrapper/constants';
import { Link } from 'react-router-dom';
import { CollapseBodyOnly, CollapseButton } from 'app-wrapper/view/components';

interface ITaskInfoProps {
  notificationTask: NotificationTaskContentDTM;
  shipmentIdClick?: () => void;
}

export const TaskInfo: FC<ITaskInfoProps> = ({
  notificationTask,
  shipmentIdClick,
}) => {
  const { t } = useTranslation();

  const [show, setShow] = useState<boolean>(false);

  const status = useMemo(() => {
    switch (notificationTask.status) {
      case ENotificationStatus.REJECTED:
        return <RedTag>{t('Rejected')}</RedTag>;
      case ENotificationStatus.DONE:
        return <GreenTag>{t('completed')}</GreenTag>;
      case ENotificationStatus.TODO:
        return <YellowTag>{t('Pending')}</YellowTag>;
      default:
        return <YellowTag>{t('Pending')}</YellowTag>;
    }
  }, [notificationTask.status]);

  const timeComponent = useMemo(() => {
    switch (notificationTask.status) {
      case ENotificationStatus.REJECTED:
        return (
          <InfoCardComponent
            title={t('Rejected on')}
            value={
            notificationTask.completedAt
              ? notificationTask.completedAt.getFormatDMMMHHmm()
              : <MissingTextValue />
          }
          />
        );
      case ENotificationStatus.DONE:
        return (
          <InfoCardComponent
            title={t('Completed on')}
            value={
            notificationTask.completedAt
              ? notificationTask.completedAt.getFormatDMMMHHmm()
              : <MissingTextValue />
          }
          />
        );
      case ENotificationStatus.TODO:
        return (
          <InfoCardComponent
            title={t('Due Date')}
            value={
            notificationTask.dueDate
              ? (
                <DatePriority
                  mode={ENotificationCriticality.HIGH}
                  date={notificationTask.dueDate.getFormatDMMMHHmm()}
                  isWarning
                />
              )
              : <MissingTextValue />
          }
          />
        );
      default:
        return <YellowTag>{t('Pending')}</YellowTag>;
    }
  }, [notificationTask]);

  const onClick = useCallback((_show: boolean) => {
    setShow(_show);
  }, [show]);

  const onShipmentIdClick = useCallback(() => {
    if (shipmentIdClick) {
      shipmentIdClick();
    }
  }, [shipmentIdClick]);

  return (
    <SubPageContent>
      <GapHorizontalContainerStyled width="100%">
        <GapVerticalContainerStyled width="100%">
          <GapHorizontalContainerStyled>
            <ContentSectionContainer>
              <InfoCardComponent
                title={t('Status')}
                value={status}
              />
            </ContentSectionContainer>
            <ContentSectionContainer>
              {timeComponent}
            </ContentSectionContainer>
            <ContentSectionContainer>
              <InfoCardComponent
                title={t('Assignee')}
                value={notificationTask.assignee?.getFullName() || notificationTask.assigneeUsers?.[0]?.getFullName() || <MissingTextValue />}
              />
            </ContentSectionContainer>
            <ContentSectionContainer style={{ width: '32px' }}>
              <CollapseButton show={show} onClick={onClick} />
            </ContentSectionContainer>
          </GapHorizontalContainerStyled>
          <CollapseBodyOnly
            isCollapsed={show}
          >
            <GapHorizontalContainerStyled>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Created on')}
                  value={notificationTask.createdAt ? notificationTask.createdAt?.getFormatDMMMHHmm() : <MissingTextValue />}
                />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Created by')}
                  value={notificationTask.createdBy || <MissingTextValue />}
                />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Shipment')}
                  value={(
                    <Link to={RouteNames.SHIPMENT_OVERVIEW(notificationTask.objectReference?.shipmentId)} onClick={onShipmentIdClick}>
                      {notificationTask.metadata?.shipmentName}
                    </Link>
                  ) || <MissingTextValue />}
                />
              </ContentSectionContainer>
              <ContentSectionContainer style={{ minWidth: '32px', width: '32px' }} />
            </GapHorizontalContainerStyled>
          </CollapseBodyOnly>
        </GapVerticalContainerStyled>
      </GapHorizontalContainerStyled>
    </SubPageContent>
  );
};
