import { EChangeRequestType } from 'app-wrapper/constants';
import {
  IsArray, IsBoolean, IsEnum, IsNumber, IsOptional, IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface ICommandCenterGetTasksDTM {
  query?: string
  type?: string
  dueDateTo?: string
  dueDateFrom?: string
  completedAtFrom?: string
  completedAtTo?: string
  createdBy?: string
  sort?: string
  page?: string
  size?: string
  shipmentId?: string
  includeEmptyDueDate?: string
  domain?: string
  criticality?: string[]
  currentUserId?: number
  currentOrganizationId?: number
  targetAudienceOrganizationIds?: number[]
  targetAudienceUserEmails?: string[]
  objectReferenceOrganizationIds?: number[]
  objectReferencePaymentIds?: number[]
  objectReferenceRateRequestIds?: number[]
  objectReferenceShipmentIds?: number[]
  userOrganizationIds?: number[]
  assigneeOrganizationIds?: number[]
  assigneeUserEmails?: string[]
  assigneeOrganizationEmails?: string[]
  assigneeAudienceOrganizationIds?: number[]
  changeRequestType?: EChangeRequestType
}

export class CommandCenterGetTasksDTM extends BaseDTM<ICommandCenterGetTasksDTM> {
  @IsOptional()
  @IsString()
  query?: string;

  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsString()
  dueDateTo?: string;

  @IsOptional()
  @IsString()
  dueDateFrom?: string;

  @IsOptional()
  @IsString()
  completedAtFrom?: string;

  @IsOptional()
  @IsString()
  completedAtTo?: string;

  @IsOptional()
  @IsString()
  createdBy?: string;

  @IsOptional()
  @IsString()
  sort?: string;

  @IsOptional()
  @IsString()
  page?: string;

  @IsOptional()
  @IsString()
  size?: string;

  @IsOptional()
  @IsString()
  shipmentId?: string;

  @IsOptional()
  @IsString()
  includeEmptyDueDate?: string;

  @IsOptional()
  @IsString()
  domain?: string;

  @IsOptional()
  @IsString({ each: true })
  criticality?: string[];

  @IsOptional()
  @IsNumber()
  currentUserId?: number;

  @IsOptional()
  @IsNumber()
  currentOrganizationId?: number;

  @IsOptional()
  @IsArray()
  targetAudienceOrganizationIds?: number[];

  @IsOptional()
  @IsString({ each: true })
  targetAudienceUserEmails?: string[];

  @IsOptional()
  @IsArray()
  objectReferenceOrganizationIds?: number[];

  @IsOptional()
  @IsArray()
  objectReferencePaymentIds?: number[];

  @IsOptional()
  @IsArray()
  objectReferenceRateRequestIds?: number[];

  @IsOptional()
  @IsArray()
  objectReferenceShipmentIds?: number[];

  @IsOptional()
  @IsArray()
  userOrganizationIds?: number[];

  @IsOptional()
  @IsArray()
  assigneeOrganizationIds?: number[];

  @IsOptional()
  @IsString({ each: true })
  assigneeUserEmails?: string[];

  @IsOptional()
  @IsString({ each: true })
  assigneeOrganizationEmails?: string[];

  @IsOptional()
  @IsArray()
  assigneeAudienceOrganizationIds?: number[];

  @IsOptional()
  @IsBoolean()
  assigneeUserAssigned?: boolean

  @IsOptional()
  @IsEnum(EChangeRequestType)
  changeRequestType?: EChangeRequestType
}
