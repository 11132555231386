import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { EShippingPartyTypes } from 'shipment-operations/constants';
import { ShippingPartySection } from 'app-wrapper/view/guideline';

export const SupplierSection = observer(() => {
  const { t } = useTranslation();
  const { shippingPartiesStore } = useMobxStore();
  const cargoSupplier = shippingPartiesStore.getShippingPartyByRole(EShippingPartyTypes.CARGO_SUPPLIER);

  return cargoSupplier ? (
    <ShippingPartySection
      title={t('Cargo Supplier')}
      addressTitle={t('Supplier’s Address')}
      shippingParty={cargoSupplier}
    />
  ) : null;
});
