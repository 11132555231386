import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';
import { Button as BaseButton } from 'app-wrapper/view/components';

/* SmallTextFootnoteDescription dosen't work because guideline not loaded yet, to fix it latter */
export const Button = styled(BaseButton)`
  height: 22px;
  padding: 0 7px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const Icon = styled(EditOutlined)`
  font-size: 14px;
`;
