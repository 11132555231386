import React, { FC, useCallback, useMemo } from 'react';

import { Button } from 'app-wrapper/view/components';
import { useTranslation } from 'react-i18next';
import { NotificationTaskContentDTM } from 'app-wrapper/models/dtm';
import { PermissionsDTM } from 'shipment-operations/view/drawers/ChangeRequestDrawer/permissions/Permissions.dtm';
import { hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';
import {
  ButtonsWrapper,
} from './FooterButtons.styled';

interface IFooterButtonsComponentProps {
  isSubmitting: boolean
  onSave: () => void
  onDelete: () => void
  onEdit: () => void
  onCompleteTask: () => void
  onCancelEditMode: () => void
  changeRequest: NotificationTaskContentDTM | undefined
  editMode: boolean
  permissions: PermissionsDTM | undefined
  isNewRequest: boolean
}

export const FooterButtonsComponent: FC<IFooterButtonsComponentProps> = ({
  onDelete,
  onEdit,
  onSave,
  onCompleteTask,
  isSubmitting,
  changeRequest,
  editMode,
  permissions,
  isNewRequest,
  onCancelEditMode,
}) => {
  const { t } = useTranslation();

  const handleSaveChangeRequest = useCallback(() => {
    onSave();
  }, []);

  const handleDeleteChangeRequest = useCallback(() => {
    onDelete();
  }, []);

  const handleEditChangeRequest = useCallback(() => {
    onEdit();
  }, []);

  const handleCompleteTask = useCallback(() => {
    onCompleteTask();
  }, []);

  const handleCancelEditMode = useCallback(() => {
    onCancelEditMode();
  }, []);

  const editButtons = useMemo(() => (
    <>
      <Button
        type="default"
        disabled={false}
        loading={isSubmitting}
        onClick={handleCancelEditMode}
      >
        {t('Cancel')}
      </Button>
      <Button
        htmlType="submit"
        disabled={false}
        loading={isSubmitting}
        onClick={handleSaveChangeRequest}
      >
        {t('Submit')}
      </Button>
    </>
  ),
  [isSubmitting, handleSaveChangeRequest, handleCancelEditMode]);

  if (isNewRequest) {
    if (!changeRequest) {
      return (
        <Button
          htmlType="submit"
          disabled={false}
          loading={isSubmitting}
          onClick={handleSaveChangeRequest}
        >
          {t('Save')}
        </Button>
      );
    }
    return (
      <>
        {editMode
          ? editButtons
          : (
            <>
              <Button
                danger
                type="text"
                loading={isSubmitting}
                onClick={handleDeleteChangeRequest}
              >
                {t('Delete')}
              </Button>
              <Button
                htmlType="submit"
                disabled={false}
                loading={isSubmitting}
                onClick={handleEditChangeRequest}
              >
                {t('Edit')}
              </Button>
            </>
          )}
      </>
    );
  }

  return (
    <ButtonsWrapper>
      <Button
        danger
        type="text"
        loading={isSubmitting}
        onClick={handleDeleteChangeRequest}
      >
        {t('Reject')}
      </Button>

      <GapHorizontalContainerStyled width="auto">
        {editMode
          ? editButtons
          : (
            <>
              <Button
                type="default"
                disabled={false}
                loading={isSubmitting}
                onClick={handleEditChangeRequest}
              >
                {t('Edit')}
              </Button>

              {hasAccess(permissions?.completeRequestButton, PermissionAttributePolicy.WRITE)
                ? (
                  <Button
                    htmlType="submit"
                    disabled={false}
                    loading={isSubmitting}
                    onClick={handleCompleteTask}
                  >
                    {t('Complete Request')}
                  </Button>
                )
                : null}
            </>
          )}
      </GapHorizontalContainerStyled>
    </ButtonsWrapper>
  );
};
