import { paths as contactServicePaths } from 'api-contracts/dev/contact-service/v1/schema';
import { paths as organizationServicePaths } from 'api-contracts/dev/organization-service/v1/schema';
import { paths as billingServicePaths } from 'api-contracts/dev/billing-service/v1/schema';
import { paths as quotaServicePaths } from 'api-contracts/dev/quota-service/v1/schema';
import { paths as rateServicePaths } from 'api-contracts/dev/rate-service/v1/schema';
import { paths as shipmentServicePaths } from 'api-contracts/dev/shipment-service/v1/schema';
import { paths as trackingServicePaths } from 'api-contracts/dev/tracking-service/v1/schema';
import { paths as planningServicePaths } from 'api-contracts/dev/planning-service/v1/schema';
import { paths as taskServicePaths } from 'api-contracts/dev/task-service/v1/schema';
import {
  NetworkErrorProto,
  RequestEntityTooLargeError,
  BadRequestError,
  TokenExpiredError,
  InternalServerError,
  NotFoundError,
  UnauthorizedError,
  ForbiddenError,
  ConflictError,
  GatewayTimeoutError,
} from 'app-wrapper/models/errors';
import Axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { R } from 'authentication/repository';

import { getBaseUrl, currentSession } from 'app-wrapper/utils';
import { RouteNames } from 'app-wrapper/constants';
import { dispatch } from 'app-wrapper/store';

type HttpVerb = 'get' | 'post' | 'patch' | 'put' | 'delete' | 'update';
type ResponseForMethod<Paths, Path extends keyof Paths, Verb extends HttpVerb> =
  Paths[Path] extends Record<Verb, {
      responses: {
        200: {
          content: {
            'application/json': infer ResponseType,
          }
        }
      }
    }> ? ResponseType :
    (Paths[Path] extends Record<Verb, {
      responses: {
        200: {
          content: {
            '*/*': infer ResponseType,
          }
        }
      }
    }> ? ResponseType : never);

type RequestForMethod<Paths, Path extends keyof Paths, Verb extends HttpVerb> =
  Paths[Path] extends Record<Verb, {
    requestBody: {
      content: {
        'application/json': infer ResponseType,
      }
    }
  }> ? ResponseType : never;

type AllPaths = contactServicePaths & organizationServicePaths & billingServicePaths & quotaServicePaths & rateServicePaths &
  shipmentServicePaths & trackingServicePaths & planningServicePaths & taskServicePaths

class ApiWorker {
  private controller = new AbortController();

  private axiosInstance = Axios.create({
    baseURL: getBaseUrl(),
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

  constructor() {
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        const token = await currentSession.getIdToken();

        if (token && config.headers) {
          config.headers.authorization = `Bearer ${token}`;
        }

        return config;
      },
    );

    this.axiosInstance.interceptors.response.use(
      async (response: AxiosResponse) => {
        if (response.status === 401) {
          try {
            currentSession.signOut();
            dispatch(R.actions.auth.logOut());

            window.location.replace(`${window.location.origin}${RouteNames.SIGN_IN()}`);
          } catch (e: unknown) {
            console.error('log: signOut', e);
          }
        }

        return response;
      },
    );
  }

  private static throwErrors(error: Error) {
    if (error instanceof AxiosError) {
      const errorData = {
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
        status: error.request?.status,
      };

      if (error?.response?.status === UnauthorizedError.status
        && error?.response?.data?.message === 'The incoming token has expired') {
        return TokenExpiredError.fromPlain(errorData);
      }

      const errorClass = [
        BadRequestError,
        UnauthorizedError,
        ForbiddenError,
        NotFoundError,
        ConflictError,
        RequestEntityTooLargeError,
        InternalServerError,
        GatewayTimeoutError,
      ].find((_errorClass) => error?.response?.status === _errorClass.status);

      if (errorClass) {
        return errorClass.fromPlain(errorData);
      }
    }

    return error;
  }

  public static async handleRequest<T>(request: () => T) {
    let result;
    let exceptionClass;
    try {
      result = await request();
    } catch (e) {
      exceptionClass = ApiWorker.throwErrors(e as Error);
      if (!(exceptionClass instanceof TokenExpiredError)) {
        throw exceptionClass;
      }

      try {
        result = await this.retryRequestWithNewToken<T>(request, exceptionClass);
      } catch (error) {
        throw ApiWorker.throwErrors(error as Error);
      }

      if (!result) {
        throw exceptionClass;
      }
    }

    return result;
  }

  private static async retryRequestWithNewToken<T>(request: () => T, exceptionClass?: NetworkErrorProto) {
    let result;
    if (exceptionClass instanceof TokenExpiredError) {
      await currentSession.refreshSession();
      result = await request();
    }

    return result;
  }

  public requestGet = async <T>(url: string, config?: AxiosRequestConfig<T>, removeSignal?: boolean): Promise<AxiosResponse<T, any>> => ApiWorker.handleRequest(
    () => this.axiosInstance.get(url, {
      signal: !removeSignal ? this.controller.signal : undefined,
      ...config,
    }),
  );

  public requestGetBySchema = async <T extends AllPaths, URL extends keyof T>(url: URL, config?: AxiosRequestConfig<T>, removeSignal?: boolean): Promise<AxiosResponse<ResponseForMethod<T, URL, 'get'>, any>> => ApiWorker.handleRequest(
    () => this.axiosInstance.get(url as string, {
      signal: !removeSignal ? this.controller.signal : undefined,
      ...config,
    }),
  );

  public requestPost = async <T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>, removeSignal?: boolean): Promise<R> => ApiWorker.handleRequest(() => this.axiosInstance.post<T, R, D>(url, data, {
    signal: !removeSignal ? this.controller.signal : undefined,
    ...config,
  }));

  public requestPostBySchema = async <Paths extends AllPaths, URL extends keyof Paths, T extends any, R extends AxiosResponse<ResponseForMethod<Paths, URL, 'post'>, any>>(url: URL, data?: RequestForMethod<Paths, URL, 'post'> | string, config?: AxiosRequestConfig<RequestForMethod<Paths, URL, 'post'>>, removeSignal?: boolean): Promise<R> => ApiWorker.handleRequest(() => this.axiosInstance.post<T, R, RequestForMethod<Paths, URL, 'post'> | string>(url as string, data, {
    signal: !removeSignal ? this.controller.signal : undefined,
    ...config,
  }));

  public requestPut = async <T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> => ApiWorker.handleRequest(() => this.axiosInstance.put<T, R, D>(url, data, config));

  public requestPutBySchema = async <Paths extends AllPaths, URL extends keyof Paths, T extends any, R extends AxiosResponse<ResponseForMethod<Paths, URL, 'put'>, any>>(url: URL, data?: RequestForMethod<Paths, URL, 'put'> | string, config?: AxiosRequestConfig<RequestForMethod<Paths, URL, 'put'>>): Promise<R> => ApiWorker.handleRequest(() => this.axiosInstance.put<T, R, RequestForMethod<Paths, URL, 'put'> | string>(url as string, data, config));

  public requestPatch = async <T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> => ApiWorker.handleRequest(() => this.axiosInstance.patch<T, R, D>(url, data, config));

  public requestPatchBySchema = async <Paths extends AllPaths, URL extends keyof Paths, T extends any, R extends AxiosResponse<ResponseForMethod<Paths, URL, 'patch'>, any>>(url: URL, data?: RequestForMethod<Paths, URL, 'patch'> | string, config?: AxiosRequestConfig<RequestForMethod<Paths, URL, 'patch'>>): Promise<R> => ApiWorker.handleRequest(() => this.axiosInstance.put<T, R, RequestForMethod<Paths, URL, 'patch'> | string>(url as string, data, config));

  public requestDelete = async <T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> => ApiWorker.handleRequest(() => this.axiosInstance.delete<T, R, D>(url, config));

  public requestDeleteBySchema = async <Paths extends AllPaths, URL extends keyof Paths, T extends any, R extends AxiosResponse<ResponseForMethod<Paths, URL, 'delete'>, any>, D = RequestForMethod<Paths, URL, 'delete'>>(url: URL, config?: AxiosRequestConfig<D>): Promise<R> => ApiWorker.handleRequest(() => this.axiosInstance.delete<T, R, D>(url as string, config));

  public isAPIError = (error: AxiosError | Error) => Axios.isAxiosError(error);

  public abortAllRequests = () => {
    this.controller.abort();
    this.controller = new AbortController();
  };
}

export const apiWorker = new ApiWorker();
