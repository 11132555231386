import React, {
  FC, memo, SyntheticEvent, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { CommandCenterPageTypeValues, ECommandCenterPageType } from 'app-wrapper/enums';
import { TableSkeleton } from 'app-wrapper/view/components';
import { REQUEST_STATUS } from 'app-wrapper/constants';
import { CommandCenterComponentTaskDTM } from 'app-wrapper/models/dtm';

import {
  CCContentTable,
  CCContentTableContent,
  CCContentTableHeader,
  CCContentTableHeaderItem,
} from './ContentTableTasks.styled';
import { ContentTableTasksSectionComponent } from './Components';

interface IContentTableTasksProps {
  onStart: () => void;
  nextPageTasks?: () => void;
  checkAnotherDownloadNextPageTask: (parentHeight: number, elementHeight: number) => void;
  openAgreementsDrawer?: (shipmentId: string) => void;
  isCompleted?: boolean;
  typePage?: ECommandCenterPageType;
  isUsersOrganizationIsAssignee?: boolean;
  isUsersOrganizationIsCreated?: boolean;
  viewDueDate?: boolean;
  viewCreatedAt?: boolean;
  viewCompletionDate?: boolean;
  viewCreatedBy?: boolean;
  viewCompletedBy?: boolean;
  viewModule?: boolean;
  viewStatus?: boolean;
  viewAssignee?: boolean;
  isLoad?: string;
  isLoadAdd?: string;
  overdueTasks?: CommandCenterComponentTaskDTM[];
  todayTasks?: CommandCenterComponentTaskDTM[];
  thisWeekTasks?: CommandCenterComponentTaskDTM[];
  allTasks?: CommandCenterComponentTaskDTM[];
  laterTasks?: CommandCenterComponentTaskDTM[];
  countTasksOverdue?: number;
  countTasksToday?: number;
  countTasksThisWeek?: number;
  countTasksLater?: number;
  countTasks?: number;
  infiniteScrollId: string
  titleNameFirstOverdue?: string;
  titleNameFirstColumn?: string;
  setShipmentId?: (shipmentId: string) => void;
  openTaskDrawer?: (task: CommandCenterComponentTaskDTM, e: SyntheticEvent) => void;
}

interface IRefParent extends HTMLDivElement {
  el?: {
    clientHeight?: number;
  }
}
interface IRefScroll {
  el?: {
    clientHeight?: number;
  }
}

const ContentTableTasks: FC<IContentTableTasksProps> = (props) => {
  const {
    onStart,
    nextPageTasks,
    checkAnotherDownloadNextPageTask,
    openAgreementsDrawer,
    isCompleted,
    typePage,
    isUsersOrganizationIsAssignee,
    isUsersOrganizationIsCreated,
    viewDueDate = true,
    viewCreatedAt,
    viewCompletionDate,
    viewCreatedBy,
    viewCompletedBy,
    viewModule = true,
    viewStatus,
    viewAssignee = true,
    isLoad,
    isLoadAdd,
    overdueTasks,
    todayTasks,
    thisWeekTasks,
    allTasks,
    laterTasks,
    countTasksOverdue,
    countTasksToday,
    countTasksThisWeek,
    countTasksLater,
    countTasks,
    infiniteScrollId,
    titleNameFirstOverdue,
    titleNameFirstColumn,
    setShipmentId,
    openTaskDrawer,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    onStart();
  }, []);

  // Download next page on large table
  const refParent = useRef<IRefParent | null>(null);
  const refScroll = useRef<InfiniteScroll | null>(null);

  useEffect(() => {
    const scroll = refScroll?.current ? refScroll.current as unknown as IRefScroll : undefined;
    const currentHeight = refParent?.current?.clientHeight || 0;

    checkAnotherDownloadNextPageTask(currentHeight, scroll?.el?.clientHeight || 0);
  }, [allTasks?.length, refParent.current, refScroll?.current]);

  const viewDueDatePage = typePage && CommandCenterPageTypeValues({
    isUsersOrganizationIsAssignee,
    isUsersOrganizationIsCreated,
  })[typePage]?.viewDueDate;

  return (
    <CCContentTable ref={refParent} isCompleted={isCompleted}>
      <CCContentTableHeader>
        <CCContentTableHeaderItem
          width="calc(37% + 10px)"
        >
          {titleNameFirstColumn || t('Task')}
        </CCContentTableHeaderItem>
        {(viewDueDatePage || viewDueDate) ? (
          <CCContentTableHeaderItem
            width="14.5%"
          >
            {t('dueDate')}
          </CCContentTableHeaderItem>
        ) : null}
        {viewCreatedAt ? (
          <CCContentTableHeaderItem
            width="14.5%"
          >
            {t('Creation Date')}
          </CCContentTableHeaderItem>
        ) : null}
        {/* {viewCompletionDate ? (
          <CCContentTableHeaderItem
            width="14.5%"
          >
            {t('Completion Date')}
          </CCContentTableHeaderItem>
        ) : null} */}
        {isCompleted ? (
          <CCContentTableHeaderItem
            width="14.5%"
          >
            {t('Completion Date')}
          </CCContentTableHeaderItem>
        ) : (
          <CCContentTableHeaderItem
            width="14.5%"
          >
            {t('object')}
          </CCContentTableHeaderItem>
        )}
        {viewCreatedBy ? (
          <CCContentTableHeaderItem
            width="16%"
          >
            {t('Created By')}
          </CCContentTableHeaderItem>
        ) : null}
        {viewCompletedBy ? (
          <CCContentTableHeaderItem
            width="16%"
          >
            {t('Completed By')}
          </CCContentTableHeaderItem>
        ) : null}
        {viewModule ? (
          <CCContentTableHeaderItem
            width="16%"
          >
            {t('module')}
          </CCContentTableHeaderItem>
        ) : null}
        {viewStatus ? (
          <CCContentTableHeaderItem
            width="16%"
          >
            {t('Status')}
          </CCContentTableHeaderItem>
        ) : null}
        {viewAssignee ? (
          <CCContentTableHeaderItem
            width="7.5%"
            isCenter
          >
            {t('assignee')}
          </CCContentTableHeaderItem>
        ) : null}
        <CCContentTableHeaderItem
          width="7%"
          isCenter
        >
          {t('action')}
        </CCContentTableHeaderItem>
      </CCContentTableHeader>

      <CCContentTableContent>

        <ContentTableTasksSectionComponent
          nameSection={titleNameFirstOverdue || t('Overdue')}
          isLoad={isLoad === REQUEST_STATUS.pending}
          dataTasks={overdueTasks}
          countTasks={countTasksOverdue}
          viewDueDate={viewDueDate}
          viewCreatedAt={viewCreatedAt}
          viewCompletionDate={viewCompletionDate}
          viewCreatedBy={viewCreatedBy}
          viewCompletedBy={viewCompletedBy}
          viewModule={viewModule}
          viewStatus={viewStatus}
          viewAssignee={viewAssignee}
          openAgreementsDrawer={openAgreementsDrawer}
          setShipmentId={setShipmentId}
          openTaskDrawer={openTaskDrawer}
        />

        <ContentTableTasksSectionComponent
          nameSection={t('Today')}
          isLoad={isLoad === REQUEST_STATUS.pending}
          dataTasks={todayTasks}
          countTasks={countTasksToday}
          viewDueDate={viewDueDate}
          viewCreatedAt={viewCreatedAt}
          viewCompletionDate={viewCompletionDate}
          viewCreatedBy={viewCreatedBy}
          viewCompletedBy={viewCompletedBy}
          viewModule={viewModule}
          viewStatus={viewStatus}
          viewAssignee={viewAssignee}
          openAgreementsDrawer={openAgreementsDrawer}
          setShipmentId={setShipmentId}
          openTaskDrawer={openTaskDrawer}
        />

        <ContentTableTasksSectionComponent
          nameSection={t('Next 7 Days')}
          isLoad={isLoad === REQUEST_STATUS.pending}
          dataTasks={thisWeekTasks}
          countTasks={countTasksThisWeek}
          viewDueDate={viewDueDate}
          viewCreatedAt={viewCreatedAt}
          viewCompletionDate={viewCompletionDate}
          viewCreatedBy={viewCreatedBy}
          viewCompletedBy={viewCompletedBy}
          viewModule={viewModule}
          viewStatus={viewStatus}
          viewAssignee={viewAssignee}
          openAgreementsDrawer={openAgreementsDrawer}
          setShipmentId={setShipmentId}
          openTaskDrawer={openTaskDrawer}
        />

        <ContentTableTasksSectionComponent
          nameSection={t('Later')}
          isLoad={isLoad === REQUEST_STATUS.pending}
          dataTasks={laterTasks}
          countTasks={countTasksLater}
          viewDueDate={viewDueDate}
          viewCreatedAt={viewCreatedAt}
          viewCompletionDate={viewCompletionDate}
          viewCreatedBy={viewCreatedBy}
          viewCompletedBy={viewCompletedBy}
          viewModule={viewModule}
          viewStatus={viewStatus}
          viewAssignee={viewAssignee}
          openAgreementsDrawer={openAgreementsDrawer}
          setShipmentId={setShipmentId}
          openTaskDrawer={openTaskDrawer}
        />

        {nextPageTasks && (
          <InfiniteScroll
            ref={refScroll}
            dataLength={Number(allTasks?.length)}
            next={nextPageTasks}
            hasMore
            loader={
              isLoad === REQUEST_STATUS.pending
              && (<TableSkeleton />)
            }
            scrollableTarget={infiniteScrollId}
          >

            <ContentTableTasksSectionComponent
              isCompleted
              hideExtend
              isLoad={isLoad === REQUEST_STATUS.pending}
              dataTasks={allTasks}
              countTasks={countTasks}
              viewDueDate={viewDueDate}
              viewCreatedAt={viewCreatedAt}
              viewCompletionDate={viewCompletionDate}
              viewCreatedBy={viewCreatedBy}
              viewCompletedBy={viewCompletedBy}
              viewModule={viewModule}
              viewStatus={viewStatus}
              viewAssignee={viewAssignee}
              openAgreementsDrawer={openAgreementsDrawer}
              openTaskDrawer={openTaskDrawer}
            />
          </InfiniteScroll>
        )}

        {isLoadAdd === REQUEST_STATUS.pending && (<TableSkeleton />)}
      </CCContentTableContent>

    </CCContentTable>
  );
};

const ContentTableTasksCache = memo(ContentTableTasks);

export { ContentTableTasksCache as ContentTableTasks };
