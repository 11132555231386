import { EOrganizationMemberRole } from 'monetary/constants';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import {
  PermissionsDTM,
} from './Permissions.dtm';

export class PermissionsService {
  userRole?: EOrganizationMemberRole

  constructor(userRole?: EOrganizationMemberRole) {
    this.userRole = userRole;
  }

  getFunctionalityPermissions() {
    if (this.userRole === EOrganizationMemberRole.CUSTOMER) {
      return PermissionsDTM.fromPlain({
        rejectButton: PermissionAttributePolicy.WRITE,
        completeRequestButton: undefined,
      });
    }

    if (this.userRole === EOrganizationMemberRole.admin) {
      return PermissionsDTM.fromPlain({
        rejectButton: PermissionAttributePolicy.WRITE,
        completeRequestButton: PermissionAttributePolicy.WRITE,
      });
    }

    if (this.userRole === EOrganizationMemberRole.PARTNER) {
      return PermissionsDTM.fromPlain({
        rejectButton: PermissionAttributePolicy.WRITE,
        completeRequestButton: PermissionAttributePolicy.WRITE,
      });
    }

    return PermissionsDTM.fromPlain({
      rejectButton: PermissionAttributePolicy.WRITE,
      completeRequestButton: PermissionAttributePolicy.WRITE,
    });
  }
}
