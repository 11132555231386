import { EMembershipType } from 'user-management/constants';

export const MAX_LENGTH_6 = 6;
export const MAX_LENGTH_9 = 9;
export const MAX_LENGTH_10 = 10;
export const MAX_LENGTH_20 = 20;
export const MAX_LENGTH_25 = 25;
export const MAX_LENGTH_35 = 35;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_70 = 70;
export const MAX_LENGTH_75 = 75;

export const SHIPPING_PARTY_MAX_LENGTHS = {
  COMPANY_NAME: MAX_LENGTH_75,
  TAX_ID: MAX_LENGTH_35,
  ADDRESS_1: MAX_LENGTH_50,
  ADDRESS_2: MAX_LENGTH_50,
  CITY: MAX_LENGTH_35,
  POSTCODE: MAX_LENGTH_10,
  FULL_NAME: MAX_LENGTH_35,
  EMAIL: 254,
};

export const MEMBERSHIP_REFERENCES_MAX_LENGTHS: Record<EMembershipType, number> = {
  [EMembershipType.DNB_ID]: MAX_LENGTH_9,
  [EMembershipType.WCA_ID]: MAX_LENGTH_6,
  [EMembershipType.XLP]: 16,
  [EMembershipType.GAA]: 16,
  [EMembershipType.JC_TRANS]: 16,
  [EMembershipType.GLA]: 16,
};

export const MEMBERSHIP_REFERENCES_REQUIRED_LENGTHS: Record<EMembershipType, number> = {
  [EMembershipType.DNB_ID]: MAX_LENGTH_9,
  [EMembershipType.WCA_ID]: MAX_LENGTH_6,
  [EMembershipType.XLP]: 1,
  [EMembershipType.GAA]: 1,
  [EMembershipType.JC_TRANS]: 1,
  [EMembershipType.GLA]: 1,
};
