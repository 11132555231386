import { R } from 'shipment-operations/repository';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { PermissionAttributePolicy, TradeType } from 'shipment-operations/constants';
import { ModuleFunctionalityPermissionsDtm, PaymentTermsDTM } from 'shipment-operations/models/dtm';
import { EShipmentOrganizationRole } from 'user-management/constants';

export class ModuleFunctionalityPermissionsService {
  public getCurrentShipmentRoles = async (shipmentId: string, removeSignal?: boolean) => {
    const response = await apiWorker.requestGet<EShipmentOrganizationRole[]>(`/shipment-service/api/v1/shipments/${shipmentId}/roles`, {}, removeSignal);

    return response.data;
  }

  public getFunctionalityPermissions = async (
    shipmentId: string,
    paymentTerms: PaymentTermsDTM | null,
    isAdmin?: boolean,
    removeSignal?: boolean,
  ): Promise<ModuleFunctionalityPermissionsDtm | null> => {
    const shipment = await R.services.shipment.getShipmentShortById(+shipmentId);

    if (isAdmin) {
      return ModuleFunctionalityPermissionsDtm.fromPlain({
        exportClearance: {
          exportClearance: PermissionAttributePolicy.WRITE,
        },
        shippingInstructions: {
          shippingInstructionsStepVisible: PermissionAttributePolicy.READ,
          MBLVisible: PermissionAttributePolicy.READ,
          chargesMBLVisible: PermissionAttributePolicy.READ,
          paymentsMBLVisible: PermissionAttributePolicy.WRITE,
          editSISections: PermissionAttributePolicy.WRITE,
          editSIHBLSections: PermissionAttributePolicy.WRITE,
          ableToViewMBLContact: PermissionAttributePolicy.READ,
          isAbleToSubmitSI: paymentTerms?.tradeType === TradeType.IMPORT || shipment?.getIsOriginDoor() ? undefined : PermissionAttributePolicy.WRITE,
          customerEditShippingParty: PermissionAttributePolicy.WRITE,
        },
        documents: {
          imoDeclarations: PermissionAttributePolicy.WRITE,
          additionalDocuments: PermissionAttributePolicy.WRITE,
          billOfLadingDocuments: PermissionAttributePolicy.WRITE,
          seaworthyCertificate: PermissionAttributePolicy.WRITE,
          viewInvoiceDocumentNameWithPrefix: PermissionAttributePolicy.READ,
        },
        containers: {
          containersAvailability: PermissionAttributePolicy.WRITE,
          customerEditAvailability: PermissionAttributePolicy.WRITE,
        },
        shipmentCharges: {
          shipmentChargesAvailability: PermissionAttributePolicy.WRITE,
          shipmentChargesProfit: PermissionAttributePolicy.WRITE,
        },
        shipmentsBillingInvoicePayables: {
          makePaymentButton: PermissionAttributePolicy.WRITE,
        },
        cargos: {
          cargosAvailability: PermissionAttributePolicy.WRITE,
          customerCargosAvailability: PermissionAttributePolicy.WRITE,
        },
        overview: {
          shipmentRollAvailability: PermissionAttributePolicy.WRITE,
          bookingAmendAvailability: PermissionAttributePolicy.WRITE,
          cancelShipmentAvailability: PermissionAttributePolicy.WRITE,
          mismatchesAvailability: PermissionAttributePolicy.WRITE,
          manualConfirmationAvailability: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          resubmitAvailability: PermissionAttributePolicy.WRITE,
          shipmentChanges: PermissionAttributePolicy.WRITE,
          drayageDrawerInformationSubmission: undefined,
          manualReferenceNumberDrawerAvailability: undefined,
        },
        transportation: {
          overviewTab: PermissionAttributePolicy.WRITE,
          trackerTab: PermissionAttributePolicy.WRITE,
          chargesTab: PermissionAttributePolicy.WRITE,
          accessorialsTab: PermissionAttributePolicy.WRITE,
          freeTimeTab: PermissionAttributePolicy.WRITE,
          locationTab: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          carrierCutoffsAvailability: PermissionAttributePolicy.WRITE,
          carrierCommentsAvailability: PermissionAttributePolicy.READ,
          carrierTOCAvailability: PermissionAttributePolicy.READ,
          trackerAvailability: PermissionAttributePolicy.WRITE,
          locationsAvailability: PermissionAttributePolicy.WRITE,
          trackerWarningsAvailability: PermissionAttributePolicy.READ,
          transportationAvailability: PermissionAttributePolicy.READ,
        },
        dashboards: {
          visibility: PermissionAttributePolicy.WRITE,
        },
        workgroup: {
          companyNameVisibility: PermissionAttributePolicy.READ,
        },
        transportationDrayage: {
          editExportLoadingControls: undefined,
          editImportLoadingControls: undefined,
          editExportSchedule: undefined,
          editImportSchedule: undefined,
          editExportContainer: undefined,
          editImportContainer: undefined,
          addChargeExportCharges: undefined,
          addChargeImportCharges: undefined,
          chargesAndTrackerVisibility: PermissionAttributePolicy.READ,
          exportRequestChangesVisibility: undefined,
          importRequestChangesVisibility: undefined,
          linkContainer: PermissionAttributePolicy.WRITE,
        },
      });
    }

    // Such amount of logic inside service is an EXCEPTION only for permissions logic
    const roles = await this.getCurrentShipmentRoles(shipmentId, removeSignal) || [] as EShipmentOrganizationRole[];

    let customer: ModuleFunctionalityPermissionsDtm | null = null;
    let accountHolder: ModuleFunctionalityPermissionsDtm | null = null;
    let originPartner: ModuleFunctionalityPermissionsDtm | null = null;
    let destinationPartner: ModuleFunctionalityPermissionsDtm | null = null;

    if (!roles.length) {
      return null;
    }

    if (roles.includes(EShipmentOrganizationRole.CUSTOMER)) {
      customer = ModuleFunctionalityPermissionsDtm.fromPlain({
        exportClearance: {
          exportClearance: PermissionAttributePolicy.WRITE,
        },
        shippingInstructions: {
          shippingInstructionsStepVisible: PermissionAttributePolicy.READ,
          MBLVisible: undefined,
          chargesMBLVisible: PermissionAttributePolicy.READ,
          paymentsMBLVisible: undefined,
          editSISections: PermissionAttributePolicy.WRITE,
          editSIHBLSections: PermissionAttributePolicy.READ,
          ableToViewMBLContact: undefined,
          isAbleToSubmitSI: paymentTerms?.tradeType === TradeType.IMPORT || shipment?.getIsOriginDoor() ? undefined : PermissionAttributePolicy.WRITE,
          customerEditShippingParty: shipment.getIsOriginDoor() || paymentTerms?.tradeType === TradeType.IMPORT ? undefined : PermissionAttributePolicy.WRITE,
        },
        documents: {
          imoDeclarations: PermissionAttributePolicy.WRITE,
          additionalDocuments: PermissionAttributePolicy.WRITE,
          billOfLadingDocuments: PermissionAttributePolicy.WRITE,
          seaworthyCertificate: PermissionAttributePolicy.WRITE,
          viewInvoiceDocumentNameWithPrefix: undefined,
        },
        containers: {
          containersAvailability: PermissionAttributePolicy.WRITE,
          customerEditAvailability: shipment.getIsOriginDoor() || paymentTerms?.tradeType === TradeType.IMPORT ? undefined : PermissionAttributePolicy.WRITE,
        },
        shipmentCharges: {
          shipmentChargesAvailability: PermissionAttributePolicy.READ,
          shipmentChargesProfit: PermissionAttributePolicy.READ,
          activeChargeOrigin: PermissionAttributePolicy.READ,
          activeChargeDestination: PermissionAttributePolicy.READ,
        },
        shipmentsBillingInvoicePayables: {
          makePaymentButton: undefined,
        },
        cargos: {
          cargosAvailability: PermissionAttributePolicy.WRITE,
          customerCargosAvailability: shipment.getIsOriginDoor() || paymentTerms?.tradeType === TradeType.IMPORT ? undefined : PermissionAttributePolicy.WRITE,
        },
        overview: {
          shipmentRollAvailability: PermissionAttributePolicy.WRITE,
          bookingAmendAvailability: PermissionAttributePolicy.WRITE,
          cancelShipmentAvailability: PermissionAttributePolicy.WRITE,
          mismatchesAvailability: PermissionAttributePolicy.WRITE,
          shipmentChanges: PermissionAttributePolicy.READ,
          resubmitAvailability: undefined,
          drayageDrawerInformationSubmission: undefined,
          manualReferenceNumberDrawerAvailability: undefined,
        },
        transportation: {
          overviewTab: undefined,
          trackerTab: PermissionAttributePolicy.WRITE,
          chargesTab: undefined,
          accessorialsTab: undefined,
          freeTimeTab: undefined,
          locationTab: PermissionAttributePolicy.WRITE,
          trackerAvailability: PermissionAttributePolicy.READ,
          locationsAvailability: undefined,
          trackerWarningsAvailability: undefined,
          transportationAvailability: undefined,
        },
        dashboards: {
          visibility: PermissionAttributePolicy.READ,
        },
        workgroup: {
          companyNameVisibility: undefined,
        },
        transportationDrayage: {
          editExportLoadingControls: undefined,
          editImportLoadingControls: undefined,
          editExportSchedule: undefined,
          editImportSchedule: undefined,
          editExportContainer: undefined,
          editImportContainer: undefined,
          addChargeExportCharges: undefined,
          addChargeImportCharges: undefined,
          chargesAndTrackerVisibility: undefined,
          exportRequestChangesVisibility: PermissionAttributePolicy.WRITE,
          importRequestChangesVisibility: PermissionAttributePolicy.WRITE,
          linkContainer: PermissionAttributePolicy.READ,
        },
      });
    }

    if (roles.includes(EShipmentOrganizationRole.ACCOUNT_HOLDER)) {
      accountHolder = ModuleFunctionalityPermissionsDtm.fromPlain({
        exportClearance: {
          exportClearance: PermissionAttributePolicy.WRITE,
        },
        shippingInstructions: {
          shippingInstructionsStepVisible: PermissionAttributePolicy.READ,
          MBLVisible: PermissionAttributePolicy.READ,
          chargesMBLVisible: PermissionAttributePolicy.READ,
          paymentsMBLVisible: undefined,
          editSISections: PermissionAttributePolicy.WRITE,
          editSIHBLSections: PermissionAttributePolicy.WRITE,
          ableToViewMBLContact: PermissionAttributePolicy.READ,
          isAbleToSubmitSI: paymentTerms?.tradeType === TradeType.IMPORT || shipment?.getIsOriginDoor() ? undefined : PermissionAttributePolicy.WRITE,
          customerEditShippingParty: PermissionAttributePolicy.WRITE,
        },
        documents: {
          imoDeclarations: PermissionAttributePolicy.WRITE,
          additionalDocuments: PermissionAttributePolicy.WRITE,
          billOfLadingDocuments: PermissionAttributePolicy.WRITE,
          seaworthyCertificate: PermissionAttributePolicy.WRITE,
          viewInvoiceDocumentNameWithPrefix: PermissionAttributePolicy.READ,
        },
        containers: {
          containersAvailability: PermissionAttributePolicy.WRITE,
          customerEditAvailability: PermissionAttributePolicy.WRITE,
        },
        shipmentCharges: {
          shipmentChargesAvailability: PermissionAttributePolicy.WRITE,
          shipmentChargesProfit: PermissionAttributePolicy.WRITE,
          activeChargeOrigin: PermissionAttributePolicy.READ,
          activeChargeDestination: PermissionAttributePolicy.READ,
        },
        shipmentsBillingInvoicePayables: {
          makePaymentButton: PermissionAttributePolicy.WRITE,
        },
        cargos: {
          cargosAvailability: PermissionAttributePolicy.WRITE,
          customerCargosAvailability: PermissionAttributePolicy.WRITE,
        },
        overview: {
          shipmentRollAvailability: PermissionAttributePolicy.WRITE,
          bookingAmendAvailability: PermissionAttributePolicy.WRITE,
          cancelShipmentAvailability: PermissionAttributePolicy.WRITE,
          mismatchesAvailability: PermissionAttributePolicy.WRITE,
          manualConfirmationAvailability: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          resubmitAvailability: PermissionAttributePolicy.WRITE,
          shipmentChanges: PermissionAttributePolicy.WRITE,
          drayageDrawerInformationSubmission: undefined,
          manualReferenceNumberDrawerAvailability: undefined,
        },
        transportation: {
          overviewTab: PermissionAttributePolicy.WRITE,
          trackerTab: PermissionAttributePolicy.WRITE,
          chargesTab: PermissionAttributePolicy.WRITE,
          accessorialsTab: PermissionAttributePolicy.WRITE,
          freeTimeTab: PermissionAttributePolicy.WRITE,
          locationTab: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          carrierCutoffsAvailability: PermissionAttributePolicy.WRITE,
          carrierCommentsAvailability: PermissionAttributePolicy.READ,
          carrierTOCAvailability: PermissionAttributePolicy.READ,
          trackerAvailability: PermissionAttributePolicy.WRITE,
          locationsAvailability: PermissionAttributePolicy.WRITE,
          transportationAvailability: PermissionAttributePolicy.READ,
        },
        dashboards: {
          visibility: PermissionAttributePolicy.WRITE,
        },
        workgroup: {
          companyNameVisibility: PermissionAttributePolicy.READ,
        },
        transportationDrayage: {
          editExportLoadingControls: undefined,
          editImportLoadingControls: undefined,
          editExportSchedule: undefined,
          editImportSchedule: undefined,
          editExportContainer: undefined,
          editImportContainer: undefined,
          addChargeExportCharges: undefined,
          addChargeImportCharges: undefined,
          chargesAndTrackerVisibility: PermissionAttributePolicy.READ,
          exportRequestChangesVisibility: undefined,
          importRequestChangesVisibility: undefined,
          linkContainer: PermissionAttributePolicy.READ,
        },
      });
    }

    if (roles.includes(EShipmentOrganizationRole.ORIGIN_PARTNER)) {
      originPartner = ModuleFunctionalityPermissionsDtm.fromPlain({
        exportClearance: {
          exportClearance: PermissionAttributePolicy.WRITE,
        },
        shippingInstructions: {
          shippingInstructionsStepVisible: PermissionAttributePolicy.READ,
          MBLVisible: PermissionAttributePolicy.READ,
          chargesMBLVisible: PermissionAttributePolicy.READ,
          paymentsMBLVisible: PermissionAttributePolicy.WRITE,
          editSISections: PermissionAttributePolicy.WRITE,
          editSIHBLSections: PermissionAttributePolicy.WRITE,
          ableToViewMBLContact: PermissionAttributePolicy.READ,
          isAbleToSubmitSI: PermissionAttributePolicy.WRITE,
          customerEditShippingParty: PermissionAttributePolicy.WRITE,
        },
        documents: {
          imoDeclarations: PermissionAttributePolicy.WRITE,
          additionalDocuments: PermissionAttributePolicy.WRITE,
          billOfLadingDocuments: PermissionAttributePolicy.WRITE,
          seaworthyCertificate: PermissionAttributePolicy.WRITE,
          viewInvoiceDocumentNameWithPrefix: PermissionAttributePolicy.READ,
        },
        containers: {
          containersAvailability: PermissionAttributePolicy.WRITE,
          customerEditAvailability: PermissionAttributePolicy.WRITE,
        },
        shipmentCharges: {
          shipmentChargesAvailability: PermissionAttributePolicy.WRITE,
          shipmentChargesProfit: PermissionAttributePolicy.WRITE,
          activeChargeOrigin: PermissionAttributePolicy.WRITE,
          activeChargeDestination: PermissionAttributePolicy.READ,
        },
        shipmentsBillingInvoicePayables: {
          makePaymentButton: PermissionAttributePolicy.WRITE,
        },
        cargos: {
          cargosAvailability: PermissionAttributePolicy.WRITE,
          customerCargosAvailability: PermissionAttributePolicy.WRITE,
        },
        overview: {
          shipmentRollAvailability: PermissionAttributePolicy.WRITE,
          bookingAmendAvailability: PermissionAttributePolicy.WRITE,
          cancelShipmentAvailability: PermissionAttributePolicy.WRITE,
          mismatchesAvailability: PermissionAttributePolicy.WRITE,
          manualConfirmationAvailability: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          resubmitAvailability: PermissionAttributePolicy.WRITE,
          shipmentChanges: PermissionAttributePolicy.WRITE,
          drayageDrawerInformationSubmission: PermissionAttributePolicy.WRITE,
          manualReferenceNumberDrawerAvailability: PermissionAttributePolicy.READ,
        },
        transportation: {
          overviewTab: PermissionAttributePolicy.WRITE,
          trackerTab: PermissionAttributePolicy.WRITE,
          chargesTab: PermissionAttributePolicy.WRITE,
          accessorialsTab: PermissionAttributePolicy.WRITE,
          freeTimeTab: PermissionAttributePolicy.WRITE,
          locationTab: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          carrierCutoffsAvailability: PermissionAttributePolicy.WRITE,
          carrierCommentsAvailability: PermissionAttributePolicy.READ,
          carrierTOCAvailability: PermissionAttributePolicy.READ,
          trackerAvailability: PermissionAttributePolicy.WRITE,
          locationsAvailability: PermissionAttributePolicy.WRITE,
          trackerWarningsAvailability: PermissionAttributePolicy.READ,
          transportationAvailability: PermissionAttributePolicy.READ,
        },
        dashboards: {
          visibility: PermissionAttributePolicy.WRITE,
        },
        workgroup: {
          companyNameVisibility: PermissionAttributePolicy.READ,
        },
        transportationDrayage: {
          editExportLoadingControls: PermissionAttributePolicy.WRITE,
          editImportLoadingControls: undefined,
          editExportSchedule: PermissionAttributePolicy.WRITE,
          editImportSchedule: undefined,
          editExportContainer: PermissionAttributePolicy.WRITE,
          editImportContainer: undefined,
          addChargeExportCharges: PermissionAttributePolicy.WRITE,
          addChargeImportCharges: undefined,
          chargesAndTrackerVisibility: PermissionAttributePolicy.READ,
          exportRequestChangesVisibility: undefined,
          importRequestChangesVisibility: undefined,
          linkContainer: PermissionAttributePolicy.WRITE,
        },
      });
    }

    if (roles.includes(EShipmentOrganizationRole.DESTINATION_PARTNER)) {
      destinationPartner = ModuleFunctionalityPermissionsDtm.fromPlain({
        exportClearance: {
          exportClearance: PermissionAttributePolicy.READ,
        },
        shippingInstructions: {
          shippingInstructionsStepVisible: PermissionAttributePolicy.READ,
          MBLVisible: PermissionAttributePolicy.READ,
          chargesMBLVisible: PermissionAttributePolicy.READ,
          paymentsMBLVisible: undefined,
          editSIHBLSections: PermissionAttributePolicy.READ,
          ableToViewMBLContact: PermissionAttributePolicy.READ,
          isAbleToSubmitSI: paymentTerms?.tradeType === TradeType.IMPORT || shipment?.getIsOriginDoor() ? undefined : PermissionAttributePolicy.WRITE,
          customerEditShippingParty: PermissionAttributePolicy.WRITE,
        },
        documents: {
          imoDeclarations: PermissionAttributePolicy.READ,
          additionalDocuments: PermissionAttributePolicy.WRITE,
          billOfLadingDocuments: PermissionAttributePolicy.READ,
          seaworthyCertificate: PermissionAttributePolicy.READ,
          viewInvoiceDocumentNameWithPrefix: PermissionAttributePolicy.READ,
        },
        containers: {
          containersAvailability: PermissionAttributePolicy.READ,
          customerEditAvailability: PermissionAttributePolicy.WRITE,
        },
        shipmentCharges: {
          shipmentChargesAvailability: PermissionAttributePolicy.WRITE,
          shipmentChargesProfit: PermissionAttributePolicy.WRITE,
          activeChargeOrigin: PermissionAttributePolicy.READ,
          activeChargeDestination: PermissionAttributePolicy.WRITE,
        },
        shipmentsBillingInvoicePayables: {
          makePaymentButton: PermissionAttributePolicy.WRITE,
        },
        cargos: {
          cargosAvailability: PermissionAttributePolicy.READ,
          customerCargosAvailability: PermissionAttributePolicy.WRITE,
        },
        overview: {
          mismatchesAvailability: PermissionAttributePolicy.READ,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          shipmentChanges: PermissionAttributePolicy.WRITE,
          resubmitAvailability: undefined,
          drayageDrawerInformationSubmission: undefined,
          manualReferenceNumberDrawerAvailability: undefined,
        },
        transportation: {
          overviewTab: PermissionAttributePolicy.WRITE,
          trackerTab: PermissionAttributePolicy.WRITE,
          chargesTab: PermissionAttributePolicy.WRITE,
          accessorialsTab: PermissionAttributePolicy.WRITE,
          freeTimeTab: PermissionAttributePolicy.WRITE,
          locationTab: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          carrierCutoffsAvailability: PermissionAttributePolicy.READ,
          carrierCommentsAvailability: PermissionAttributePolicy.READ,
          carrierTOCAvailability: PermissionAttributePolicy.READ,
          trackerAvailability: PermissionAttributePolicy.READ,
          locationsAvailability: PermissionAttributePolicy.READ,
          trackerWarningsAvailability: PermissionAttributePolicy.READ,
          transportationAvailability: PermissionAttributePolicy.READ,
        },
        dashboards: {
          visibility: PermissionAttributePolicy.WRITE,
        },
        workgroup: {
          companyNameVisibility: PermissionAttributePolicy.READ,
        },
        transportationDrayage: {
          editExportLoadingControls: undefined,
          editImportLoadingControls: PermissionAttributePolicy.WRITE,
          editExportSchedule: undefined,
          editImportSchedule: PermissionAttributePolicy.WRITE,
          editExportContainer: undefined,
          editImportContainer: PermissionAttributePolicy.WRITE,
          addChargeExportCharges: undefined,
          addChargeImportCharges: PermissionAttributePolicy.WRITE,
          chargesAndTrackerVisibility: PermissionAttributePolicy.READ,
          exportRequestChangesVisibility: undefined,
          importRequestChangesVisibility: undefined,
          linkContainer: PermissionAttributePolicy.READ,
        },
      });
    }

    const mergedExportClearance = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'exportClearance');
    const mergedShippingInstructions = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'shippingInstructions');
    const mergedDocuments = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'documents');
    const mergedContainers = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'containers');
    const mergedShipmentCharges = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'shipmentCharges');
    const mergedShipmentsBillingInvoicePayables = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'shipmentsBillingInvoicePayables');
    const mergedCargos = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'cargos');
    const mergedOverview = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'overview');
    const mergedTransportation = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'transportation');
    const mergedDashboards = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'dashboards');
    const mergedWorkgroup = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'workgroup');
    const mergedTransportationDrayage = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'transportationDrayage');

    return ModuleFunctionalityPermissionsDtm.fromPlain({
      exportClearance: mergedExportClearance,
      shippingInstructions: mergedShippingInstructions,
      documents: mergedDocuments,
      containers: mergedContainers,
      shipmentCharges: mergedShipmentCharges,
      shipmentsBillingInvoicePayables: mergedShipmentsBillingInvoicePayables,
      cargos: mergedCargos,
      overview: mergedOverview,
      transportation: mergedTransportation,
      dashboards: mergedDashboards,
      workgroup: mergedWorkgroup,
      transportationDrayage: mergedTransportationDrayage,
    });
  }

  public getMergedPermissions = <T extends keyof ModuleFunctionalityPermissionsDtm>(
    customer: ModuleFunctionalityPermissionsDtm | null,
    accountHolder: ModuleFunctionalityPermissionsDtm | null,
    originPartner: ModuleFunctionalityPermissionsDtm | null,
    destinationPartner: ModuleFunctionalityPermissionsDtm | null,
    permissionName: T,
  ): ModuleFunctionalityPermissionsDtm[T] => {
    const allRoles = [customer, accountHolder, originPartner, destinationPartner];

    const allRolesAttributes = [
      ...allRoles.map(
        (role) => (role
          ? Object.keys(role[permissionName]) as Array<keyof ModuleFunctionalityPermissionsDtm[T]>
          : [] as Array<keyof ModuleFunctionalityPermissionsDtm[T]>
        ),
      ).flat()];

    const plain = {} as ModuleFunctionalityPermissionsDtm[T];

    allRolesAttributes.forEach((attribute) => {
      const hasWrite = allRoles.some(
        (role) => role
          && role[permissionName][attribute] as unknown as PermissionAttributePolicy === PermissionAttributePolicy.WRITE,
      );
      const hasRead = allRoles.some(
        (role) => role
          && role[permissionName][attribute] as unknown as PermissionAttributePolicy === PermissionAttributePolicy.READ,
      );

      if (hasRead) {
        (plain[attribute] as unknown as PermissionAttributePolicy) = PermissionAttributePolicy.READ;
      }

      if (hasWrite) {
        (plain[attribute] as unknown as PermissionAttributePolicy) = PermissionAttributePolicy.WRITE;
      }
    });

    return plain;
  }
}
