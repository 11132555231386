import { createSelector } from 'reselect';
import getOr from 'lodash/fp/getOr';
import groupBy from 'lodash/fp/groupBy';
import uniqBy from 'lodash/fp/uniqBy';
import isEqual from 'lodash/isEqual';
import findLastIndex from 'lodash/fp/findLastIndex';
import moment from 'moment';
import get from 'lodash/fp/get';

import { RootState } from 'app-wrapper/store';
import {
  ARRIVAL,
  DEPARTURE,
  ARRIVAL_CODES,
  DEPARTURE_CODES,
  N_A,
  ORIGIN_INCOTERM,
  ORIGIN,
  FREIGHT,
  DESTINATION_INCOTERM,
  DESTINATION,
  ChargeCodeDesignation,
  ChargeCodePriceBy,
  priceByContainer,
  priceByBol,
  MscChargeDescription,
} from 'shipment-operations/constants';
import {
  EstimatedChargeDTM,
  IChargeContainerDTM,
  PreparedRoutesDTM, ShipmentEventDTM,
  TrackerScheduleDTM,
} from 'shipment-operations/models/dtm';
import { ContainerWithChangeChargesDTM, ShipmentChargeChangeDTM } from 'app-wrapper/models/dtm';
import {
  getArrivalLocation,
  getDepartureLocation,
  getUSAArrivalLocation,
  getUSADepartureLocation,
} from 'app-wrapper/utils';

const roundToTwoDecimals = (number: number) => Math.round(number * 100) / 100;

const localState = (state: RootState) => state.shipmentTrackerRoutes;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsError = createSelector(
  localState,
  (state) => state.error,
);

const getContainers = createSelector(
  localState,
  (state) => state.containers,
);

const getIsLoadingSchedules = createSelector(
  localState,
  (state) => state.isLoadingSchedules,
);

const getIsErrorSchedules = createSelector(
  localState,
  (state) => state.errorSchedules,
);

const getSchedules = createSelector(
  localState,
  (state) => state.schedules,
);

const getPlan = createSelector(
  localState,
  (state) => state.plan,
);

const parseEvents = (filteredEvents: ShipmentEventDTM[], departureEvent?: ShipmentEventDTM, arrivalEvent?: ShipmentEventDTM) => {
  const routesEvents = filteredEvents.filter(
    (item) => item.location.code !== departureEvent?.location.code && item.location.code !== arrivalEvent?.location.code,
  );

  const eventsWithCodes = routesEvents.filter((item) => item.location.code);
  const uniqSortedEvents = uniqBy((item) => item.location.code, eventsWithCodes);
  const sortedCodes = uniqSortedEvents.map((item) => item.location.code);

  const groupedEvents = groupBy((item) => item.location.code, routesEvents);
  const route = {
    [DEPARTURE]: [departureEvent],
    ...groupedEvents,
    [ARRIVAL]: [arrivalEvent],
  };

  return PreparedRoutesDTM.fromPlain({
    codes: sortedCodes as string[],
    route,
  });
};

const getRoutes = createSelector(
  getContainers,
  (containers) => {
    const events = getOr([], [0, 'container', 'events'], containers);
    const isExport = events.find((item) => item.code === 'DS');
    const isImport = events.find((item) => item.code === 'RA');
    const lastActualEvent = findLastIndex((elem) => !!elem.actual, events);
    const updatedEvents = events.map((item, index) => ({
      ...item,
      passed: index <= lastActualEvent,
    }));

    if (isExport) {
      const codes = [...ARRIVAL_CODES, ...DEPARTURE_CODES, 'I', 'DS'];
      const filteredEvents = updatedEvents.filter((item) => item.code && codes.includes(item.code));
      const departureEvent = updatedEvents.find((item) => item.code === 'DS');
      const arrivalEvent = [...filteredEvents].reverse().find((item) => item.code && ARRIVAL_CODES.includes(item.code));
      return parseEvents(filteredEvents, departureEvent, arrivalEvent);
    }
    if (isImport) {
      const codes = [...ARRIVAL_CODES, ...DEPARTURE_CODES, 'RA', 'OA'];
      const filteredEvents = updatedEvents.filter((item) => item.code && codes.includes(item.code));
      const arrivalEvent = updatedEvents.find((item) => item.code === 'RA');
      const departureEvent = filteredEvents.find((item) => item.code && DEPARTURE_CODES.includes(item.code));
      return parseEvents(filteredEvents, departureEvent, arrivalEvent);
    }
    const codes = [...ARRIVAL_CODES, ...DEPARTURE_CODES];
    const filteredEvents = updatedEvents.filter((item) => item.code && codes.includes(item.code));
    const departureEvent = filteredEvents.find((item) => item.code && DEPARTURE_CODES.includes(item.code));
    const arrivalEvent = [...filteredEvents].reverse().find((item) => item.code && ARRIVAL_CODES.includes(item.code));
    return parseEvents(filteredEvents, departureEvent, arrivalEvent);
  },
);

const getDrayageRoutesExport = createSelector(
  getContainers,
  (containers) => {
    const events = getOr([], [0, 'container', 'events'], containers);
    const allContainersEvents = containers.reduce((acc: ShipmentEventDTM[], item) => [...acc, ...item.container.events], []);
    const isIPassed = allContainersEvents.find((item) => item.code === 'I' && item.actual);
    const isDSPassed = allContainersEvents.find((item) => item.code === 'DS' && item.actual);
    const lastActualEvent = findLastIndex((elem) => !!elem.actual, events);
    const updatedEvents = events.map((item, index) => {
      if (item.code === 'I') {
        return ({
          ...item,
          passed: !!isIPassed,
        });
      }
      if (item.code === 'DS') {
        return ({
          ...item,
          passed: !!isDSPassed,
        });
      }
      return ({
        ...item,
        passed: index <= lastActualEvent,
      });
    });
    const departureEvent = updatedEvents.find((item) => item.code === 'DS');
    const arrivalEvent = updatedEvents.find((item) => item.code === 'I');
    const route = {
      [DEPARTURE]: [departureEvent],
      groupedEvents: [],
      [ARRIVAL]: [arrivalEvent],
    };

    return PreparedRoutesDTM.fromPlain({
      codes: [],
      route,
    });
  },
);

const getDrayageRoutesImport = createSelector(
  getContainers,
  (containers) => {
    const events = getOr([], [0, 'container', 'events'], containers);
    const allContainersEvents = containers.reduce((acc: ShipmentEventDTM[], item) => [...acc, ...item.container.events], []);
    const isRAPassed = allContainersEvents.find((item) => item.code === 'RA' && item.actual);
    const isOAPassed = allContainersEvents.find((item) => item.code === 'OA' && item.actual);
    const lastActualEvent = findLastIndex((elem) => !!elem.actual, events);
    const updatedEvents = events.map((item, index) => {
      if (item.code === 'RA') {
        return ({
          ...item,
          passed: !!isRAPassed,
        });
      }
      if (item.code === 'OA') {
        return ({
          ...item,
          passed: !!isOAPassed,
        });
      }
      return ({
        ...item,
        passed: index <= lastActualEvent,
      });
    });
    const departureEvent = updatedEvents.find((item) => item.code === 'OA');
    const arrivalEvent = updatedEvents.find((item) => item.code === 'RA');
    const route = {
      [DEPARTURE]: [departureEvent],
      groupedEvents: [],
      [ARRIVAL]: [arrivalEvent],
    };

    return PreparedRoutesDTM.fromPlain({
      codes: [],
      route,
    });
  },
);

const getSchedule = createSelector(
  getSchedules,
  (schedules = []) => {
    const arrivalTime = schedules.find((item) => item.arrivalTime)?.arrivalTime;
    const cyAvailable = schedules.find((item) => item.cyAvailable)?.cyAvailable;
    const ctoAvailable = schedules.find((item) => item.ctoAvailable)?.ctoAvailable;
    const terminalCutOff = schedules.find((item) => item.terminalCutOff)?.terminalCutOff;
    const vgmCutOff = schedules.find((item) => item.vgmCutOff)?.vgmCutOff;
    const documentCutOff = schedules.find((item) => item.documentCutOff)?.documentCutOff;

    return {
      arrivalTime,
      cyAvailable,
      ctoAvailable,
      terminalCutOff,
      vgmCutOff,
      documentCutOff,
    } as TrackerScheduleDTM;
  },
);

const getScheduleDrayage = createSelector(
  getContainers,
  (containers = []) => {
    const events = getOr([], [0, 'container', 'events'], containers);
    const cyAvailable = events.find((item) => item.code === 'RA');

    return {
      cyAvailable: cyAvailable?.actual?.getDateAsMomentLocalOffset() || cyAvailable?.estimated?.getDateAsMomentLocalOffset(),
      ctoAvailable: cyAvailable?.actual?.getDateAsMomentLocalOffset() || cyAvailable?.estimated?.getDateAsMomentLocalOffset(),
    } as TrackerScheduleDTM;
  },
);

const getEditableSchedules = createSelector(
  localState,
  (state) => state.editableSchedules,
);

const getOriginalSchedules = createSelector(
  localState,
  (state) => state.originalSchedules,
);

const getEditTransportPlan = createSelector(
  getPlan,
  (plan) => {
    const currentLegs = get(['route', 'legs'], plan[0]);
    const currentTransportation = get(['transportations'], plan[0]);
    return currentLegs.reduce((acc: any, leg) => {
      const matchedTransportation = currentTransportation.find((item) => item.transportLeg === leg?.id);
      const isUSADeparture = leg?.departureLocation.country?.code === 'US';
      const isUSAArrival = leg?.arrivalLocation.country?.code === 'US';
      const departureLocation = isUSADeparture ? getUSADepartureLocation(leg) : getDepartureLocation(leg);
      const arrivalLocation = isUSAArrival ? getUSAArrivalLocation(leg) : getArrivalLocation(leg);

      return [...acc, {
        id: leg?.id,
        type: matchedTransportation?.transport.type,
        location: departureLocation,
        nextLocation: arrivalLocation,
        etdTime: moment.parseZone(matchedTransportation?.schedule.departureTime),
        etaTime: moment.parseZone(matchedTransportation?.schedule.arrivalTime),
        vessel: matchedTransportation?.transport.name,
        voyage: matchedTransportation?.voyageCode,
      }];
    }, []);
  },
);

const getLegs = createSelector(
  localState,
  (state) => state.legs,
);

const getPreparedLegs = createSelector(
  getLegs,
  (legs) => legs.map((leg) => ({
    ...leg,
    vessel: leg.vessel === N_A ? '' : leg.vessel,
  })),
);

const getIsLoadingUpdate = createSelector(
  localState,
  (state) => state.isLoadingUpdate,
);

const getIsUpdateSuccess = createSelector(
  localState,
  (state) => state.isUpdateSuccess,
);

const getIsErrorTerminalCutOff = createSelector(
  localState,
  (state) => state.terminalCutOffError,
);

const getVoyageErrorIndexes = createSelector(
  localState,
  (state) => state.voyageErrorIndexes,
);

const getVesselErrorIndexes = createSelector(
  localState,
  (state) => state.vesselErrorIndexes,
);

const getIsChangedTransportPlan = createSelector(
  localState,
  (state) => state.isChangedTransportPlan,
);

const getIsChangedCutoffs = createSelector(
  getEditableSchedules,
  getOriginalSchedules,
  (editableSchedules, originalSchedules) => !isEqual(editableSchedules, originalSchedules),
);

const getIsChangedPortCutoff = createSelector(
  getEditableSchedules,
  getOriginalSchedules,
  (editableSchedules, originalSchedules) => !isEqual(editableSchedules.terminalCutOff, originalSchedules.terminalCutOff),
);

const getValidationLoading = createSelector(
  localState,
  (state) => state.validationLoading,
);

const getIncoterm = createSelector(
  localState,
  (state) => state.incoterm,
);

const getSavedCharges = createSelector(
  localState,
  (state) => state.savedCharges,
);

const getEstimatedCharges = createSelector(
  localState,
  (state) => state.estimatedCharges,
);

const getEstimatedChargesSum = createSelector(
  getEstimatedCharges,
  getIncoterm,
  (estimatedCharges, incoterm) => {
    let filteredEstimated: EstimatedChargeDTM[] = [];
    filteredEstimated = estimatedCharges.filter((item) => item.applied);
    if (ORIGIN_INCOTERM.includes(incoterm)) {
      filteredEstimated = filteredEstimated.filter((item) => (item.designation === ORIGIN) || (item.designation === FREIGHT));
    }
    if (DESTINATION_INCOTERM.includes(incoterm)) {
      filteredEstimated = filteredEstimated.filter((item) => (item.designation === FREIGHT) || (item.designation === DESTINATION));
    }
    return filteredEstimated.reduce((acc, item) => acc + item.totalCost, 0);
  },
);

const getSavedChargesSum = createSelector(
  getSavedCharges,
  (savedCharges) => {
    const filteredSaved = savedCharges.filter((item) => item.applied && item.active);
    return filteredSaved.reduce((acc, item) => acc + item.buyTotalCost, 0);
  },
);

const getAdditionalChargesSum = createSelector(
  getSavedCharges,
  (savedCharges) => {
    const filteredAdditionalSaved = savedCharges.filter((item) => item.applied && item.additional && item.active);
    return filteredAdditionalSaved.reduce((acc, item) => acc + item.buyTotalCost, 0);
  },
);

const getIsChangedChargesSum = createSelector(
  getSavedChargesSum,
  getEstimatedChargesSum,
  getAdditionalChargesSum,
  (savedSum, estimatedSum, additionalSum) => roundToTwoDecimals(estimatedSum + additionalSum) !== roundToTwoDecimals(savedSum),
);

const getNewTotal = createSelector(
  getEstimatedChargesSum,
  getAdditionalChargesSum,
  (estimatedSum, additionalSum) => estimatedSum + additionalSum,
);

const getValidationLoaded = createSelector(
  localState,
  (state) => state.validationLoaded,
);

// disable update if there are no changes + if there are changes in cutoffs and validation is not loaded
const getIsDisableUpdate = createSelector(
  getIsChangedTransportPlan,
  getIsChangedCutoffs,
  getIsChangedPortCutoff,
  getValidationLoaded,
  (isChangedTransportPlan, isChangedCutoffs, isChangedPortCutoff, loaded) => {
    if (isChangedTransportPlan || isChangedCutoffs) {
      return isChangedPortCutoff && !loaded;
    }
    return true;
  },
);

const getIsShowAddCharge = createSelector(
  localState,
  (state) => state.showAddChargeButton,
);

const getSavedFilteredCharges = createSelector(
  getSavedCharges,
  (savedCharges) => {
    const filtered = savedCharges.filter((item) => item.applied && item.active);
    return filtered.map((item, index) => ShipmentChargeChangeDTM.fromPlain({
      chargeCode: item.chargeCode.code,
      chargeDescription: item.chargeCode.description,
      chargeId: index,
      prevVersion: {
        costPerUnit: item.buyCostPerUnit,
        totalCost: item.buyTotalCost,
        currency: item.currency,
        designation: item.designation as ChargeCodeDesignation,
      },
      priceBy: item.priceBy as ChargeCodePriceBy,
      container: {
        ...item.container,
        type: item?.container?.originalType,
      },
      designation: item.designation as ChargeCodeDesignation,
    }));
  },
);

const getEstimatedFilteredCharges = createSelector(
  getEstimatedCharges,
  (estimatedCharges) => {
    const filtered = estimatedCharges.filter((item) => item.applied);
    return filtered.map((item, index) => ShipmentChargeChangeDTM.fromPlain({
      chargeCode: item.chargeCode.code,
      chargeDescription: item.chargeCode.description,
      chargeId: index,
      newVersion: {
        costPerUnit: item.costPerUnit,
        totalCost: item.totalCost,
        currency: item.currency,
        designation: item.designation as ChargeCodeDesignation,
      },
      priceBy: item.priceBy as ChargeCodePriceBy,
      container: {
        ...item.container,
        type: item?.container?.originalType,
      } as IChargeContainerDTM,
      designation: item.designation as ChargeCodeDesignation,
    }));
  },
);

const getComparedChargesTableBol = createSelector(
  getSavedFilteredCharges,
  getEstimatedFilteredCharges,
  (savedCharges, estimatedCharges) => {
    const allCharges = [...savedCharges, ...estimatedCharges].filter((item) => item.priceBy === priceByBol);
    const merged:ShipmentChargeChangeDTM[] = [];
    allCharges.forEach((item) => {
      const existing = merged.find((el) => (el.chargeCode === item.chargeCode) && item.designation === el.designation && item.chargeDescription === el.chargeDescription);

      if (existing) {
        if (item.prevVersion !== undefined) {
          existing.prevVersion = item.prevVersion;
        }
        if (item.newVersion !== undefined) {
          existing.newVersion = item.newVersion;
        }
      } else if (item.chargeDescription !== MscChargeDescription) {
        merged.push({ ...item } as ShipmentChargeChangeDTM);
      }
    });
    return merged;
  },
);

const getComparedChargesTableContainer = createSelector(
  getSavedFilteredCharges,
  getEstimatedFilteredCharges,
  (savedCharges, estimatedCharges) => {
    const allCharges = [...savedCharges, ...estimatedCharges].filter((item) => item.priceBy === priceByContainer);
    const savedChargesContainers: { [key: string]: string } = {};
    const estimatedChargesContainers: { [key: string]: string } = {};
    savedCharges.forEach((item) => {
      if (item.container?.id && item.container?.type) {
        savedChargesContainers[item.container?.id] = item.container?.type;
      }
    });
    estimatedCharges.forEach((item) => {
      if (item.container?.id && item.container?.type) {
        estimatedChargesContainers[item.container?.id] = item.container?.type;
      }
    });
    const mergedId: { [key: string]: number } = {};

    const keysA = Object.keys(savedChargesContainers);
    const keysB = Object.keys(estimatedChargesContainers);

    keysA.forEach((keyA) => {
      keysB.some((keyB, index) => {
        if (savedChargesContainers[keyA] === estimatedChargesContainers[keyB]) {
          mergedId[keyA] = Number(keyB);
          keysB.splice(index, 1);
          return true;
        }
        return false;
      });
    });

    const chargesWithId = allCharges.map((item) => ({
      ...item,
      container: {
        ...item.container,
        id: (item.container?.id && Object.keys(savedChargesContainers).includes(String(item.container?.id)) ? mergedId[item.container?.id] : item.container?.id) || item.container?.id,
      },
    }));

    const result:ShipmentChargeChangeDTM[] = [];

    chargesWithId.forEach((item) => {
      const existing = result.find((el) => (el.chargeCode === item.chargeCode) && el.container?.id === item.container?.id && item.designation === el.designation && item.chargeDescription === el.chargeDescription);

      if (existing) {
        if (item.prevVersion !== undefined) {
          existing.prevVersion = item.prevVersion;
        }
        if (item.newVersion !== undefined) {
          existing.newVersion = item.newVersion;
        }
      } else if (item.chargeDescription !== MscChargeDescription) {
        result.push({ ...item } as ShipmentChargeChangeDTM);
      }
    });

    const containersWithCharges: ContainerWithChangeChargesDTM[] = [];

    result.forEach((charge) => {
      if (charge.container) {
        const { container } = charge;

        const targetContainer = containersWithCharges.find(({ id }) => container?.id === id);

        if (targetContainer) {
          targetContainer.charges.push(charge);
        } else {
          containersWithCharges.push(ContainerWithChangeChargesDTM.fromPlain({
            key: container?.id,
            id: container?.id,
            type: container?.type,
            number: container?.number,
            charges: [charge],
          }));
        }
      }
    });
    return containersWithCharges;
  },
);

export const shipmentTrackerRoutesSelectors = {
  getContainers,
  getIsError,
  getIsLoading,
  getRoutes,
  getIsLoadingSchedules,
  getIsErrorSchedules,
  getSchedule,
  getEditTransportPlan,
  getPlan,
  getLegs,
  getIsLoadingUpdate,
  getIsUpdateSuccess,
  getEditableSchedules,
  getIsErrorTerminalCutOff,
  getVoyageErrorIndexes,
  getVesselErrorIndexes,
  getPreparedLegs,
  getOriginalSchedules,
  getIsChangedTransportPlan,
  getIsChangedCutoffs,
  getIsDisableUpdate,
  getIsChangedPortCutoff,
  getValidationLoading,
  getIsChangedChargesSum,
  getValidationLoaded,
  getNewTotal,
  getSavedChargesSum,
  getIsShowAddCharge,
  getComparedChargesTableBol,
  getComparedChargesTableContainer,
  getDrayageRoutesExport,
  getDrayageRoutesImport,
  getScheduleDrayage,
};
