import styled from 'styled-components';

import {
  BodyTextRegular,
  BodyTextMedium,
  SmallTextFootnoteDescription,
  ContentSection,
  FlexRow,
} from 'app-wrapper/view/guideline';

export const Wrap = styled(ContentSection)`
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.span`
  ${BodyTextMedium}
`;

export const Options = styled(FlexRow)`
  gap: 8px;
`;

export const Option = styled(ContentSection)`
  min-height: 100px;
  padding: 8px 16px;
  gap: 8px;
  align-items: center;
`;

export const OptionTitle = styled.span`
  ${BodyTextRegular}
`;

export const OptionText = styled.span`
  ${SmallTextFootnoteDescription}
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;
