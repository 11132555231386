import i18n from 'app-wrapper/i18n/i18n';

export enum ENotificationCriticality {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum ENotificationStatus {
  TODO = 'TODO',
  DONE = 'DONE',
  REJECTED = 'REJECTED',
}

export enum ENotificationTaskType {
  TASK = 'TASK',
  ALERT = 'ALERT',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
}

export enum ENotificationDomain {
  DOCUMENTATION = 'DOCUMENTATION',
  BOOKING = 'BOOKING',
  PLAN_TRACK = 'PLAN_TRACK',
  BILLING = 'BILLING',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  QUOTES_AND_RATES = 'QUOTES_AND_RATES',
}

export enum EChangeRequestType {
  UPDATE_CARGO_INFORMATION = 'UPDATE_CARGO_INFORMATION',
  UPDATE_CONTAINERS_INFORMATION = 'UPDATE_CONTAINERS_INFORMATION',
  CHANGE_SHIPMENT_SCHEDULE = 'CHANGE_SHIPMENT_SCHEDULE',
  UPDATE_SUPPLIER_INFORMATION = 'UPDATE_SUPPLIER_INFORMATION',
  UPDATE_RECEIVER_INFORMATION = 'UPDATE_RECEIVER_INFORMATION',
  UPDATE_DRAYAGE_INFORMATION_EXPORT = 'UPDATE_DRAYAGE_INFORMATION_EXPORT',
  UPDATE_DRAYAGE_INFORMATION_IMPORT = 'UPDATE_DRAYAGE_INFORMATION_IMPORT',
}

export const ENotificationDomainByChangeRequestType = {
  [EChangeRequestType.UPDATE_CARGO_INFORMATION]: ENotificationDomain.BOOKING,
  [EChangeRequestType.UPDATE_CONTAINERS_INFORMATION]: ENotificationDomain.BOOKING,
  [EChangeRequestType.UPDATE_SUPPLIER_INFORMATION]: ENotificationDomain.DOCUMENTATION,
  [EChangeRequestType.UPDATE_RECEIVER_INFORMATION]: ENotificationDomain.DOCUMENTATION,
  [EChangeRequestType.CHANGE_SHIPMENT_SCHEDULE]: ENotificationDomain.PLAN_TRACK,
  [EChangeRequestType.UPDATE_DRAYAGE_INFORMATION_EXPORT]: ENotificationDomain.PLAN_TRACK,
  [EChangeRequestType.UPDATE_DRAYAGE_INFORMATION_IMPORT]: ENotificationDomain.PLAN_TRACK,
};

export const EChangeRequestTypeText = {
  [EChangeRequestType.UPDATE_CARGO_INFORMATION]: i18n.t('Update cargo information'),
  [EChangeRequestType.UPDATE_CONTAINERS_INFORMATION]: i18n.t('Update containers information'),
  [EChangeRequestType.CHANGE_SHIPMENT_SCHEDULE]: i18n.t('Change shipment schedule'),
  [EChangeRequestType.UPDATE_SUPPLIER_INFORMATION]: i18n.t('Update supplier information'),
  [EChangeRequestType.UPDATE_RECEIVER_INFORMATION]: i18n.t('Update receiver information'),
  [EChangeRequestType.UPDATE_DRAYAGE_INFORMATION_EXPORT]: i18n.t('Update drayage information Export'),
  [EChangeRequestType.UPDATE_DRAYAGE_INFORMATION_IMPORT]: i18n.t('Update drayage information Import'),
};

export const EChangeRequestTypeStepToComplete = {
  [EChangeRequestType.UPDATE_CARGO_INFORMATION]: [
    {
      title: i18n.t('Validate Changes'),
      text: i18n.t('Verify the requested changes, and, if necessary, reach out to all relevant shipping parties.'),
    },
    {
      title: i18n.t('Update Cargo Information'),
      text: i18n.t('Go to the Details > Cargo page and apply the requested updates.'),
    },
    {
      title: i18n.t('Complete Change Request'),
      text: i18n.t('Once the information has been updated, finalize the change request by clicking the “Complete Request” button.'),
    },
  ],
  [EChangeRequestType.UPDATE_CONTAINERS_INFORMATION]: [
    {
      title: i18n.t('Validate Changes'),
      text: i18n.t('Verify the requested changes, and, if necessary, reach out to all relevant shipping parties.'),
    },
    {
      title: i18n.t('Update Container Information'),
      text: i18n.t('Go to the Details > Containers page and apply the requested updates.'),
    },
    {
      title: i18n.t('Complete Change Request'),
      text: i18n.t('Once the information has been updated, finalize the change request by clicking the “Complete Request” button.'),
    },
  ],
  [EChangeRequestType.CHANGE_SHIPMENT_SCHEDULE]: [],
  [EChangeRequestType.UPDATE_SUPPLIER_INFORMATION]: [
    {
      title: i18n.t('Validate Changes'),
      text: i18n.t('Verify the requested changes, and, if necessary, reach out to all relevant shipping parties.'),
    },
    {
      title: i18n.t('Update Cargo Supplier Information'),
      text: i18n.t('Go to the Details > Shipping Parties > Cargo Supplier page and apply the requested updates.'),
    },
    {
      title: i18n.t('Complete Change Request'),
      text: i18n.t('Once the information has been updated, finalize the change request by clicking the “Complete Request” button.'),
    },
  ],
  [EChangeRequestType.UPDATE_RECEIVER_INFORMATION]: [
    {
      title: i18n.t('Validate Changes'),
      text: i18n.t('Verify the requested changes, and, if necessary, reach out to all relevant shipping parties.'),
    },
    {
      title: i18n.t('Update Cargo Receiver Information'),
      text: i18n.t('Go to the Details > Shipping Parties > Cargo Receiver page and apply the requested updates.'),
    },
    {
      title: i18n.t('Complete Change Request'),
      text: i18n.t('Once the information has been updated, finalize the change request by clicking the “Complete Request” button.'),
    },
  ],
  [EChangeRequestType.UPDATE_DRAYAGE_INFORMATION_EXPORT]: [
    {
      title: i18n.t('Validate Changes'),
      text: i18n.t('Verify the requested changes, and, if necessary, reach out to all relevant shipping parties.'),
    },
    {
      title: i18n.t('Update Drayage Planning Information'),
      text: i18n.t('Go to the Transportation > Export Inland Pickup page and apply the requested updates.'),
    },
    {
      title: i18n.t('Complete Change Request'),
      text: i18n.t('Once the information has been updated, finalize the change request by clicking the “Complete Request” button.'),
    },
  ],
  [EChangeRequestType.UPDATE_DRAYAGE_INFORMATION_IMPORT]: [
    {
      title: i18n.t('Validate Changes'),
      text: i18n.t('Verify the requested changes, and, if necessary, reach out to all relevant shipping parties.'),
    },
    {
      title: i18n.t('pdate Drayage Planning Information'),
      text: i18n.t('Go to the Transportation > Import Inland Delivery page and apply the requested updates.'),
    },
    {
      title: i18n.t('Complete Change Request'),
      text: i18n.t('Once the information has been updated, finalize the change request by clicking the “Complete Request” button.'),
    },
  ],
};
