import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CargoReferencesFormComponent } from './CargoReferencesForm.component';

const CargoReferencesFormContainer: FC = () => {
  const {
    references,
    errors,
    touchedFields,
    wasUpdateAttempted,
  } = useSelector(R.selectors.cargo.getCargo);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  return (
    <CargoReferencesFormComponent
      references={references}
      wasUpdateAttempted={wasUpdateAttempted}
      touchedFields={touchedFields}
      errors={errors.references}
      setReferenceType={UC.cargo.setReferenceType}
      setReferenceValue={UC.cargo.setReferenceNumber}
      touchReferenceType={UC.cargo.touchReferenceType}
      touchReferenceValue={UC.cargo.touchReferenceValue}
      addReference={UC.cargo.addReference}
      removeReference={UC.cargo.removeReference}
      cargosAvailability={permissions.cargosAvailability}
      customerCargosAvailability={permissions.customerCargosAvailability}
    />
  );
};

export {
  CargoReferencesFormContainer as CargoReferencesForm,
};
