import styled from 'styled-components';

import { Divider as BaseDivider } from 'app-wrapper/view/components';
import {
  FlexCol,
  FlexRow,
  FlexCenter,
  BodyTextRegular,
} from 'app-wrapper/view/guideline';

export const Content = styled(FlexRow)`
  gap: 16px;
`;

export const Steps = styled(FlexCol)`
  height: 100%;
  width: 24px;
  gap: 8px;
  align-items: center;
`;

export const StepNumber = styled(FlexCenter)`
  ${BodyTextRegular}
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
  
  &:first-of-type {
    margin-top: 9px;
  }

  &:last-of-type {
    margin-bottom: 9px;
  }
`;

export const Divider = styled(BaseDivider)`
  color: ${({ theme }) => theme.themesColors.neutralBranding4};
  height: 100%;
`;

export const StepDescription = styled(FlexCol)`
  width: 100%;
  padding: 12px 0;
  height: 66px;
  align-items: flex-start;
  justify-content: center;

  &:not(:first-of-type) {
    border-top: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
  }
  
  &:first-of-type {
    padding-top: 0;
    height: 54px;
  }

  &:last-of-type {
    padding-bottom: 0;
    height: 54px;
  }
`;
