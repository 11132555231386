import styled from 'styled-components';

import {
  SubPageContent,
  BodyTextMedium,
  FlexRow,
} from 'app-wrapper/view/guideline';

export const Header = styled(SubPageContent)`
  flex-direction: row;
`;

export const Title = styled.span`
  ${BodyTextMedium}
`;

export const HeaderContent = styled(FlexRow)`
  margin-left: auto;
  gap: 20px;
`;
