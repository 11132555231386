import { ForbiddenError, NotFoundError } from 'app-wrapper/models/errors';
import moment from 'moment';

import { BaseController, controller } from 'proto/BaseController';

import {
  ENotificationStatus,
  ENotificationTaskType,
  RouteNames,
} from 'app-wrapper/constants';
import { R as appR } from 'app-wrapper/repository';
import {
  CommandCenterGetCommandCenterGetNotificationsDTM, CommandCenterGetTasksDTM,
} from 'app-wrapper/models/dtm';

import { R as AuthR } from 'authentication/repository';
import { R } from 'shipment-operations/repository';
import {
  ContainerDTM,
  ModuleFunctionalityPermissionsDtm,
  PaymentTermsDTM,
  ShipmentAllStatsDTM,
  ShipmentAllStatsNotificationStatsDTM,
  ShipmentAllStatsTaskStatsDTM,
  ShipmentPreviewDTM,
} from 'shipment-operations/models/dtm';
import { ContainerBasicTypes } from 'shipment-operations/constants';
import { FreightFromStateDTM } from 'monetary/models/dtm';
import { freightTypeLocation } from 'monetary/constants';
import { EOrganizationMemberRole } from 'user-management/constants';

const IntervalUpdateShipmentStats = 180000;
const IntervalFirstUpdateShipmentStats = 4000;

const defaultParams = CommandCenterGetTasksDTM.fromPlain({
  page: `${0}`,
  size: '100',
  sort: 'dueDate,asc',
  query: 'TODO',
});
let isFirst = false;

const getTasksStats = async (paramsTasks?: CommandCenterGetTasksDTM) => new Promise<ShipmentAllStatsDTM | undefined>((resolve, reject) => {
  try {
    const res = appR.services.CommandCenter.getOrganizationStats({
      ...defaultParams,
      ...paramsTasks,
    });

    resolve(res);
  } catch (e) {
    reject(e);
  }
});

@controller
export class ShipmentController extends BaseController {
  loadData = async (shipmentId?: string) => {
    let shipment: ShipmentPreviewDTM | null;
    let shipmentContainers: ContainerDTM[];

    if (!shipmentId) {
      this.navigate(RouteNames.SHIPMENTS());

      return;
    }

    this.dispatch(R.actions.shipment.setLoading(true));
    const shipmentStatsInterval = setInterval(() => {
      this.loadShipmentStats(shipmentId);
    }, IntervalUpdateShipmentStats);

    this.loadShipmentStats(shipmentId);
    this.dispatch(R.actions.shipment.setShipmentStatsInterval(shipmentStatsInterval));

    // TODO: will be implemented soon
    try {
      shipment = await R.services.shipment.getShipmentShortById(+shipmentId, true);
    } catch (e) {
      if (e instanceof NotFoundError || e instanceof ForbiddenError) {
        this.navigate(RouteNames.SHIPMENTS());
      }

      console.error(e);

      return;
    }

    if (!shipment) {
      return;
    }

    let paymentTerms: PaymentTermsDTM | null = null;
    const form = FreightFromStateDTM.fromPlain({
      origin: {
        isPort: shipment.origin?.type === freightTypeLocation.PORT,
        location: {
          code: shipment.origin.code,
        },
        datePort: {
          earliestDate: moment(shipment.origin?.estimatedDate?.getDateYYYYMMDD() || shipment.origin.realDate?.getDateYYYYMMDD()).format(),
          latestDate: moment(shipment.origin?.estimatedDate?.getDateYYYYMMDD() || shipment.origin.realDate?.getDateYYYYMMDD()).format(),
        },
      },
      destination: {
        isPort: shipment.destination?.type === freightTypeLocation.PORT,
        location: {
          code: shipment.destination.code,
        },
      },
    });

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerShipmentRFQFormState(form);

    try {
      paymentTerms = await R.services.paymentTerms.getShipmentPaymentTerms(shipmentId, true);
    } catch (e) {
      if (e instanceof NotFoundError || e instanceof ForbiddenError) {
        this.navigate(RouteNames.SHIPMENTS());
      }

      console.error(e);

      return;
    }

    let shipmentPermissions: ModuleFunctionalityPermissionsDtm | null = null;

    const userData = AuthR.selectors.auth.getUser(this.store.getState());

    try {
      shipmentPermissions = await this.repositories.shipmentPermissionsRepository.get(String(shipment.id), paymentTerms, userData.isAdmin, true) || null;
    } catch (e) {
      if (e instanceof NotFoundError || e instanceof ForbiddenError) {
        this.navigate(RouteNames.SHIPMENTS());
      }

      console.error(e);

      return;
    }

    if (!shipmentPermissions) {
      this.navigate(RouteNames.SHIPMENTS());

      return;
    }

    this.dispatch(R.actions.shipment.setPaymentTermsDTM(paymentTerms || undefined));
    this.dispatch(R.actions.shipment.setPermissions(shipmentPermissions));

    if (shipment.origin.estimatedDate) {
      const tz = shipment.origin.estimatedDate.getDateAsMomentWithOffset().format().slice(19, 25);

      this.dispatch(R.actions.shipment.setDestinationTimeZone(tz));
    }

    try {
      shipmentContainers = await R.services.shipmentContainers.getContainersList(shipmentId, true);
    } catch (e) {
      console.error(e);

      return;
    }

    if (!shipmentContainers.length) {
      return;
    }

    this.dispatch(R.actions.shipment.setShipment(shipment));
    this.dispatch(R.actions.shipment.setContainers(shipmentContainers));

    const shipmentContainersBasicType = /[rR]/.test(shipmentContainers[0].type)
      ? ContainerBasicTypes.REEFER : ContainerBasicTypes.USUAL;

    this.dispatch(R.actions.shipment.setContainersBasicType(shipmentContainersBasicType));

    const isContainersOwn = shipmentContainers[0].ownContainer;
    this.dispatch(R.actions.shipment.setContainersOwn(isContainersOwn));

    this.dispatch(R.actions.shipment.setLoading(false));
  }

  loadShipmentStats = async (shipmentId: string) => {
    const { email } = this.store.getState().auth;

    if (!email && !isFirst) {
      isFirst = true;
      setTimeout(() => {
        this.loadShipmentStats(shipmentId);
      }, IntervalFirstUpdateShipmentStats);
      return;
    }

    const assigneeUserEmails = email ? [email] : undefined;
    const organization = await this.repositories.organizationRepository.get();
    const assigneeAudienceOrganizationIds = organization?.id ? [organization?.id] : undefined;
    const isCustomerOrganizationId = (organization?.role) === EOrganizationMemberRole.CUSTOMER;

    const paramsNotification = CommandCenterGetCommandCenterGetNotificationsDTM.fromPlain({
      page: `${0}`,
      size: '1',
      sort: 'createdAt,desc',
      shipmentId,
      assigneeAudienceOrganizationIds,
    });

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${0}`,
      size: '1',
      query: ENotificationStatus.TODO,
      shipmentId,
      assigneeUserEmails,
    });
    const paramsRequest = CommandCenterGetTasksDTM.fromPlain({
      page: `${0}`,
      size: '1',
      query: ENotificationStatus.TODO,
      type: ENotificationTaskType.CHANGE_REQUEST,
      assigneeUserEmails: !isCustomerOrganizationId ? assigneeUserEmails : undefined,
      createdBy: isCustomerOrganizationId ? email : undefined,
    });

    const [resTasks, resRequests] = await Promise.all<ShipmentAllStatsDTM | undefined>([
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...params,
      })),
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...paramsRequest,
      })),
    ]);

    const [resNotification] = await Promise.all<ShipmentAllStatsDTM | undefined>([
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...paramsNotification,
        assigneeUserEmails: undefined,
        targetAudienceOrganizationIds: assigneeAudienceOrganizationIds,
      })),
    ]);

    const tasksOverdue = resTasks?.taskStats.overdue;
    const alerts = resTasks?.taskStats.alerts;
    const alertsRequests = resRequests?.taskStats.alerts;

    const notificationImportantCount = resNotification?.notificationStats.unreadCritical;

    const allStats = await R.services.shipment.getShipmentStatsById(CommandCenterGetTasksDTM.fromPlain({
      assigneeUserEmails,
      shipmentId,
    }), shipmentId);

    if (allStats) {
      this.dispatch(appR.actions.overview.setShipmentByIdStats({
        ...allStats,
        alertsHighStats: alerts ? ShipmentAllStatsTaskStatsDTM.fromPlain({
          todo: 0,
          done: 0,
          expiring: alerts || 0,
          overdue: 0,
          alerts: 0,
          highTodo: 0,
          highExpiring: 0,
          highOverdue: 0,
        }) : undefined,
        requestsHighStats: alertsRequests ? ShipmentAllStatsTaskStatsDTM.fromPlain({
          todo: 0,
          done: 0,
          expiring: alertsRequests || 0,
          overdue: 0,
          alerts: 0,
          highTodo: 0,
          highExpiring: 0,
          highOverdue: 0,
        }) : undefined,
        taskHighStats: tasksOverdue ? ShipmentAllStatsTaskStatsDTM.fromPlain({
          todo: 0,
          done: 0,
          expiring: tasksOverdue || 0,
          overdue: 0,
          alerts: 0,
          highTodo: 0,
          highExpiring: 0,
          highOverdue: 0,
        }) : undefined,
        notificationHighStats: notificationImportantCount ? ShipmentAllStatsNotificationStatsDTM.fromPlain({
          unread: 0,
          read: 0,
          unreadCritical: notificationImportantCount || 0,
          unreadRegular: 0,
        }) : undefined,
      }));
    }
  }

  clearState = () => {
    const { shipmentStatsInterval } = this.store.getState().shipment;

    if (shipmentStatsInterval) {
      clearInterval(shipmentStatsInterval);
    }

    this.dispatch(R.actions.shipment.clear());
    this.repositories.shipmentPermissionsRepository.clear();
  }
}
