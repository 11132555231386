import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'app-wrapper/hooks';
import { SHIPPING_PARTY_MAX_LENGTHS } from 'app-wrapper/constants';
import {
  ICompanyAddressDTM, ICompanyContactDTM, IContactBookDefaultStateCompanyListStateDTM, IContactBookUpdateStateCompanyListStateErrorsDTM,
} from 'user-management/models/dtm';
import { TooltipError } from 'user-management/view/components';

import {
  SPAddCompanyContentBodyForm,
  SPAddCompanyContentBodyFormCompanyFirstBlock,
  SPAddCompanyContentBodyFormCompanyName,
  SPAddCompanyContentBodyFormCompanyNameInput,
  SPAddCompanyContentBodyFormCompanyNameTitle,
  SPAddCompanyContentBodyFormCompanyNameTitleWrapper,
  SPAddCompanyContentBodyFormCompanyTaxId,
  SPAddCompanyContentBodyFormCompanyTaxIdInput,
  SPAddCompanyContentBodyFormCompanyTaxIdTitle,
  SPAddCompanyContentBodyFormCompanyTaxIdTitleWrapper,
  SPAddCompanyContentBodyFormFooter,
  SPAddCompanyContentBodyFormWrap,
  SPAddCompanyContentBodyFormWrapper,
} from './ShipmentPartyAddCompany.styled';
import { AddressItem, ContactPersonItem } from './components';

interface IShipmentPartyAddCompanyComponentProps {
  companyListState: IContactBookDefaultStateCompanyListStateDTM[]
  companyListAddresses?: ICompanyAddressDTM[]
  companyListContactPersons?: ICompanyContactDTM[]
  companyListStateIndex: string
  isUpdating: boolean
  errors: IContactBookUpdateStateCompanyListStateErrorsDTM
  updateForm: () => void
  onDiscard: () => void
  onChangeListName: (index: string) => (name: string) => void
  onBlurListName: () => void
  onFocusListName: () => void
  onChangeTaxId: (index: string) => (name: string) => void
  onBlurTaxId: () => void
  onFocusTaxId: () => void
}

const ShipmentPartyAddCompanyComponent: FC<IShipmentPartyAddCompanyComponentProps> = (props) => {
  const {
    companyListState,
    companyListAddresses,
    companyListContactPersons,
    companyListStateIndex,
    isUpdating,
    errors,
    updateForm,
    onDiscard,
    onChangeListName,
    onBlurListName,
    onFocusListName,
    onChangeTaxId,
    onBlurTaxId,
    onFocusTaxId,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;

  const onChangeListNameHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeListName(companyListStateIndex)(event.target.value);
    },
    [companyListStateIndex],
  );

  const onChangeTaxIdHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeTaxId(companyListStateIndex)(event.target.value);
    },
    [companyListStateIndex],
  );

  const hasErrorName = useMemo(() => {
    const errDocument = errors?.name;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [companyListStateIndex, errors]);

  const hasErrorTaxId = useMemo(() => {
    const errDocument = errors?.taxId;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [companyListStateIndex, errors]);

  const tooltipMessage = errors?.name?.message || '';
  const tooltipVisible = errors?.name?.isTooltipVisible || false;

  return (
    <SPAddCompanyContentBodyFormWrap>
      <SPAddCompanyContentBodyForm>
        <SPAddCompanyContentBodyFormWrapper>
          <SPAddCompanyContentBodyFormCompanyFirstBlock>
            <SPAddCompanyContentBodyFormCompanyName>
              <SPAddCompanyContentBodyFormCompanyNameTitleWrapper>
                <SPAddCompanyContentBodyFormCompanyNameTitle>
                  {`${t('CompanyName')} ${t('requiredSymbol')}`}
                </SPAddCompanyContentBodyFormCompanyNameTitle>
              </SPAddCompanyContentBodyFormCompanyNameTitleWrapper>
              <TooltipError
                message={tooltipMessage}
                visible={tooltipVisible}
              >
                <SPAddCompanyContentBodyFormCompanyNameInput
                  largeInput={largeInput}
                  value={companyListState.filter((item) => item.customId === companyListStateIndex)?.[0]?.name}
                  hasError={hasErrorName}
                  onChange={onChangeListNameHandler}
                  onBlur={onBlurListName}
                  onFocus={onFocusListName}
                  maxLength={SHIPPING_PARTY_MAX_LENGTHS.COMPANY_NAME}
                />
              </TooltipError>
            </SPAddCompanyContentBodyFormCompanyName>

            <SPAddCompanyContentBodyFormCompanyTaxId>
              <SPAddCompanyContentBodyFormCompanyTaxIdTitleWrapper>
                <SPAddCompanyContentBodyFormCompanyTaxIdTitle>
                  {t('Tax ID')}
                </SPAddCompanyContentBodyFormCompanyTaxIdTitle>
              </SPAddCompanyContentBodyFormCompanyTaxIdTitleWrapper>
              <SPAddCompanyContentBodyFormCompanyTaxIdInput
                largeInput={largeInput}
                value={companyListState.filter((item) => item.customId === companyListStateIndex)?.[0]?.taxId || ''}
                hasError={hasErrorTaxId}
                onChange={onChangeTaxIdHandler}
                onBlur={onBlurTaxId}
                onFocus={onFocusTaxId}
                maxLength={SHIPPING_PARTY_MAX_LENGTHS.TAX_ID}
              />
            </SPAddCompanyContentBodyFormCompanyTaxId>
          </SPAddCompanyContentBodyFormCompanyFirstBlock>

          {companyListAddresses?.map((addressItem, addressIndex) => (
            <AddressItem
              key={`ContactBookAddressItem-${addressItem.customId}`}
              largeInput={largeInput}
              isFirst
              isLast={false}
              addressIndex={addressIndex}
              companyListStateIndex={companyListStateIndex}
              address={addressItem}
            />
          ))}

          {companyListContactPersons?.map((personItem, personIndex) => (
            <ContactPersonItem
              key={`ContactBookContactPersonItem-${personItem.customId}`}
              largeInput={largeInput}
              isFirst
              isLast={false}
              contactIndex={personIndex}
              personItem={personItem}
              companyListStateIndex={companyListStateIndex}
            />
          ))}

        </SPAddCompanyContentBodyFormWrapper>

      </SPAddCompanyContentBodyForm>

      <SPAddCompanyContentBodyFormFooter
        saveName={t('Add')}
        discardName={t('cancel')}
        discardChanges={onDiscard}
        updateForm={updateForm}
        disabledUpdate={isUpdating}
      />
    </SPAddCompanyContentBodyFormWrap>
  );
};

const ShipmentPartyAddCompanyComponentCache = memo(ShipmentPartyAddCompanyComponent);

export { ShipmentPartyAddCompanyComponentCache as ShipmentPartyAddCompanyComponent };
