import styled from 'styled-components';
import Typography from 'antd/es/typography';
import AntModal from 'antd/es/modal';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Select } from 'app-wrapper/view/components';

export const Modal = styled(AntModal)`
  &.ant-modal {
    width: 416px !important;
  }

  .ant-modal-header {
    padding: 0px;
    border-bottom: 0px;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #202C3C;
  }

  .ant-modal-body {
    padding: 32px 32px 0px 32px;
  }

  .ant-modal-footer {
    padding: 24px 32px;
    border-top: 0;

    button {
      border-radius: 3px;
    }

    button:first-of-type {
      border: 1px solid rgba(222, 225, 233, 1);
      color: #202C3C;
    }

    button:last-of-type {
      background: rgba(255, 77, 79, 1);
      border-color: rgba(255, 77, 79, 1);
    }

    button:last-of-type:disabled {
      border-color: #d9d9d9;
      background: #f5f5f5;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContentTitle = styled(Typography)`
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const TitleContentSubTitle = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const TitleIcon = styled(ExclamationCircleOutlined)`
  margin-right: 16px;
  font-size: 22px;
  color: rgba(255, 77, 79, 1);
`;

export const SpinnerLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const Label = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 20px;
`;

export const ReasonSelect = styled(Select)`
  min-height: 32px;
  height: 32px;

  .ant-select-arrow, .ant-select-clear {
    margin-top: -2px;
  }
`;
