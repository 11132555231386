import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { ContainerDTM } from 'shipment-operations/models/dtm';
import { EDrayageSide } from 'shipment-operations/constants/DrayageSide.enum';
import { useIsExportOrImport } from 'app-wrapper/hooks';
import { DepartureContainerComponent } from './DepartureContainer.component';

interface IDepartureContainerContainerProps {
  container: ContainerDTM
  openEditDrawer: (id: number) => void
  drayageSide: EDrayageSide
  isAbleToEdit?: boolean
}

const DepartureContainerContainer: FC<IDepartureContainerContainerProps> = observer(({
  container, openEditDrawer, drayageSide, isAbleToEdit,
}) => {
  const { companies } = useMobxStore();
  const { isExport } = useIsExportOrImport();

  let matchedId: number | undefined;
  if (isExport) {
    matchedId = container.originDrayageCarrierId;
  } else {
    matchedId = container.destinationDrayageCarrierId;
  }

  const selectedCompany = useMemo(() => companies.items.find((company) => company.id === matchedId), [companies, container]);

  return (
    <DepartureContainerComponent
      container={container}
      openEditDrawer={openEditDrawer}
      drayageSide={drayageSide}
      isAbleToEdit={isAbleToEdit}
      companyName={selectedCompany?.name}
    />
  );
});

export { DepartureContainerContainer as DepartureContainer };
