import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentGap, ContentSection,
  ContentSectionTitle,
  FooterContainer,
  GapVerticalContainerStyled, SubPage,
  SubPageContent,
  Wrapper,
  ContentSectionContainer,
  InputTitle,
  Title, TaskInfo, TasksSteps,
} from 'app-wrapper/view/guideline';
import { Footer } from 'app-wrapper/view/guideline/Footer/Footer.styled';
import { CustomModal } from 'shipment-operations/view/components';
import {
  HintBox, SkeletonSection,
} from 'app-wrapper/view/components';
import { TextContainer } from 'shipment-operations/view/drawers/ChangeRequestDrawer/components/Text';
import {
  DocumentsSectionsContainer,
} from 'shipment-operations/view/drawers/ChangeRequestDrawer/components/DocumentsSections';
import { NotificationTaskContentDTM } from 'app-wrapper/models/dtm';
import { FooterButtonsContainer } from 'shipment-operations/view/drawers/ChangeRequestDrawer/components/FooterButtons';
import { ENotificationStatus, EChangeRequestType, EChangeRequestTypeText } from 'app-wrapper/constants';

interface IChargesCostChangesComponentProps {
  isLoading: boolean
  onClose: () => void
  changeRequest: NotificationTaskContentDTM | undefined
  type: EChangeRequestType
  editMode: boolean
  steps: {
    title: string
    text?: string
  }[]
}

const ChangeRequestDrawerComponent: FC<IChargesCostChangesComponentProps> = ({
  onClose, isLoading, changeRequest, editMode, type, steps,
}) => {
  const { t } = useTranslation();

  const handleCloseDrawer = useCallback(() => {
    onClose();
  }, []);

  const title = useMemo(() => {
    if (isLoading) {
      return '';
    }
    return changeRequest ? t('Update Containers Details') : t('Create Change Request');
  }, [isLoading, changeRequest]);

  return (
    <CustomModal
      title={title}
      onClose={handleCloseDrawer}
      contentStyles={{
        left: 'unset',
        width: '736px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
        padding: '0',
      }}
    >
      <SubPage>
        {isLoading
          ? (
            <Wrapper>
              <GapVerticalContainerStyled width="100%">
                <SkeletonSection height="85px" />
                <SkeletonSection height="183px" />
              </GapVerticalContainerStyled>
            </Wrapper>
          )
          : (
            <>
              <Wrapper>
                <GapVerticalContainerStyled width="100%">
                  { changeRequest
                    ? <TaskInfo notificationTask={changeRequest} shipmentIdClick={handleCloseDrawer} />
                    : (
                      <HintBox>
                        <GapVerticalContainerStyled>
                          <Title>
                            {t('How does a change request work?')}
                          </Title>
                          <span>
                            {t('You specify the changes that need to be made, and our operator will process your request within 24 hours. After reviewing and approving the changes, the operator will update the information, and you’ll see the changes reflected in the system.')}
                          </span>
                        </GapVerticalContainerStyled>
                      </HintBox>
                    )}
                  <SubPageContent>
                    <ContentSectionTitle>{t('Request Details')}</ContentSectionTitle>
                    <GapVerticalContainerStyled width="100%">
                      <ContentSection>
                        { changeRequest && !editMode
                          ? changeRequest?.payload?.changeRequest?.description
                          : (
                            <GapVerticalContainerStyled width="100%">
                              <ContentGap>
                                <InputTitle title={t('Topic')}>
                                  <Title>{EChangeRequestTypeText[type]}</Title>
                                </InputTitle>
                              </ContentGap>
                              <ContentSectionContainer />
                              <ContentGap>
                                <ContentSectionContainer>
                                  <InputTitle title={t('Description')}>
                                    <TextContainer />
                                  </InputTitle>
                                </ContentSectionContainer>
                              </ContentGap>
                            </GapVerticalContainerStyled>
                          )}
                      </ContentSection>
                      <ContentSection>
                        <DocumentsSectionsContainer />
                      </ContentSection>
                      { changeRequest ? <TasksSteps steps={steps} /> : null }
                    </GapVerticalContainerStyled>
                  </SubPageContent>
                </GapVerticalContainerStyled>
              </Wrapper>
              {changeRequest?.status && [ENotificationStatus.DONE, ENotificationStatus.REJECTED].includes(changeRequest.status)
                ? null
                : (
                  <Footer>
                    <FooterContainer>
                      <FooterButtonsContainer />
                    </FooterContainer>
                  </Footer>
                )}
            </>
          )}
      </SubPage>
    </CustomModal>
  );
};

export { ChangeRequestDrawerComponent };
