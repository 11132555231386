import styled from 'styled-components';
import AntTag from 'antd/es/tag';

import { Divider as DividerCommon } from 'app-wrapper/view/components';
import {
  SubPageContent,
  FlexRow,
} from 'app-wrapper/view/guideline';
import { ContentSection } from 'app-wrapper/view/guideline/ContentSection/ContentSection.styled';
import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  TitleText,
  SmallTextFootnoteDescription,
  BodyTextRegular,
} from 'app-wrapper/view/guideline/Font/Font.styled';

export const Wrap = styled(SubPageContent)`
  gap: 8px;
`;

export const Title = styled.span`
  ${TitleText}
`;

export const Content = styled(ContentSection)`
  display: flex;
  flex-direction: column;
`;

export const ContentRow = styled(FlexRow)`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
  height: 100px;
`;

export const Divider = styled(DividerCommon)`
  height: 100%;
`;

export const ReferencesBlock = styled.div`
  flex-direction: column;
  display: flex;
  gap: 16px;
`;

export const Text = styled.span`
  ${SmallTextFootnoteDescription}
  color: ${themesColors.darkGrayMiddle};
`;

export const TextValue = styled.span`
  ${BodyTextRegular}
`;

export const References = styled(FlexRow)`
  flex-wrap: wrap;
  gap: 8px;
`;

export const Tag = styled(AntTag)`
  ${SmallTextFootnoteDescription}
  margin-right: 0;
  width: fit-content;
  background-color: #F4F5F8;
  border: 1px solid #EAEEF8;
  color: #73819B;
`;

export const ArrowWrap = styled.div`
  cursor: pointer;
  margin-top: 2px;
`;
