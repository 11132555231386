import React, {
  FC,
  memo,
  useCallback, useEffect, useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { InfoCircleOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import {
  AlertBlock, Button, Tooltip, Row, Col, Panel, Collapse,
  ExpandIcon,
} from 'app-wrapper/view/components';
import { Option } from 'app-wrapper/view/controllers/SelectController/SelectController';

import {
  useCollapseOnChangeHandler, useWindowSize,
} from 'app-wrapper/hooks';
import {
  CloseSvg, VerticalFormItemSvg,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { REQUEST_STATUS } from 'app-wrapper/constants';

import { getNotFoundContent } from 'monetary/hooks';
import { FreightFromCommodityValuesStateDTM, IFreightSelectFieldDTM } from 'monetary/models/dtm';

import { IMOClass as IMOClassData } from './data';

import {
  CommodityFAK,
  CommoditySelect,
  CommodityWrapper,
} from './Commodity.styled';

const collapseIcon = (<VerticalFormItemSvg />);
const debounceTime = 300;

export interface ICommodityComponentProps {
  isAllDisabled?: boolean
  forceCollapse?: boolean
  isCollapsed?: boolean
  temperatureControl?: boolean
  onChangeCommodityTemperature: (temperatureControl: boolean) => void
  onAddCommodityValuesItem: () => void
  onRemoveCommodityValuesItem: (indexItem: number) => () => void
  commodityValues?: FreightFromCommodityValuesStateDTM[]
  isCommodityError?: boolean
  quotaStatus?: string
  isSubmitVisited?: boolean
  isSubmitClicked?: boolean
  // HsCode
  getRFQCommodityHsCodeDyIndex: (indexItem: number) => (value: string | null) => void
  hsCodeState?: {
    // this simulation array keys
    [key: number]: {
      status?: string
      values?: IFreightSelectFieldDTM[]
    }
  }
  onChangeCommodityHsCode: (indexItem: number) => (value: IFreightSelectFieldDTM) => void
  onClearCommodityHsCode: (indexItem: number) => () => void
  onFocusCommodityHsCode: (indexItem: number) => () => void
  onBlurCommodityHsCode: (indexItem: number) => () => void
  onKeyPressHsCodeByIndex: (indexItem: number) => (key: string) => void
  // IMO Class
  onChangeCommodityIMOClass: (indexItem: number) => (value: string) => void
  onClearCommodityIMOClass: (indexItem: number) => () => void
  onFocusCommodityIMOClass: (indexItem: number) => () => void
  onBlurCommodityIMOClass: (indexItem: number) => () => void
}

const CommodityComponent: FC<ICommodityComponentProps> = ((props) => {
  const {
    isAllDisabled,
    forceCollapse,
    isCollapsed,
    temperatureControl,
    onAddCommodityValuesItem,
    onRemoveCommodityValuesItem,
    commodityValues,
    isCommodityError,
    quotaStatus,
    isSubmitVisited,
    isSubmitClicked,
    // HsCode
    getRFQCommodityHsCodeDyIndex,
    onChangeCommodityHsCode,
    onClearCommodityHsCode,
    onFocusCommodityHsCode,
    onBlurCommodityHsCode,
    onKeyPressHsCodeByIndex,
    hsCodeState,
    // IMO Class
    onChangeCommodityIMOClass,
    onClearCommodityIMOClass,
    onFocusCommodityIMOClass,
    onBlurCommodityIMOClass,
  } = props;
  const { isFullMediaWidth } = useWindowSize();
  const [isResponsive, setIsResponsive] = useState(isFullMediaWidth);

  useEffect(() => {
    if (forceCollapse) {
      setIsResponsive(false);
    } else {
      // old changed this
      setIsResponsive(true);
    }
  }, [forceCollapse]);
  const { t } = useTranslation();

  const handleAddButton = useCallback(() => {
    onAddCommodityValuesItem();
  }, [onAddCommodityValuesItem]);

  const isInfoBlock = temperatureControl && commodityValues?.length && commodityValues?.length > 1;

  const headerIsFullComponent = useMemo(() => isResponsive && (
    <>
      <Row justify="space-between" className="RFRFormRequest__Row__col_both__title">
        <div>
          <Typography.Text>{t('Commodity')}</Typography.Text>
        </div>
      </Row>
      {!!commodityValues?.length && (
        <Row className="RFRFormRequest__Row__Commodity__content">
          <Col span={14}>
            <div className="RFRFormRequest__Row__Commodity__content_title">
              <Typography.Text>{t('Type')} </Typography.Text>
              <Typography.Text className="text_placeholder">{t('default FAK')}
                <Tooltip title={t('fakInfoText')} placement="top">
                  <InfoCircleOutlined className="RFRFormRequest__Row__Commodity__content_title_icon" />
                </Tooltip>
              </Typography.Text>
            </div>
          </Col>
          {/* temporarily hidden */}
          {false && (
            <Col span={9}>
              <div className="RFRFormRequest__Row__Commodity__content_title">
                <Typography.Text>{t('IMO Class')}</Typography.Text>
              </div>
            </Col>
          )}
          <Col span={1} style={{ minWidth: isFullMediaWidth ? '40px' : '32px' }} />
        </Row>
      )}
    </>
  ), [isFullMediaWidth, commodityValues?.length, isResponsive, t]);

  const IMOClassComponent = useCallback((index) => (
    <CommoditySelect
      value={commodityValues?.[index]?.IMOClass || null}
      data-class={`IMOClassComponent_${index}`}
      allowClear={!!commodityValues?.[index]?.IMOClass}
      disabled={isAllDisabled}
      suffixIcon={collapseIcon}
      onClear={onClearCommodityIMOClass(index)}
      onChange={onChangeCommodityIMOClass(index)}
      onFocus={onFocusCommodityIMOClass(index)}
      onBlur={onBlurCommodityIMOClass(index)}
    >
      {IMOClassData.map((itemIMO, indexIMO) => (
        <Option key={`${indexIMO + 1}_IMOClass.map`} value={`${itemIMO.value}`}>
          {itemIMO.name}
        </Option>
      ))}
    </CommoditySelect>
  ), [
    commodityValues,
    isAllDisabled,
    isSubmitVisited,
    t,
  ]);

  const onChangeCommodityHsCodeHandler = useCallback(
    (index: number) => (value: string, option: unknown) => {
      const { description } = option as { description: string } || {};

      if (value) {
        onChangeCommodityHsCode(index)({
          code: value,
          description: description || '',
        });
      }
    },
    [onChangeCommodityHsCode],
  );

  const debounceHsCodeSelect = useMemo(() => debounce((index: number, newValue: string) => {
    getRFQCommodityHsCodeDyIndex(index)(newValue);
  }, debounceTime), [getRFQCommodityHsCodeDyIndex]);
  const throttledHsCodeSelect = useMemo(() => throttle((index: number, newValue: string) => {
    debounceHsCodeSelect(index, newValue);
  }, 40), [debounceHsCodeSelect]);

  const onKeyPressHsCodeByIndexHandler = useCallback((index: number) => (key: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyPressHsCodeByIndex(index)(key.key);
  }, [onKeyPressHsCodeByIndex]);

  const onSearchHsCodeSelectHandler = useCallback(
    (index: number) => (value: string) => {
      throttledHsCodeSelect(index, value);
    },
    [throttledHsCodeSelect],
  );

  const getHsCodeValue = useCallback(
    (index: number) => {
      let value = null;

      if (commodityValues?.[index]?.hsCode?.code) {
        value = commodityValues?.[index]?.hsCode?.code;
      }

      if (value && commodityValues?.[index]?.hsCode?.description) {
        value = `${value} ${commodityValues?.[index]?.hsCode?.description}`;
      }

      if (!value && commodityValues?.[index]?.hsCode?.description) {
        value = `${commodityValues?.[index]?.hsCode?.description}`;
      }

      return value;
    },
    [commodityValues],
  );

  const HsCodeSelectComponent = useCallback((index: number, dropdownMatchSelectWidth?: number) => (
    <CommoditySelect
      value={getHsCodeValue(index)}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      data-class={`commodityTypeComponent_${index}`}
      showSearch
      allowClear={!!commodityValues?.[index]?.hsCode}
      autoClearSearchValue={false}
      disabled={isAllDisabled}
      onSearch={onSearchHsCodeSelectHandler(index)}
      onClear={onClearCommodityHsCode(index)}
      placeholder={`${t('Start entering H.S or Name')}`}
      notFoundContent={getNotFoundContent(hsCodeState?.[index]?.status || '', !!hsCodeState?.[index]?.values?.length)}
      showArrow={false}
      filterOption={false}
      onChange={onChangeCommodityHsCodeHandler(index)}
      onFocus={onFocusCommodityHsCode(index)}
      onBlur={onBlurCommodityHsCode(index)}
      onKeyDown={onKeyPressHsCodeByIndexHandler(index)}
    >
      {hsCodeState?.[index]?.values?.map(({ id: idHsCode, code, description }) => (
        <Option key={`origin_locationValues_${idHsCode} `} description={description} value={code || ''}>{code || ''} {description}</Option>
      ))}
    </CommoditySelect>
  ), [throttledHsCodeSelect, commodityValues, isAllDisabled, hsCodeState, isSubmitVisited, onClearCommodityHsCode, onSearchHsCodeSelectHandler, onFocusCommodityHsCode, onBlurCommodityHsCode, onKeyPressHsCodeByIndexHandler, onChangeCommodityHsCodeHandler]);

  const itemCommodityIsFull = useCallback((item: FreightFromCommodityValuesStateDTM, index) => (
    <Row className={`${index === 0 ? 'RFRFormRequest__Row__Commodity__parent_content_item_first' : ''} RFRFormRequest__Row__Commodity__content RFRFormRequest__Row__Commodity__parent_content_item`} key={`${item.id} `}>
      <Col span={14}>
        {HsCodeSelectComponent(index, 400)}
      </Col>
      {/* temporarily hidden */}
      {false && (
        <Col span={9}>
          {IMOClassComponent(index)}
        </Col>
      )}
    </Row>
  ), [
    temperatureControl,
    isAllDisabled,
    t,
    IMOClassComponent,
    onRemoveCommodityValuesItem,
    HsCodeSelectComponent, handleAddButton, onAddCommodityValuesItem,
  ]);

  const itemCommodityIsMedium = useCallback((item: FreightFromCommodityValuesStateDTM, index) => (
    <Row
      className={`${index === 0 ? 'RFRFormRequest__Row__Commodity__parent_content_item_first' : ''} RFRFormRequest__Row__Commodity__content RFRFormRequest__Row__Commodity__parent_content_item
RFRFormRequest__Row__Commodity__parent_content_item__medium`}
      key={`${item.id} `}
    >
      <Col span={24}>
        <Row>
          <Col span={18}>
            <div className="RFRFormRequest__Row__Commodity__content_title">
              <Typography.Text>{t('Type')} </Typography.Text>
              <Typography.Text className="text_placeholder">{t('default FAK')}
                <Tooltip title={t('fakInfoText')} placement="top">
                  <InfoCircleOutlined className="RFRFormRequest__Row__Commodity__content_title_icon" />
                </Tooltip>
              </Typography.Text>
            </div>
          </Col>
          <Col span={6} className="RFRFormRequest__Row__Commodity__content__col_add">
            {index !== 0 && (
              <Button
                onClick={onRemoveCommodityValuesItem(index)}
                disabled={isAllDisabled}
                type="dashed"
                size="small"
                icon={<CloseSvg height={11} width={11} />}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {HsCodeSelectComponent(index)}
          </Col>
        </Row>
      </Col>
      {/* temporarily hidden */}
      {false && (
        <Col span={24} className="RFRFormRequest__Row__Commodity__content__row_second">
          <Row>
            <Col span={8}>
              <div className="RFRFormRequest__Row__Commodity__content_title">
                <Typography.Text>{t('IMO Class')}</Typography.Text>
              </div>
            </Col>

          </Row>
          <Row>
            <Col span={8}>
              <span>
                {IMOClassComponent(index)}
              </span>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  ), [t,
    isAllDisabled,
    IMOClassComponent,
    HsCodeSelectComponent,
  ]);

  const commodityComponent = useMemo(() => (
    <>
      {headerIsFullComponent}
      {!commodityValues?.length && (
        <CommodityFAK>
          {t('FAK')}
        </CommodityFAK>
      )}
      {commodityValues?.map((item, index) => (
        isResponsive ? itemCommodityIsFull(item, index) : itemCommodityIsMedium(item, index)
      ))}
      {
        isInfoBlock && (
          <Row className="RFRFormRequest__Row__msg">
            <Col span={24}><AlertBlock type="warning" message={t('onlyOneCommotiy')} /></Col>
          </Row>
        )
      }
    </>
  ), [
    temperatureControl,
    isInfoBlock,
    t,
    headerIsFullComponent,
    isResponsive,
    itemCommodityIsFull,
    itemCommodityIsMedium,
  ]);

  const endVisible = useRef<HTMLElement>(null);

  const [
    changeErrorIsCollapseState, setChangeErrorIsCollapseState,
  ] = useState<Array<string>>([]);

  useEffect(() => {
    if (isSubmitVisited && isCommodityError) {
      setChangeErrorIsCollapseState(['1']);
    }
  }, [isSubmitVisited, isCommodityError, isSubmitClicked]);

  useEffect(() => {
    if (quotaStatus === REQUEST_STATUS.pending) {
      if (commodityValues?.some((item) => (item.hsCode?.code || item.IMOClass || item.goodsValue || item.UNNumber))) {
        setChangeErrorIsCollapseState(['1']);
        return;
      }
      setChangeErrorIsCollapseState([]);
    }
  }, [quotaStatus, commodityValues]);

  const changeErrorIsCollapseCallback = useCallback(
    () => {
      setChangeErrorIsCollapseState((prev) => (prev?.length ? [] : ['1']));
    },
    [],
  );

  const collapseOnChangeHandler = useCollapseOnChangeHandler(
    endVisible.current, changeErrorIsCollapseCallback,
  );

  const collapseComponent = useMemo(() => (
    <Collapse
      expandIcon={({ isActive }) => ExpandIcon({
        isActive,
        color: `${changeErrorIsCollapseState.length ? themesColors.primaryBranding6 : themesColors.neutralBranding7} `,
      })}
      onChange={collapseOnChangeHandler}
      activeKey={changeErrorIsCollapseState}
    >
      <Panel header={t('Commodity')} key="1">
        {commodityComponent}
      </Panel>
    </Collapse>
  ), [changeErrorIsCollapseState, commodityComponent, t]);

  return (
    <CommodityWrapper isNotCollapsed={!isCollapsed}>
      <Row className="RFRFormRequest__Row__col_both RFRFormRequest__Row__Commodity RFRFormRequest__Row__col__commodityPanel">
        <Col span={24}>
          {!isResponsive ? (
            collapseComponent
          ) : (
            commodityComponent
          )}
        </Col>
      </Row>
    </CommodityWrapper>
  );
});

const CachedCommodityComponent = memo(CommodityComponent);

export { CachedCommodityComponent as CommodityComponent };
