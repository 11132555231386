import { components, paths } from 'api-contracts/dev/task-service/v1/schema';
import {
  EChangeRequestType,
  ENotificationCriticality,
  ENotificationDomain,
  ENotificationStatus,
} from 'app-wrapper/constants';
import { TaskTypeEnum } from 'app-wrapper/enums';
import {
  ICommandCenterTasksFiltersResponse,
  ICommandCenterTasksResponse,
  IGetCommandCenterTasksAllStatsContract,
  IShipmentCostChangeTaskResponse,
} from 'app-wrapper/models/contracts';
import {
  ICommandCenterNotificationsResponse,
  ICommandCenterNotificationsViewedResponse,
} from 'app-wrapper/models/contracts/commandCenterNotifications.contract';
import {
  CommandCenterGetCommandCenterGetNotificationsDTM,
  CommandCenterGetTasksDTM,
  DateDtm,
  INotificationTaskContentDTM, IPayloadDTM,
  NotificationTaskContentActionsDTM,
  NotificationTaskContentAssigneeDTM, NotificationTaskContentAttachmentDTM,
  NotificationTaskContentCreatedByDTM,
  NotificationTaskContentDTM,
  NotificationTaskContentViewedDTM,
  NotificationTaskDTM,
  ShipmentCostChangeTaskMetadataDTM,
  TasksFiltersDTM,
  TasksFiltersOrganizationDTM,
} from 'app-wrapper/models/dtm';
import { apiWorker } from 'app-wrapper/repository/utilsServices';

import { ServerError } from 'app-wrapper/types/ServerError';
import { AxiosError } from 'axios';
import moment from 'moment';
import { BaseService } from 'proto/BaseService';
import { ETasksType } from 'shipment-operations/constants';

import { ShipmentAllStatsDTM } from 'shipment-operations/models/dtm';
import { v4 as uuidv4 } from 'uuid';

export class CommandCenterService extends BaseService {
  private baseUrl = '/task-service/api/v1/tasks';

  private baseUrlNotifications = '/task-service/api/v1/notifications';

  public getShipmentCostChangesTaskMetadataById = async (taskId: string): Promise<ShipmentCostChangeTaskMetadataDTM | null> => {
    const responseRaw = await apiWorker.requestGet<IShipmentCostChangeTaskResponse>(`${this.baseUrl}/${taskId}`);
    const { metadata } = responseRaw.data;

    if (metadata?.taskType !== ETasksType.CONFIRM_SHIPMENT_COST_CHANGE_TASK) {
      return null;
    }

    return ShipmentCostChangeTaskMetadataDTM.fromPlain({
      ...metadata,
      taskType: metadata?.taskType || '',
      shipmentId: metadata?.shipmentId || '',
      shipmentName: metadata?.shipmentName || '',
      chargeChanges: JSON.parse(metadata.chargeChanges),
    });
  }

  public getOrganizationStats = async (params: CommandCenterGetTasksDTM) => {
    let result: ShipmentAllStatsDTM | undefined;

    if (params.dueDateTo === 'undefined' || params.dueDateFrom === 'undefined') {
      return result;
    }

    const response = await apiWorker.requestGet<IGetCommandCenterTasksAllStatsContract>('/task-service/api/v1/organizations/current/stats', {
      params: {
        page: params.page,
        size: params.size,
        type: params.type,
        status: params.query,
        criticality: params.criticality?.join(','),
        sort: params.sort,
        includeEmptyDueDate: params.includeEmptyDueDate,
        'dueDate.to': params.dueDateTo,
        'dueDate.from': params.dueDateFrom,
        'completedAt.from': params.completedAtFrom,
        'completedAt.to': params.completedAtTo,
        'metadata[shipmentId]': params.shipmentId,
        domains: params.domain?.trim(),
        'targetAudience.organizationIds': params?.targetAudienceOrganizationIds?.join(',') || undefined,
        'targetAudience.userEmails': params?.targetAudienceUserEmails?.join(',') || undefined,
        'objectReference.organizationIds': params?.objectReferenceOrganizationIds?.join(',') || undefined,
        'objectReference.paymentIds': params?.objectReferencePaymentIds?.join(',') || undefined,
        'objectReference.rateRequestIds': params?.objectReferenceRateRequestIds?.join(',') || undefined,
        'objectReference.shipmentIds': params?.objectReferenceShipmentIds?.join(',') || undefined,
        'assignee.organizationIds': params?.assigneeOrganizationIds?.join(',') || undefined,
        'assignee.userEmails': params?.assigneeUserEmails?.join(',') || undefined,
        organizationId: params?.userOrganizationIds,
        createdBy: params?.createdBy,
      },
    }, true);

    const { data } = response;

    return ShipmentAllStatsDTM.fromPlain({
      objectReference: data.objectReference && {
        id: data.objectReference.id,
        type: data.objectReference.type,
        shipmentId: data.objectReference.shipmentId,
      },
      taskStats: {
        todo: data?.taskStats.todo,
        done: data?.taskStats.done,
        expiring: data?.taskStats.expiring,
        overdue: data?.taskStats.overdue,
        alerts: data?.taskStats.alerts,
        highTodo: data?.taskStats.highTodo,
        highExpiring: data?.taskStats.highExpiring,
        highOverdue: data?.taskStats.highOverdue,
      },
      notificationStats: {
        unread: data?.notificationStats.unread,
        read: data?.notificationStats.read,
        unreadCritical: data?.notificationStats.unreadCritical,
        unreadRegular: data?.notificationStats.unreadRegular,
      },
    });
  }

  public getTasks = async (params: CommandCenterGetTasksDTM) => {
    let result: NotificationTaskDTM | null = null;
    let response: ICommandCenterTasksResponse | null = null;

    if (params.dueDateTo === 'undefined' || params.dueDateFrom === 'undefined') {
      return result;
    }

    try {
      const rawResponse = await apiWorker.requestGet<ICommandCenterTasksResponse>(`${this.baseUrl}`, {
        params: {
          page: params.page,
          type: params.type,
          size: params.size,
          status: params.query,
          criticality: params.criticality?.join(','),
          sort: params.sort,
          includeEmptyDueDate: params.includeEmptyDueDate,
          'dueDate.to': params.dueDateTo,
          'dueDate.from': params.dueDateFrom,
          'completedAt.from': params.completedAtFrom,
          'completedAt.to': params.completedAtTo,
          'metadata[shipmentId]': params.shipmentId,
          'metadata[changeRequestType]': params.changeRequestType,
          domains: params.domain?.trim(),
          'targetAudience.organizationIds': params?.targetAudienceOrganizationIds?.join(',') || undefined,
          'targetAudience.userEmails': params?.targetAudienceUserEmails?.join(',') || undefined,
          'objectReference.organizationIds': params?.objectReferenceOrganizationIds?.join(',') || undefined,
          'objectReference.paymentIds': params?.objectReferencePaymentIds?.join(',') || undefined,
          'objectReference.rateRequestIds': params?.objectReferenceRateRequestIds?.join(',') || undefined,
          'objectReference.shipmentIds': params?.objectReferenceShipmentIds?.join(',') || undefined,
          'assignee.organizationIds': params?.assigneeOrganizationIds?.join(',') || undefined,
          'assignee.userEmails': params?.assigneeUserEmails?.join(',') || undefined,
          'assignee.userAssigned': params?.assigneeUserAssigned || undefined,
          organizationId: params?.userOrganizationIds,
          createdBy: params?.createdBy,
        },
      });

      response = rawResponse.data;

      if (response) {
        result = NotificationTaskDTM.fromPlain({
          totalPages: response.totalPages || undefined,
          totalElements: response.totalElements || undefined,
          content: response.content.map((item) => this.notificationTaskContent(item)),
        });
      }

      const errors = result?.validate();

      if (errors?.length) {
        console.error('DTM valid RFQ: error', errors, result);
      }
    } catch (e) {
      const error = e as AxiosError<ServerError>;

      if (error.status === 500) {
        throw new Error('CommandCenterService: getTasks');
      }

      if (error?.response?.data?.message) {
        throw error?.response?.data?.message;
      }
      if (error?.response?.data?.details) {
        throw error?.response?.data?.details[0];
      }

      throw e;
    }

    return result;
  }

  async postTask(params: paths['/api/v1/tasks']['post']['requestBody']['content']['application/json']) {
    const rawResponse = await apiWorker.requestPostBySchema(`${this.baseUrl}` as '/api/v1/tasks', params);

    const response = rawResponse.data;

    return this.notificationTaskContent(response);
  }

  async postAttachment(taskId: number, file: FormData) {
    const rawResponse = await apiWorker.requestPostBySchema(
      `/task-service/api/v1/tasks/${taskId}/attachments` as '/api/v1/tasks/{taskId}/attachments',
      // @ts-ignore
      file,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );

    const response = rawResponse.data;

    return NotificationTaskContentAttachmentDTM.fromPlain(response);
  }

  async downloadAttachment(taskId: number, attachment: NotificationTaskContentAttachmentDTM) {
    const response = await apiWorker.requestGetBySchema(
      `/task-service/api/v1/tasks/${taskId}/attachments/${attachment.id}` as '/api/v1/tasks/{taskId}/attachments/{attachmentId}', {
        responseType: 'blob',
      },
    );
    // contract has id and name instead of Blob
    // @ts-ignore
    const blob = response.data as Blob;

    if (!blob) {
      return;
    }

    this.downloadBlob(blob, attachment.name);
  }

  async deleteAttachment(taskId: number, attachmentId: number) {
    await apiWorker.requestDeleteBySchema(
      `/task-service/api/v1/tasks/${taskId}/attachments/${attachmentId}` as '/api/v1/tasks/{taskId}/attachments/{attachmentId}',
    );
  }

  public getTasksFilters = async (params?: CommandCenterGetTasksDTM) => {
    let result: TasksFiltersDTM | undefined;
    let response: ICommandCenterTasksFiltersResponse | undefined;

    const rawResponse = await apiWorker.requestGet<ICommandCenterTasksFiltersResponse>(`${this.baseUrl}/filters`, {
      params: params && {
        page: params.page,
        size: params.size,
        status: params.query,
        sort: params.sort,
        includeEmptyDueDate: params.includeEmptyDueDate,
        criticality: params.criticality?.join(','),
        'dueDate.to': params.dueDateTo,
        'dueDate.from': params.dueDateFrom,
        'metadata[shipmentId]': params.shipmentId,
        domains: params.domain?.trim(),
        'targetAudience.organizationIds': params?.targetAudienceOrganizationIds,
        'targetAudience.userEmails': params?.targetAudienceUserEmails,
        'objectReference.organizationIds': params?.objectReferenceOrganizationIds,
        'objectReference.paymentIds': params?.objectReferencePaymentIds,
        'objectReference.rateRequestIds': params?.objectReferenceRateRequestIds,
        'objectReference.shipmentIds': params?.objectReferenceShipmentIds,
        'assignee.organizationIds': params?.assigneeOrganizationIds,
        'assignee.userEmails': params?.assigneeUserEmails,
        organizationId: params?.userOrganizationIds,
        createdBy: params?.createdBy,
      },
    });

    if (rawResponse.data) {
      response = rawResponse.data;
    }

    if (response) {
      result = TasksFiltersDTM.fromPlain({
        createdAt: {
          from: response?.createdAt?.from,
          to: response?.createdAt?.to,
        },
        completedAt: {
          from: response.createdAt.from,
          to: response.createdAt.to,
        },
        criticality: response.criticality || [],
        domains: response.domains || [],
        dueDate: {
          from: response.createdAt.from,
          to: response.createdAt.to,
        },
        targetAudience: {
          organizations: response?.targetAudience?.organizations?.map((item) => TasksFiltersOrganizationDTM.fromPlain(item)),
          users: response?.targetAudience?.users?.map((item) => NotificationTaskContentAssigneeDTM.fromPlain(item)),
        },
        objectReference: {
          organizations: response?.objectReference?.organizations?.map((item) => TasksFiltersOrganizationDTM.fromPlain(item)),
          paymentIds: response?.objectReference?.paymentIds || [],
          rateRequestIds: response?.objectReference?.rateRequestIds || [],
          shipmentIds: response?.objectReference?.shipmentIds || [],
        },
        assignee: {
          organizations: response?.assignee?.organizations?.map((item) => TasksFiltersOrganizationDTM.fromPlain(item)),
          users: response?.assignee?.users?.map((item) => NotificationTaskContentAssigneeDTM.fromPlain(item)),
        },
      });
    }

    const errors = result?.validate();

    if (errors?.length) {
      console.error('DTM valid RFQ: error', errors, result);
    }

    return result;
  }

  public getTask = async (taskId: number) => {
    const response = await apiWorker.requestGetBySchema(`${this.baseUrl}/${taskId}` as '/api/v1/tasks/{taskId}');

    return this.notificationTaskContent(response.data);
  }

  public putTask = async (task: INotificationTaskContentDTM) => {
    const requestBody = {
      id: Number(task.id) || undefined,
      type: task.type as TaskTypeEnum,
      title: task.title as string,
      description: task.description || undefined,
      criticality: task.criticality as ENotificationCriticality,
      domain: task.domain || undefined,
      organizationId: (task.organizationId || undefined) as number,
      view: !!task.view || undefined,
      status: task.status as ENotificationStatus,
      dueDate: task.dueDate?.getDateISO(),
      objectReference: {
        id: task?.objectReference?.id || undefined,
        shipmentId: task?.objectReference?.shipmentId || undefined,
        type: (task?.objectReference?.type || undefined) as string,
      },
      targetAudience: {
        type: (task?.targetAudience?.type || undefined) as string,
        id: task?.targetAudience?.id || undefined,
        organizationId: task?.targetAudience?.organizationId,
        organizationIds: task?.targetAudience?.organizationIds || undefined,
      },
      actions: task.actions?.map((itemAction) => ({
        type: (itemAction?.type || undefined) as string,
        id: itemAction?.id || undefined,
        title: itemAction?.title as string,
        link: itemAction?.link,
      })),
      metadata: {
        taskType: (task?.metadata?.taskType || undefined) as string,
        shipmentId: (task?.metadata?.shipmentId || undefined) as string,
        shipmentName: (task?.metadata?.shipmentName || undefined) as string,
        changeRequestType: (task?.metadata?.changeRequestType || undefined) as string,
      },
      assignee: task?.assignee ? {
        type: task?.assignee.type as string,
        users: task?.assigneeUsers,
        user: task.assignee.email
          ? {
            email: task.assignee.email,
          } : undefined,
        organization: {
          id: task.assigneeOrganizationId as number,
          name: task.assigneeOrganizationName as string,
        },
      } : undefined,
      payload: task?.payload as { [key: string]: Record<string, never>; },
    };

    const response = await apiWorker.requestPutBySchema(`${this.baseUrl}/${task.id}` as '/api/v1/tasks/{taskId}', requestBody);

    return this.notificationTaskContent(response.data);
  }

  public getNotifications = async (params: CommandCenterGetCommandCenterGetNotificationsDTM) => {
    let result: NotificationTaskDTM | null = null;
    let response: ICommandCenterNotificationsResponse | null = null;

    try {
      const rawResponse = await apiWorker.requestGet<ICommandCenterNotificationsResponse>(`${this.baseUrlNotifications}`, {
        params: {
          page: params.page,
          size: params.size,
          status: params.query,
          sort: params.sort,
          'dueDate.to': params.dueDateTo,
          'dueDate.from': params.dueDateFrom,
          'createdAt.to': params.createdAtTo,
          'createdAt.from': params.createdAtFrom,
          criticality: params?.criticality?.join(',') || undefined,
          viewed: params.viewed,
          query: params.query,
          domains: params.domain,
          'metadata[shipmentId]': params.shipmentId,
          'assignee.organizationIds': params?.assigneeOrganizationIds?.join(',') || undefined,
          'targetAudience.organizationIds': params?.assigneeAudienceOrganizationIds?.join(',') || undefined,
          'assignee.userEmails': params?.assigneeUserEmails?.join(',') || undefined,
        },
      });

      response = rawResponse.data;

      if (response) {
        result = NotificationTaskDTM.fromPlain({
          totalPages: response.totalPages || undefined,
          totalElements: response.totalElements || undefined,
          content: response.content.map((item) => NotificationTaskContentDTM.fromPlain({
            id: `${item.id}` || undefined,
            customId: uuidv4(),
            createdAt: item.createdAt ? DateDtm.fromPlain({
              date: item.createdAt,
              offset: moment.parseZone(item.createdAt).utcOffset(),
            }) : undefined,
            completedAt: item.completedAt ? DateDtm.fromPlain({
              date: item.completedAt,
              offset: moment.parseZone(item.completedAt).utcOffset(),
            }) : undefined,
            // @ts-ignore
            createdBy: item?.createdBy?.email ? item.createdBy?.email : (item.createdBy || undefined),
            // @ts-ignore
            createdByObj: item.createdBy && NotificationTaskContentCreatedByDTM.fromPlain(item.createdBy),
            // @ts-ignore
            completedBy: item.completedBy && NotificationTaskContentCreatedByDTM.fromPlain(item.completedBy),
            title: item.title,
            description: item.description || undefined,
            criticality: item.criticality,
            domain: item.domain || undefined,
            dueDate: item.dueDate ? DateDtm.fromPlain({
              date: item.dueDate,
              offset: moment.parseZone(item.dueDate).utcOffset(),
            }) : undefined,
            organizationId: item.organizationId || undefined,
            view: !!item.view || undefined,
            viewId: `${item.view?.id || ''}`,
            objectReference: {
              id: item?.objectReference?.id || undefined,
              shipmentId: item?.objectReference?.shipmentId || undefined,
              type: item?.objectReference?.type || undefined,
            },
            targetAudience: {
              type: item?.targetAudience?.type || undefined,
              id: item?.targetAudience?.id || undefined,
            },
            actions: item.actions?.map((itemAction) => NotificationTaskContentActionsDTM.fromPlain({
              type: itemAction?.type || undefined,
              id: itemAction?.id || undefined,
              title: itemAction?.title,
            })),
            metadata: {
              taskType: item?.metadata?.taskType || undefined,
              shipmentId: item?.metadata?.shipmentId || undefined,
              shipmentName: item?.metadata?.shipmentName || undefined,
            },
          })),
        });
      }

      const errors = result?.validate();

      if (errors?.length) {
        console.error('DTM valid RFQ: error', errors, result);
      }
    } catch (e) {
      throw new Error('CommandCenterService: getTasks');
    }

    return result;
  }

  public setNotificationsViewed = async (id: string) => {
    if (!id) throw new Error('CommandCenterService: setNotificationsViewed (id is null)');
    let result: NotificationTaskContentViewedDTM | undefined;
    let response: ICommandCenterNotificationsViewedResponse | undefined;

    try {
      const rawResponse = await apiWorker.requestPost<ICommandCenterNotificationsViewedResponse>(`${this.baseUrlNotifications}/${id}/views`);

      response = rawResponse.data;

      result = NotificationTaskContentViewedDTM.fromPlain({
        id: response.id,
        viewedAt: response.viewedAt,
        viewedBy: response.viewedBy,
      });
    } catch (e) {
      throw new Error('CommandCenterService: setNotificationsViewed');
    }

    return result;
  }

  public setNotificationsUnviewed = async (notificationId: string, viewId: string) => {
    if (!notificationId || !viewId) throw new Error('CommandCenterService: setNotificationsUnviewed (id is null)');

    try {
      await apiWorker.requestDelete(`${this.baseUrlNotifications}/${notificationId}/views/${viewId}`);
    } catch (e) {
      throw new Error('CommandCenterService: setNotificationsUnviewed');
    }
  }

  public setAllNotificationsViewed = async (shipmentId?: string) => {
    try {
      await apiWorker.requestPost(`${this.baseUrlNotifications}/views`, null, {
        params: {
          'metadata[shipmentId]': shipmentId || undefined,
        },
      });
    } catch (e) {
      throw new Error('CommandCenterService: setAllNotificationsViewed');
    }
  }

  private notificationTaskContent(item: components['schemas']['Task']) {
    const assigneeUser = (item?.assignee as components['schemas']['UserAssignee']);
    const assigneeUsers = (item?.assignee as components['schemas']['UserGroupAssignee']);
    return NotificationTaskContentDTM.fromPlain({
      id: `${item.id}` || undefined,
      type: item.type as TaskTypeEnum,
      customId: uuidv4(),
      createdAt: item.createdAt ? DateDtm.fromPlain({
        date: item.createdAt,
        offset: moment.parseZone(item.createdAt).utcOffset(),
      }) : undefined,
      completedAt: item.completedAt ? DateDtm.fromPlain({
        date: item.completedAt,
        offset: moment.parseZone(item.completedAt).utcOffset(),
      }) : undefined,
      createdBy: item?.createdBy?.email,
      createdByObj: item.createdBy && NotificationTaskContentCreatedByDTM.fromPlain(item.createdBy),
      completedBy: item.completedBy && NotificationTaskContentCreatedByDTM.fromPlain(item.completedBy),
      title: item.title,
      description: item.description || undefined,
      criticality: item.criticality as ENotificationCriticality,
      status: item.status as ENotificationStatus,
      domain: (item.domain as ENotificationDomain) || undefined,
      dueDate: item.dueDate ? DateDtm.fromPlain({
        date: item.dueDate,
        offset: moment.parseZone(item.dueDate).utcOffset(),
      }) : undefined,
      assignee: {
        type: assigneeUser?.type || undefined,
        sub: assigneeUser?.user?.sub || undefined,
        enabled: assigneeUser?.user?.enabled || undefined,
        email: assigneeUser?.user?.email || undefined,
        phone: assigneeUser?.user?.phone || undefined,
        firstName: assigneeUser?.user?.firstName || undefined,
        lastName: assigneeUser?.user?.lastName || undefined,
        organization: assigneeUser?.organization || undefined,
      },
      assigneeOrganizationId: assigneeUser?.organization?.id,
      assigneeOrganizationName: assigneeUser?.organization?.name,
      assigneeUsers: assigneeUsers?.users?.map((itemUser) => NotificationTaskContentAssigneeDTM.fromPlain({
        sub: itemUser?.sub || undefined,
        enabled: itemUser?.enabled || undefined,
        email: itemUser?.email || undefined,
        phone: itemUser?.phone || undefined,
        firstName: itemUser?.firstName || undefined,
        lastName: itemUser?.lastName || undefined,
      })),
      organizationId: item.organizationId || undefined,
      objectReference: {
        shipmentId: (item?.objectReference as components['schemas']['ShipmentReference'])?.shipmentId || undefined,
        rateRequestId: (item?.objectReference as components['schemas']['RateRequestReference'])?.rateRequestId || undefined,
        organizationId: (item?.objectReference as components['schemas']['OrganizationReference'])?.organizationId || undefined,
        type: item?.objectReference?.type || undefined,
      },
      targetAudience: {
        type: item?.targetAudience?.type || undefined,
        organizationIds: (item?.targetAudience as components['schemas']['OrganizationsGroupTargetAudience'])?.organizationIds || undefined,
        organizationId: (item?.targetAudience as components['schemas']['OrganizationTargetAudience'])?.organizationId || undefined,
      },
      actions: item.actions?.map((itemAction) => ({
        type: itemAction?.type || undefined,
        id: itemAction?.id || undefined,
        title: itemAction?.title,
        link: (itemAction as components['schemas']['LinkAction'])?.link || undefined,
      })),
      metadata: {
        taskType: item?.metadata?.taskType || undefined,
        shipmentId: item?.metadata?.shipmentId || undefined,
        shipmentName: item?.metadata?.shipmentName || undefined,
        changeRequestType: (item?.metadata?.changeRequestType as EChangeRequestType) || undefined,
      },
      payload: item?.payload as IPayloadDTM,
      attachments: item?.attachments,
    });
  }
}
