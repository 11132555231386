import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { BaseController } from 'proto/BaseController';
import { InputStore } from 'proto/BaseMobxStore/InputStore';

export class InputMobxUseCase<T extends InputStore<any>> extends BaseUseCase {
  storeMobx: T

  maxLength;

  validation: (useCase: InputMobxUseCase<T>) => void

  constructor(
    controller: BaseController,
    storeMobx: T,
    validation?: (useCase: InputMobxUseCase<T>) => void,
    options?: { maxLength: number },
  ) {
    super(controller);
    this.storeMobx = storeMobx;
    this.validation = validation || (() => {});
    this.maxLength = options?.maxLength || 50;
  }

  onChange(value: string) {
    const validValue = value.slice(0, this.maxLength);
    this.storeMobx.setValue(validValue);

    this.storeMobx.setStates({
      isFocus: true,
      isBlur: false,
    });

    this.validate();
  }

  onClear() {
    this.storeMobx.setValue('');

    this.storeMobx.setStates({
      isFocus: true,
      isBlur: false,
    });

    this.validate();
  }

  public onBlur() {
    this.storeMobx.setStates({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    });
    this.validate();
  }

  public setError(error: string | null) {
    this.storeMobx.setError(error);
  }

  public validate() {
    this.validation(this);
  }
}
